import { Card } from 'components/design-system/Card/Card';
import { H6 } from 'components/design-system/Heading/Heading';
import {
  FontSize,
  FontStyle,
  FontWeight,
  Text,
  TextLarge,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import type { BreakdownComponentProps } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import {
  MinusIcon,
  PlusIcon,
  ShoppingBasketIcon,
} from 'components/feature/PortfolioBuilder/_shared/DpcIcons';
import { currencyFull, percent } from 'formatting';
import styled from 'styled-components';
import { Table, Td, Th, Tr } from './Table.styles';

export const IconWrapper = styled.div`
  display: inline-flex;
  margin-left: 0rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-left: 0.5rem;
  }
`;

const Wrapper = styled(Card)`
  margin-top: 1rem;
`;

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1.5rem;
  align-items: start;
`;
const TableLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StatCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

interface DrillDownTableProps extends BreakdownComponentProps {
  type: 'existing' | 'combined';
}

export const DrillDownTable = ({ breakdown, type }: DrillDownTableProps) => {
  return (
    <Wrapper>
      <H6>{breakdown.name} holdings</H6>
      <Layout>
        <TableLayout>
          <Table>
            <thead>
              <Tr>
                {type === 'combined' && <Th style={{ width: '1rem' }}></Th>}
                <Th>Name</Th>
                <Th>Holding (%)</Th>
                <Th>Value (£)</Th>
                {type === 'combined' && <Th>Change</Th>}
              </Tr>
            </thead>
            <tbody>
              {breakdown.lines?.map((breakdownLine) => {
                return (
                  <Tr status={breakdownLine.state}>
                    {type === 'combined' && (
                      <>
                        <Td>
                          {breakdownLine.amount >
                            breakdownLine.existingAmount && (
                            <IconWrapper>
                              <ShoppingBasketIcon $type="buy" />
                              <PlusIcon />
                            </IconWrapper>
                          )}
                          {breakdownLine.amount <
                            breakdownLine.existingAmount && (
                            <IconWrapper>
                              <ShoppingBasketIcon $type="sell" />
                              <MinusIcon />
                            </IconWrapper>
                          )}
                        </Td>
                      </>
                    )}
                    <Td>{breakdownLine.name}</Td>
                    <Td>{percent(breakdownLine.proportion)}</Td>
                    <Td>{currencyFull(breakdownLine.amount)}</Td>
                    {type === 'combined' && (
                      <Td>
                        {currencyFull(
                          (Math.round(breakdownLine.amount * 100) -
                            Math.round(breakdownLine.existingAmount * 100)) /
                            100
                        )}
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </tbody>
          </Table>
          <Text
            $fontSize={FontSize.xs}
            $fontWeight={FontWeight.light}
            $fontStyle={FontStyle.italic}
          >
            Please note that the 'Holding (%)' is calculated as a proportion of
            your equity allocation, not your total portfolio.
          </Text>
        </TableLayout>
        <StatCardLayout>
          <Card>
            <TextXS $noMargin>{breakdown.name} value</TextXS>
            <TextLarge $noMargin $fontWeight={FontWeight.medium}>
              {currencyFull(breakdown.amount)}
            </TextLarge>
            {type === 'combined' && (
              <TextSmall>
                {currencyFull(breakdown.amount - breakdown.existingAmount)}
              </TextSmall>
            )}
          </Card>
          <Card>
            <TextXS $noMargin>{breakdown.name} allocation</TextXS>
            <TextLarge $noMargin $fontWeight={FontWeight.medium}>
              {percent(breakdown.proportion)}
            </TextLarge>
            {type === 'combined' && (
              <TextSmall>
                {percent(breakdown.proportion - breakdown.existingProportion)}
              </TextSmall>
            )}
          </Card>
        </StatCardLayout>
      </Layout>
    </Wrapper>
  );
};
