import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TutorialVideo } from '../../TutorialVideo';
import { StepProps } from '../_shared';
import { Content } from '../PersonalisePensionStep.styles';
import { RetirementRangeType, useRetirementRange } from './useRetirementRange';

const retirementVideoUrl: Record<RetirementRangeType, string> = {
  over20: 'Step-4-Personal-Circumstances-_20-years-left-to-retirement.mp4.m3u8',
  years15_20:
    'Step-4-Personal-Circumstances-15-20-years-left-to-retirement.mp4.m3u8',
  years10_15:
    'Step-4-Personal-Circumstances-10-15-years-left-to-retirement.mp4.m3u8',
  years5_10:
    'Step-4-Personal-Circumstances-5-10-years-left-to-retirement.mp4.m3u8',
  under5: 'Step-4-Personal-Circumstances-_5-years-left-to-retirement.mp4.m3u8',
};

export function Step4PersonalCircumstances({ onProceed }: StepProps) {
  const { retirementRange } = useRetirementRange();
  const videoUrl = retirementVideoUrl[retirementRange];

  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>Personal Circumstances</H1Large>
      <TutorialVideo
        video={{
          thumbnailUrl: 'https://via.placeholder.com/150',
          videoUrl: `/mediacontent/protected/personalise-pension/${videoUrl}`,
          subtitlesUrl: '',
        }}
        description="Step-4-Personal-Circumstances"
        onVideoEnd={onProceed}
      />
    </Content>
  );
}
