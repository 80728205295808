import { H4, TextAlign } from 'components/design-system/Heading/Heading';
import { StyledA } from 'components/design-system/Link';
import { FontStyle } from 'components/design-system/Text/Text';
import {
  ToggleColorOptions,
  ToggleIconButtons,
} from 'components/design-system/ToggleIconButtons/ToggleIconButtons';
import { RegionBreakdown } from 'components/feature/FundDetails/Breakdown/RegionBreakdown/RegionBreakdown';
import worldData from 'components/feature/FundDetails/Breakdown/RegionBreakdown/world';
import { TableBreakdown } from 'components/feature/FundDetails/Breakdown/TableBreakdown/TableBreakdown';
import { NoDataCopy } from 'components/feature/FundDetails/Breakdown/_shared/Breakdown.styles';
import { FundBreakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { GaEventNames } from 'constants/gaConstants';
import { sum } from 'd3';
import { date as formatDate } from 'formatting';
import { trackGa } from 'helpers/track';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { useState } from 'react';
import { AssetQueryAssetInstrument } from 'types/graphqlTypes';
import { PerformanceContainer } from '../../Styles/FundDetails.style';
import { Disclaimer } from '../RegionMapSlice.styles';
import {
  BreakdownLayout,
  BreakdownToggleWrapper,
  ChartContainer,
} from '../_shared/BreakdownSlices.styles';
import { codeMapping, nameMapping } from './feIsoMappingData';

const getCountryNameFromCode = (code: string) => {
  const country = worldData.features.find(
    (feature) => feature.properties?.code === code
  );

  return country?.properties?.name;
};

const parentIsoCountryCodeLookUp: { [key: string]: string } = {
  IM: 'GB',
  JE: 'GB',
  GG: 'GB',
};

const parentCountryNames: { [key: string]: string } = {
  GB: 'United Kingdom & Crown Dependencies',
};

/**
 * This function takes a regional breakdown and groups the lines by country code.
 */
function deriveMapBreakdown(
  instrument: AssetQueryAssetInstrument
): FundBreakdown {
  const flatLines = instrument?.assetClassBreakdown?.nodes
    .map((assetClass) => {
      return {
        name: mapAssetBreakdownNameToName(assetClass.name),
        proportion: assetClass.proportion,
        isoCountryCode: mapAssetBreakdownToRegionBreakdown(assetClass),
        code: assetClass.code || '',
        type: assetClass.code || '',
      };
    })
    .filter((region) => region.proportion !== 0);

  const linesGroupedByType = groupBy(flatLines, (line) =>
    line.isoCountryCode ? 'Country' : line.code === 'CASH' ? 'Cash' : 'Other'
  );

  const linesGroupedByCountryCode = groupBy(
    linesGroupedByType['Country'],
    (line) => {
      if (line.type)
        return (
          parentIsoCountryCodeLookUp[line.isoCountryCode || ''] ??
          line.isoCountryCode
        );
    }
  );

  const lines = map(linesGroupedByCountryCode, (value, key) => {
    return {
      name: parentCountryNames[key] ?? getCountryNameFromCode(key),
      isoCountryCode: key,
      code: key,
      proportion: sum(value.map(({ proportion }) => proportion)),
      lines: value,
    };
  });

  const cashBreakdown = {
    name: 'Cash',
    proportion: sum(
      linesGroupedByType['Cash'].map(({ proportion }) => proportion)
    ),
    lines: linesGroupedByType['Cash'],
  };

  return {
    name: 'Region',
    proportion: sum(
      instrument?.regionalBreakdown?.nodes.map(({ proportion }) => proportion)
    ),
    lines: [
      ...lines,
      ...(cashBreakdown ? [cashBreakdown] : []),
      ...(linesGroupedByType['Other'] || []),
    ],
  };
}

interface RegionMapSliceProps {
  instrument: AssetQueryAssetInstrument;
}

type DisplayOptions = 'map' | 'table';

const mapAssetBreakdownToRegionBreakdown = (breakdown: {
  code?: string | null;
  name: string;
}) => {
  const { code, name } = breakdown;

  if (!code) {
    return '';
  }

  if (code === 'CASH') {
    return '';
  }

  const mappingDataByName = nameMapping[name];
  if (mappingDataByName) {
    return mappingDataByName.isoImpliedByName;
  }

  // try to map based on name first
  const mappingDataByCode = codeMapping[code];
  if (mappingDataByCode) {
    return mappingDataByCode.isoImpliedByCode;
  }

  return '';
};

const mapAssetBreakdownNameToName = (name: string) => {
  if (name === 'None None') {
    return 'Other';
  }
  if (name.startsWith('None ')) {
    return name.replace('None ', 'Other ');
  }
  return name;
};

export function LifePathRegionMapSlice({ instrument }: RegionMapSliceProps) {
  const breakdown = deriveMapBreakdown(instrument);

  const [activeDisplay, setActiveDisplay] = useState<DisplayOptions>('map');

  const breakdownHasData = !!breakdown.lines?.length;

  return (
    <PerformanceContainer>
      <H4 $textAlign={TextAlign.center}>Where does it invest?</H4>
      {breakdownHasData ? (
        <BreakdownLayout>
          <BreakdownToggleWrapper>
            <ToggleIconButtons<DisplayOptions>
              onClick={(value) => {
                trackGa({
                  event: GaEventNames.selectContent,
                  content_type: 'fund details - bubble chart slice - display',
                  item_id: value,
                });
                setActiveDisplay(value);
              }}
              $color={ToggleColorOptions.blue}
              options={[
                { value: 'map', icon: 'map' },
                { value: 'table', icon: 'table' },
              ]}
              value={activeDisplay}
            />
          </BreakdownToggleWrapper>

          {activeDisplay === 'map' && (
            <>
              <ChartContainer>
                <RegionBreakdown breakdown={breakdown} />
              </ChartContainer>
              <Disclaimer $fontStyle={FontStyle.italic}>
                Map view based on analysis of regional and asset class breakdown
                data supplied by FE fundinfo, retrieved on{' '}
                {formatDate(instrument?.lastFeSyncUtc)}, and is indicative only.{' '}
                {instrument.factsheetUrl && (
                  <>
                    You should consult the fund's{' '}
                    <StyledA href={instrument.factsheetUrl} target="_blank">
                      factsheet
                    </StyledA>{' '}
                    before making any decisions.
                  </>
                )}
                {!instrument.factsheetUrl && instrument.kiidDocumentUrl && (
                  <>
                    You should consult the fund's{' '}
                    <StyledA href={instrument.kiidDocumentUrl} target="_blank">
                      Key Investor Information Document
                    </StyledA>{' '}
                    before making any decisions.
                  </>
                )}
              </Disclaimer>
            </>
          )}
          {activeDisplay === 'table' && (
            <ChartContainer>
              <TableBreakdown breakdown={breakdown} />
            </ChartContainer>
          )}
        </BreakdownLayout>
      ) : (
        <NoDataCopy>{breakdown.name} breakdown is not available.</NoDataCopy>
      )}
    </PerformanceContainer>
  );
}
