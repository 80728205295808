import { useRecurringTransactionsQuery } from 'generated/graphql';

interface useHasRecurringTransactionsProps {
  accountId?: string | null;
}

/**
 * A hook that determines whether an account has recurring transactions (deposits or orders).
 * It uses the `useRecurringTransactionsQuery` to fetch recurring transactions for a given account ID.
 * TODO: Can we replace this with a simpler query?
 *
 * @param {useHasRecurringTransactionsProps} props - The props for the hook, including the account ID.
 * @returns {{ recurringTransactionsQuery, hasRegularDeposits: boolean; hasRegularOrders: boolean }} An object containing boolean flags indicating the presence of recurring deposits and orders and the query.
 */
export const useHasRecurringTransactions = ({
  accountId,
}: useHasRecurringTransactionsProps) => {
  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId as string,
    },
    { enabled: !!accountId }
  );

  const hasRegularDeposits =
    (recurringTransactionsQuery.data?.recurringTransactions?.deposits &&
      recurringTransactionsQuery.data?.recurringTransactions?.deposits?.length >
        0) ||
    false;

  const hasRegularOrders =
    (recurringTransactionsQuery.data?.recurringTransactions?.orders &&
      recurringTransactionsQuery.data?.recurringTransactions?.orders?.length >
        0) ||
    false;

  return {
    recurringTransactionsQuery,
    hasRegularDeposits,
    hasRegularOrders,
  };
};
