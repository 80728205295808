import {
  assetClassColors,
  colors,
  magentaTriad,
  mauveTriad,
  purpleTriad,
} from 'constants/colors';
import { mix } from 'polished';

export const assetClassBreakdownColors: Record<string, string> = {
  ...assetClassColors,
  'Alternative investment strategies': mauveTriad['triad-200'],
  'Money Market': magentaTriad['triad+100'],
  Other: purpleTriad['triad-100'],
  Cash: `linear-gradient(135deg, ${colors.richBlack} 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(225deg, #d8d8d8 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(315deg, ${colors.richBlack} 25%, transparent 25%) 0px 0/ 8px 8px, linear-gradient(45deg, #d8d8d8 25%, #e8e8e8 25%) 0px 0/ 8px 8px`,
};

export const getBubbleColourForProportion = (proportion: number) => {
  return mix(proportion, colors['purple-300'], colors['purple-200']);
};

export const getTreeColourForProportion = (proportion: number) => {
  return mix(proportion, colors['mauve-500'], colors['mauve-400']);
};

export const getMapColourForProportion = (proportion: number) => {
  return mix(proportion, colors.magenta, colors['magenta-100']);
};

export const mapColourForNegativeProportion = colors['richBlue-500'];

export const getDonutColourForProportion = (proportion: number) => {
  return mix(proportion, colors['purple-500'], colors['purple-300']);
};
