import { positionType } from '../BeliefsAndValues';
import { StyledBuble, StyledFaCheck } from './BeliefValueBubble.styles';

interface BeliefValueBubbleProp {
  title: string;
  isSelected: boolean;
  size?: 'small' | 'medium' | 'large';
  position?: positionType;
  onSelect: () => void;
}

export function BeliefValueBuble({
  title,
  isSelected,
  size = 'medium',
  position,
  onSelect,
}: BeliefValueBubbleProp) {
  return (
    <StyledBuble
      $isActive={isSelected}
      $size={size}
      onClick={onSelect}
      style={{
        left: `${position?.left || 0}px`,
        top: `${position?.top || 0}px`,
      }}
    >
      {isSelected && <StyledFaCheck />}
      {title}
    </StyledBuble>
  );
}
