import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { FontSize } from '../Text/Text';

export const TabContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'title tabs' 'content content';
`;

export const TabTitle = styled.div``;

interface TabListProps {
  stretchHeadings: boolean;
  headingAlign: 'left' | 'right';
}

export const TabList = styled.div<TabListProps>`
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: ${({ stretchHeadings, headingAlign }) =>
    stretchHeadings
      ? 'stretch'
      : headingAlign === 'right'
      ? 'flex-end'
      : 'flex-start'};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    align-items: flex-end;
  }
`;

interface TabHeadingProps {
  $active?: boolean;
  isBorderless: boolean;
  stretchHeadings: boolean;
  $fontSize: FontSize;
  headingAlign: 'left' | 'right';
}

export const TabHeading = styled.div<TabHeadingProps>`
  padding: 0.5rem 1rem;
  transform: ${({ $active }) => ($active ? 'translateY(1px)' : 'none')};
  background-color: #fff;
  color: ${({ $active }) =>
    $active ? colors.richBlack : transparentize(0.7, colors.richBlack)};
  cursor: pointer;
  text-align: center;
  font-size: ${({ $fontSize }) =>
    $fontSize === FontSize.large ? '1.125rem' : '1rem'};
  ${({ isBorderless, $active }) =>
    isBorderless
      ? css`
          border-bottom: solid ${colors.magenta};
          border-width: ${$active ? '3px' : '0'};
        `
      : css`
          border: solid #ccc;
          border-radius: 0.25rem 0.25rem 0 0;
          border-width: ${$active ? '1px 1px 0 1px' : '0'};
        `}

  width: ${({ stretchHeadings }) => (stretchHeadings ? '100%' : 'auto')};

  ${({ headingAlign, isBorderless }) =>
    headingAlign === 'right' &&
    isBorderless &&
    css`
      &:last-child {
        padding-right: 0;
      }
    `};

  ${({ headingAlign, isBorderless }) =>
    headingAlign === 'left' &&
    isBorderless &&
    css`
      &:first-child {
        padding-left: 0;
      }
    `};
`;

interface TabPanelContainerProps {
  $isLastTabActive?: boolean;
  isBorderless: boolean;
}

export const TabPanelContainer = styled.div<TabPanelContainerProps>`
  grid-area: content;
  border: solid #ccc;
  border-width: ${({ isBorderless }) => (isBorderless ? '0' : '1px')};
  border-radius: ${({ $isLastTabActive }) =>
    $isLastTabActive ? '0.25rem 0 0.25rem 0.25rem' : '0.25rem'};
`;

interface TabPanelProps {
  $active?: boolean;
  isBorderless: boolean;
}

export const TabPanel = styled.div<TabPanelProps>`
  ${({ isBorderless, $active }) =>
    isBorderless
      ? css`
          padding: ${$active ? '1.25rem 0' : '0'};
        `
      : css`
          padding: ${$active ? '1.25rem' : '0'};
        `}
`;
