import { groupRegionalBreakdown } from './groupRegionalBreakdown';
import { useRebalancingRegionBreakdownRaw } from './useRebalancingRegionBreakdown';
import { useRegionBreakdownRaw } from './useRegionBreakdown';

interface UseRebalancingAssetBreakdownProps {
  accountId: string;
  rebalancingId: string;
}

export const useCombinedRegionBreakdown = ({
  accountId,
  rebalancingId,
}: UseRebalancingAssetBreakdownProps) => {
  const {
    query: accountQuery,
    breakdownLines: accountBreakdownList,
  } = useRegionBreakdownRaw({ accountId });

  const {
    query: rebalancingQuery,
    breakdownLines: rebalancingBreakdownList,
  } = useRebalancingRegionBreakdownRaw({ rebalancingId });

  return {
    accountQuery,
    rebalancingQuery,
    breakdown: groupRegionalBreakdown([
      ...accountBreakdownList,
      ...rebalancingBreakdownList,
    ]),
  };
};
