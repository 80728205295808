import { Checkbox, FormControlLabel } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { StepActions } from 'components/StepContainer';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  H1Xl,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { StyledA, StyledLink } from 'components/design-system/Link';
import {
  FontWeight,
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { AccountType, useReferralCodeQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SkipButton,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CheckboxContainer,
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ReferralWelcomeStep.styles';

interface ReferralWelcomeFormValues {
  optIntoComplianceDataSharing?: boolean;
}

interface ReferralWelcomeStepValues {
  optIntoComplianceDataSharing?: boolean;
  accountType: AccountType;
}

interface ReferralWelcomeStepProps {
  referralCode: string;
  onProceed: (formValues: ReferralWelcomeStepValues) => void;
}

export function ReferralWelcomeStep({
  referralCode,
  onProceed,
}: ReferralWelcomeStepProps) {
  const { getSignInPath } = useAuth();

  const { clearReferralCode } = useGetEmployerCode();
  const referralCodeQuery = useReferralCodeQuery({
    code: referralCode,
  });

  const { data: referralCodeData } = referralCodeQuery;

  const isInvalidReferralCode = referralCodeData?.referralCode === null;

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;
  const initialAccountType = referralCodeData?.referralCode?.initialAccountType;
  const initialAccountTypeSipp = initialAccountType === AccountType.Sipp;

  const complianceConfiguration =
    referralCodeData?.referralCode?.employer?.complianceConfiguration;
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: ReferralWelcomeFormValues) => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.referralCode,
    });
    onProceed({
      accountType: initialAccountType || AccountType.NotSpecified,
      optIntoComplianceDataSharing: data.optIntoComplianceDataSharing,
    });
  };
  const history = useHistory();
  const location = useLocation();

  return (
    <QueryState {...referralCodeQuery}>
      {() => (
        <ReferralWelcomeContainer>
          <StepContent width={StepContentWidth.fullWidth}>
            {initialAccountTypeSipp ? (
              <H1Xl $noMargin $textAlign={HeadingAlign.center}>
                Let's start your TILLIT Pension journey
              </H1Xl>
            ) : (
              <H1Xl $noMargin $textAlign={HeadingAlign.center}>
                Let's start your TILLIT journey
              </H1Xl>
            )}
          </StepContent>

          {isInvalidReferralCode ? (
            <StepContent>
              <TextNormal
                $textAlign={HeadingAlign.center}
                $fontWeight={FontWeight.medium}
              >
                You have an invalid referral code in the URL
              </TextNormal>
              <TextNormal $textAlign={HeadingAlign.center}>
                Please correct this to continue
              </TextNormal>
              <TextNormal $textAlign={HeadingAlign.center}>
                If you have any questions, just click on the blue icon in the
                bottom right corner to speak to our team on live chat
              </TextNormal>
              <StepActions>
                <SkipButton
                  variant="outlined"
                  className="richBlue"
                  onClick={() => {
                    clearReferralCode();
                    history.push(location.pathname);
                    onProceed({ accountType: AccountType.NotSpecified });
                  }}
                >
                  Continue without applying a code
                </SkipButton>
              </StepActions>
            </StepContent>
          ) : (
            <>
              {referralCodeData?.referralCode?.employer?.logoUrl && (
                <ReferralCoBrandingContainer>
                  <TillitLogo />
                  <AiOutlineClose />
                  <CoBrandedLogo
                    src={referralCodeData?.referralCode?.employer?.logoUrl}
                    alt={employerName || ''}
                  />
                </ReferralCoBrandingContainer>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <StepContent width={StepContentWidth.extraWide}>
                  {initialAccountTypeSipp && (
                    <>
                      <TextNormal $textAlign={TextAlign.center}>
                        To switch your <strong>{employerName}</strong> pension
                        to TILLIT, follow the steps on the screens. It should
                        take 5-7 minutes.
                      </TextNormal>
                    </>
                  )}
                  {contractNoteSharing && (
                    <CheckboxContainer>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="optIntoComplianceDataSharing"
                            inputRef={methods.register}
                          />
                        }
                        label={
                          complianceConfiguration?.policyLinkDescription ? (
                            <>
                              Forward contract notes to{' '}
                              <strong>{employerName}</strong>, as per their{' '}
                              {complianceConfiguration?.policyUrl ? (
                                <StyledA
                                  href={complianceConfiguration?.policyUrl}
                                  target="_blank"
                                >
                                  {
                                    complianceConfiguration?.policyLinkDescription
                                  }{' '}
                                  <HiExternalLink />
                                </StyledA>
                              ) : (
                                <>
                                  {
                                    complianceConfiguration?.policyLinkDescription
                                  }
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              Forward contract notes to{' '}
                              <strong>{employerName}</strong>, as per their
                              trading policy
                            </>
                          )
                        }
                      />
                    </CheckboxContainer>
                  )}

                  <TextNormal $textAlign={TextAlign.center}>
                    If you have any questions, just click on the blue icon in
                    the bottom right corner to speak to our team on live chat.
                  </TextNormal>

                  <ReferralActionsContainer>
                    <CustomButtonV2 type="submit">Begin</CustomButtonV2>

                    <TextSmall
                      $textAlign={TextAlign.center}
                      style={{ fontWeight: 300 }}
                      $noMargin
                    >
                      Already have an account?
                      <br />{' '}
                      <StyledLink
                        href={getSignInPath(undefined, undefined, referralCode)}
                      >
                        Sign in
                      </StyledLink>
                    </TextSmall>
                  </ReferralActionsContainer>
                </StepContent>
              </form>
            </>
          )}
        </ReferralWelcomeContainer>
      )}
    </QueryState>
  );
}
