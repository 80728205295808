import {
  Breakdown,
  BreakdownNoProportions,
} from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';

export const groupBreakdown = (
  getBreakdownMappingKey: (line: Breakdown) => string,
  orderBreakdown: (breakdown: Breakdown) => Breakdown
) => (breakdownLines: BreakdownNoProportions[]): Breakdown => {
  const linesGroupedByName = groupBy(breakdownLines, getBreakdownMappingKey);

  const lines: BreakdownNoProportions[] = map(
    linesGroupedByName,
    (value, key) => {
      const groupAmount = sum(value.map(({ amount }) => amount));
      const groupExistingAmount = sum(
        value
          .filter(({ state }) => state === 'existing')
          .map(({ amount }) => amount)
      );

      if (key === 'Cash') {
        const rebalancingBreakdownTotal = sum(
          breakdownLines
            .filter(
              ({ state }) => state === 'buyOrder' || state === 'sellOrder'
            )
            .map(({ amount }) => amount)
        );

        return {
          name: key,
          code: key,
          amount: Math.max(
            value[0].existingAmount - rebalancingBreakdownTotal,
            0
          ),
          existingAmount: groupExistingAmount,
          lines: value,
          state: rebalancingBreakdownTotal ? 'mix' : 'existing',
        };
      }

      const newLocal: BreakdownNoProportions[] = map(
        groupBy(value, (line) => line.subName),
        (instrumentLines, instrumentName): BreakdownNoProportions => {
          const amount = sum(
            instrumentLines.map(({ amount }) => Math.round(amount * 100) / 100)
          );
          const existingAmount = sum(
            instrumentLines
              .filter(({ state }) => state === 'existing')
              .map(({ amount }) => amount)
          );

          return {
            name: instrumentName,
            amount,
            existingAmount,
            state: instrumentLines.every(
              ({ state }) => state === instrumentLines[0].state
            )
              ? instrumentLines[0].state
              : 'mix',
          };
        }
      );

      return {
        name: key,
        code: key,
        amount: groupAmount,
        existingAmount: groupExistingAmount,
        lines: orderBy(proportionise(newLocal), 'proportion', 'desc'),
        state: groupAmount === groupExistingAmount ? 'existing' : 'mix',
      };
    }
  );

  return orderBreakdown({
    name: 'Asset class',
    proportion: 1,
    existingProportion: 1,
    amount: sum(lines.map(({ amount }) => amount)),
    existingAmount: sum(lines.map(({ existingAmount }) => existingAmount)),
    lines: proportionise(lines),
    state: 'mix',
  });
};

export const proportionise = (
  breakdownLines: BreakdownNoProportions[]
): Breakdown[] => {
  const totalAmount = sum(breakdownLines.map(({ amount }) => Math.abs(amount)));
  const existingTotalAmount = sum(
    breakdownLines.map(({ existingAmount }) => Math.abs(existingAmount))
  );

  return breakdownLines.map(
    (breakdown: BreakdownNoProportions): Breakdown => ({
      ...breakdown,
      proportion: breakdown.amount / totalAmount,
      existingProportion: breakdown.existingAmount / existingTotalAmount,
      lines: breakdown.lines ? proportionise(breakdown.lines) : [],
    })
  );
};
