import { IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H5, H6 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import {
  FontSize,
  Text,
  TextAlign,
  TextSmall,
  TextXS,
} from 'components/design-system/Text/Text';
import { useToast } from 'context/ToastContext';
import {
  ActionStatus,
  ActionType,
  useActionsQuery,
  useDismissActionMutation,
  useUserProfileQuery,
  WrapperType,
} from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import Cookies from 'js-cookie';
import { orderBy } from 'lodash';
import { actionsPath } from 'paths';
import { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { ActionsQueryAction } from 'types/graphqlTypes';
import { actionSpecLookUp } from '../actionSpecLookUp';
import {
  ActionDivider,
  ActionItemHeading,
  ActionLink,
  ActionWrapper,
  AreYouSureWrapper,
  ArrowRight,
  CloseButtonWrapper,
  ConfirmIcon,
  DismissButton,
  DismissIcon,
  DismissInnerWrapper,
  DismissWrapper,
  ListLayout,
  PopOverContainer,
  TeaserContainer,
  Wrapper,
} from './PopOver.styles';

const ACTIONS_DISMISSED_COOKIE_NAME = 'actionsDismissed';
const ACTIONS_DISMISSED_COOKIE_EXPIRATION = 1;

interface ActionsTeaserProps {
  setMobileOpenState: (newState: 'closed' | 'teaser' | 'open') => void;
}

function ActionsTeaser({ setMobileOpenState }: ActionsTeaserProps) {
  const handlers = useSwipeable({
    onSwipedDown: () => setMobileOpenState('closed'),
    onSwipedUp: () => setMobileOpenState('open'),
    trackMouse: false,
    preventDefaultTouchmoveEvent: false,
  });

  return (
    <TeaserContainer {...handlers}>
      <div>
        <H6 $noMargin $isDarkBg>
          Your actions
        </H6>
        <TextSmall $noMargin $isDarkBg>
          Make the most of your account
        </TextSmall>
      </div>
      <CustomButtonV2 onClick={() => setMobileOpenState('open')}>
        View
      </CustomButtonV2>
    </TeaserContainer>
  );
}

interface ActionsListProps {
  actions: ActionsQueryAction[];
}

function ActionsList({ actions }: ActionsListProps) {
  const orderedSlicedActions = orderBy(actions, 'complete').slice(0, 3);
  const [personalisePensionToggle] = useToggle('global-personalise-pension');

  return (
    <ListLayout>
      {orderedSlicedActions.map((action) => {
        const actionSpec = actionSpecLookUp[action.type];
        if (!actionSpec || actionSpec === undefined) {
          return null;
        }
        const { title, description, path } = actionSpec;
        if (!path) {
          return null;
        }
        if (action.status === ActionStatus.Active) {
          let queryString = '';
          if (action.type === ActionType.PickInvestments) {
            queryString = `?source=actions`;
          }

          return (
            <ActionWrapper>
              <ActionLink
                to={
                  typeof path === 'function'
                    ? path({
                        wrapperType: WrapperType.Sipp,
                        actionId: action.id,
                        personalisePensionEnabled:
                          personalisePensionToggle?.enabled,
                      }) + queryString
                    : path
                }
              >
                <div>
                  <ActionItemHeading>
                    {typeof title === 'function'
                      ? title({
                          wrapperType: WrapperType.Sipp,
                        })
                      : title}
                  </ActionItemHeading>
                  <TextXS $noMargin $isDarkBg>
                    {typeof description === 'function'
                      ? description({
                          wrapperType: WrapperType.Sipp,
                        })
                      : description}
                  </TextXS>
                </div>
                <ArrowRight />
                <ActionDivider />
              </ActionLink>

              <Dismiss actionId={action.id} />
            </ActionWrapper>
          );
        }
        return null;
      })}
    </ListLayout>
  );
}

interface DismissProps {
  actionId: string;
}

function Dismiss({ actionId }: DismissProps) {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  return (
    <DismissWrapper
      onClick={(ev) => {
        ev.stopPropagation();
      }}
      isConfirmOpen={showConfirm}
    >
      <DismissButton
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setShowConfirm(!showConfirm);
        }}
      >
        <DismissIcon isConfirmOpen={showConfirm} />
      </DismissButton>
      <DismissInnerWrapper isConfirmOpen={showConfirm}>
        <DismissConfirm actionId={actionId} />
      </DismissInnerWrapper>
    </DismissWrapper>
  );
}

function DismissConfirm({ actionId }: DismissProps) {
  const { openToast } = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, reset } = useDismissActionMutation();
  const handleDismiss = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    try {
      await mutateAsync({ id: actionId });
      openToast('Action has been dismissed');
      reset();
      queryClient.invalidateQueries(useActionsQuery.getKey());
    } catch (e) {}
  };

  return (
    <AreYouSureWrapper>
      <TextSmall $noMargin>Are you sure?</TextSmall>
      <DismissButton onClick={handleDismiss} disabled={isLoading}>
        <ConfirmIcon />
      </DismissButton>
    </AreYouSureWrapper>
  );
}

interface ActionsContentProps {
  actions: ActionsQueryAction[];
}

function ActionsContent({ actions }: ActionsContentProps) {
  return (
    <Wrapper>
      <div>
        <H5 $isDarkBg>Your actions</H5>
        <TextSmall $isDarkBg>
          You have some actions to be done to make the most of your TILLIT
          account
        </TextSmall>
      </div>

      <ActionsList actions={actions} />
      <Text $fontSize={FontSize.small} $textAlign={TextAlign.center} $noMargin>
        <StyledLink to={actionsPath} $colorStyle="light" as={Link}>
          View all actions
        </StyledLink>
      </Text>
    </Wrapper>
  );
}

export function PopOver() {
  const actionsQuery = useActionsQuery({
    filter: { statuses: [ActionStatus.Active] },
  });
  const userProfile = useUserProfileQuery();
  const theme = useTheme();

  const [openState, setOpenState] = useState<'closed' | 'teaser' | 'open'>(
    Cookies.get(ACTIONS_DISMISSED_COOKIE_NAME) === 'true' ? 'closed' : 'teaser'
  );

  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <QueryState {...userProfile}>
      {() => (
        <QueryState {...actionsQuery}>
          {() => {
            if (
              !userProfile.data?.clientSummary?.accounts ||
              !actionsQuery.data?.actions ||
              actionsQuery.data?.actions.length === 0
            ) {
              return null;
            }

            if (!isSmUp) {
              return (
                <CustomSwipeableDrawer
                  height={openState === 'teaser' ? 'teaser' : 'normal'}
                  backgroundColor="richBlue"
                  onClose={() => {
                    Cookies.set(ACTIONS_DISMISSED_COOKIE_NAME, 'true', {
                      expires: ACTIONS_DISMISSED_COOKIE_EXPIRATION,
                      domain: '.tillitinvest.com',
                    });
                    setOpenState('closed');
                  }}
                  open={openState !== 'closed'}
                >
                  {openState !== 'open' && (
                    <ActionsTeaser
                      setMobileOpenState={(newState) => {
                        if (newState === 'closed') {
                          Cookies.set(ACTIONS_DISMISSED_COOKIE_NAME, 'true', {
                            expires: ACTIONS_DISMISSED_COOKIE_EXPIRATION,
                            domain: '.tillitinvest.com',
                          });
                        }
                        setOpenState(newState);
                      }}
                    />
                  )}
                  {openState === 'open' && (
                    <ActionsContent actions={actionsQuery.data!.actions!} />
                  )}
                </CustomSwipeableDrawer>
              );
            }

            if (openState === 'teaser') {
              return (
                <PopOverContainer>
                  <CloseButtonWrapper>
                    <IconButton
                      onClick={() => {
                        Cookies.set(ACTIONS_DISMISSED_COOKIE_NAME, 'true', {
                          expires: ACTIONS_DISMISSED_COOKIE_EXPIRATION,
                          domain: '.tillitinvest.com',
                        });
                        setOpenState('closed');
                      }}
                    >
                      <HiX />
                    </IconButton>
                  </CloseButtonWrapper>
                  <ActionsContent actions={actionsQuery.data!.actions!} />
                </PopOverContainer>
              );
            }

            return null;
          }}
        </QueryState>
      )}
    </QueryState>
  );
}
