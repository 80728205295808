import { yupResolver } from '@hookform/resolvers/yup';
import { getBuyEstimate } from 'components/Dialogs/Trading/helpers';
import { CombinedQueryState, QueryState } from 'components/QueryState';
import { Severity } from 'components/design-system/Alert/Alert';
import { H4 } from 'components/design-system/Heading/Heading';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { AmountInput } from 'components/design-system/Inputs/AmountInput/AmountInput';
import { ExclusivePill } from 'components/design-system/Pill/Pill';
import {
  GoBackButton,
  StepButton,
} from 'components/design-system/StepComponents/StepComponents';
import { TextSmall } from 'components/design-system/Text/Text';
import { InstrumentSelectorSelect } from 'components/feature/FundDetails/InstrumentSelector/InstrumentSelector';
import { ImportantBuyInformation } from 'components/feature/PortfolioBuilder/ImportantInformation/ImportantBuyInformation';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import { useMode } from 'components/feature/mode/useMode';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import * as format from 'formatting';
import {
  Position,
  WrapperType,
  useAccountQuery,
  useAccountsQuery,
  useAssetQuery,
  useBuyOrderDetailsByAssetQuery,
  useCreateQuickOrderMutation,
  usePortfolioRebalancingQuery,
  usePortfolioRebalancingsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { useGetDefaultSelectedInstrumentIsin } from 'helpers/assetHelpers';
import { trackGa } from 'helpers/track';
import { amount } from 'helpers/yupExtensions';
import { useFilterInstrumentsUserCanTrade } from 'hooks/useFilterInstrumentsUserCanTrade';
import { generateCheckoutPath, generateFundDetailsPath } from 'paths';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { HiExternalLink } from 'react-icons/hi';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { PartialDeep } from 'type-fest';
import { AnyAsset, AssetQueryAssetInstrument } from 'types/graphqlTypes';
import * as yup from 'yup';
import Reference from 'yup/lib/Reference';
import { ShareClassInfoPopover } from '../../_shared/ShareClassInfoPopover';
import { BasketSummary, useFundsBasket } from '../../hooks/useFundsBasket';
import {
  AmountInputContainer,
  ButtonContainer,
  ErrorAlert,
  ErrorMessage,
  ExclusivePillWrapper,
  FundDoLink,
  KeyValue,
  PendingOrdersAlert,
  Pill,
  PillContainer,
  Section,
  ShareClassWrapper,
} from '../AddToBasketDialog.style';

export enum OnProceedAction {
  quickOrder = 'quickOrder',
  addToBasket = 'addToBasket',
}

interface AddBuyOrderToBasketDialogForm {
  onProceed: (action: OnProceedAction, orderId?: string) => void;
  onGoBack?: () => void;
  asset: AnyAsset;
  selectedAccountType?: WrapperType;
  selectedAccountId?: string;
  selectedIsin?: string;
  selectedPosition?: PartialDeep<Position>;
  flow: 'fullFlow' | 'addToBasketOnlyFlow';
  handleInstrumentChange?: (isin: string) => void;
}

interface createQuickOrderMutationProps {
  accountId: string;
  isin: string;
  amount: number;
}
const useStartQuickBuyOrder = () => {
  const queryClient = useQueryClient();
  const createQuickOrderQuery = useCreateQuickOrderMutation();
  const createQuickOrderMutation = async ({
    accountId,
    isin,
    amount,
  }: createQuickOrderMutationProps) => {
    const createQuickOrderResult = await createQuickOrderQuery.mutateAsync({
      input: {
        accountId: accountId,
        buyOrders: [{ isin, amount }],
        sellOrders: [],
      },
    });
    queryClient.removeQueries({
      queryKey: usePortfolioRebalancingQuery.getKey({
        id: createQuickOrderResult.createQuickOrder.id,
      }),
    });
    return createQuickOrderResult;
  };
  return {
    createQuickOrderQuery,
    createQuickOrderMutation,
  };
};

function deriveBasketAmountLabel(basketSummary: BasketSummary) {
  if (basketSummary.amount < 0) {
    return 'Basket est. cash raised (net)';
  } else if (basketSummary.sellOrderCount === 0) {
    return 'Basket total (net)';
  } else {
    return 'Est. basket total (net)';
  }
}

function deriveRemainingAmountLabel(
  remainingAmount: number,
  basketSummary: BasketSummary
) {
  if (remainingAmount < 0) {
    return 'Order shortfall';
  } else if (basketSummary.sellOrderCount === 0) {
    return 'Available to invest';
  } else {
    return 'Est. available to invest';
  }
}

function AddBuyOrderToBasketStepForm({
  onProceed,
  onGoBack,
  asset,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  flow,
  selectedIsin,
  handleInstrumentChange,
}: AddBuyOrderToBasketDialogForm) {
  const [, setMode] = useMode();
  const history = useHistory();
  const {
    createQuickOrderQuery,
    createQuickOrderMutation,
  } = useStartQuickBuyOrder();

  const [hasError, setHasError] = useState(false);

  const {
    addBuyOrder,
    updateBuyOrder,
    getBuyOrder,
    basketSummary,
  } = useFundsBasket({
    selectedAccountId,
  });

  const { openToast } = useToast();

  const accountsQuery = useAccountsQuery(undefined, {
    enabled: !!selectedAccountId,
  });
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === selectedAccountType
  );

  const buyOrderDetailsByAssetQuery = useBuyOrderDetailsByAssetQuery({
    assetId: `${asset.id}`,
    accountId: selectedAccountId ? selectedAccountId : null,
  });

  const buyOrderDetails =
    buyOrderDetailsByAssetQuery?.data?.buyOrderDetailsByAsset;

  const availableCash = currentAccount?.valuationSummary?.uninvestedCash;
  const remainingAmount =
    Math.round(((availableCash || 0) - basketSummary.amount) * 100) / 100;
  const rangeValues =
    buyOrderDetailsByAssetQuery.data?.buyOrderDetailsByAsset?.validRange
      ?.valueRange;
  const minAmount = rangeValues?.minimumValue ? rangeValues?.minimumValue : 1;
  const maxUnauthAmount: number = 1000000;
  const hasSelectedAccountId = !!selectedAccountId;
  const id = asset?.id!;
  const name = asset?.name || '';

  const addBuyOrderToBasketSchema = yup.object().shape({
    amount: amount()
      .label('Amount')
      .typeError('Please enter a valid number')
      .nullable()
      .min(
        yup.ref('$minAmount') as Reference<number>,
        `Please type in a value bigger than ${format.currencyFull(minAmount!)}`
      )
      .when('$hasSelectedAccountId', {
        is: false,
        then: amount()
          .typeError('Please enter a valid number')
          .max(
            yup.ref('$maxUnauthAmount') as Reference<number>,
            `Please type in a value no bigger than ${format.currencyFull(
              maxUnauthAmount!
            )}`
          ),
      }),
  });

  const assetQuery = useAssetQuery({ id });

  const existingBuyOrder = getBuyOrder(id);

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    control,
    errors,
    watch,
  } = useForm({
    defaultValues: {
      amount: existingBuyOrder ? existingBuyOrder?.amount : '',
      isin: selectedIsin,
    },
    resolver: yupResolver(addBuyOrderToBasketSchema),
    context: { minAmount, maxUnauthAmount, hasSelectedAccountId },
  });
  const queryClient = useQueryClient();
  const watchIsin = watch('isin');
  const watchAmount = watch('amount');

  const filteredInstruments = useFilterInstrumentsUserCanTrade<AssetQueryAssetInstrument>(
    assetQuery.data?.asset?.instruments?.nodes ?? [],
    selectedAccountId
  );

  const selectedInstrument =
    filteredInstruments.length === 1
      ? filteredInstruments[0]
      : filteredInstruments.find((i) => i.isin === watchIsin);

  const existingInstrumentPosition = currentAccount?.positions?.find(
    (position) => position.isin === watchIsin
  );

  const hasPendingOrders = !!buyOrderDetails?.pendingOrders?.length;
  const charges = buyOrderDetails?.charges;

  const watchAmountNumber =
    typeof watchAmount === 'string' ? parseFloat(watchAmount) : watchAmount;

  const units =
    selectedInstrument && charges && watchAmountNumber
      ? getBuyEstimate(selectedInstrument, watchAmountNumber, charges)
      : null;

  const onQuickOrderSubmit = async (data: any) => {
    if (!selectedAccountId || !selectedAccountType) {
      return;
    }
    const amount = parseFloat(data.amount);
    const isin = data.isin || selectedInstrument?.isin;
    try {
      const createQuickOrderResult = await createQuickOrderMutation({
        accountId: selectedAccountId,
        isin,
        amount,
      });

      const orderId = createQuickOrderResult.createQuickOrder.id;
      if (availableCash !== undefined && amount <= availableCash) {
        onProceed(OnProceedAction.quickOrder, orderId);
      } else {
        history.push(
          generateCheckoutPath({
            wrapperType: getPathSegmentForWrapperType(selectedAccountType),
            selectedRebalancingId: orderId,
          })
        );
      }
    } catch {
      // error handled by query state
    }
  };

  const handleInvalidation = () => {
    // invalidate query for the mini basket so all data updates
    queryClient.invalidateQueries(usePortfolioRebalancingsQuery.getKey());
    if (selectedAccountId) {
      queryClient.invalidateQueries(
        usePortfolioRebalancingQuery.getKey({ id: selectedAccountId })
      );
    }
    if (selectedAccountId) {
      queryClient.invalidateQueries(
        useAccountQuery.getKey({ id: selectedAccountId })
      );
    }
    queryClient.invalidateQueries(useAccountsQuery.getKey());
  };

  const onSubmit = async (data: any) => {
    setMode({
      wrapperType: selectedAccountType,
      mode: 'buy',
    });

    setHasError(false);

    const isin = data.isin || selectedInstrument?.isin;

    const instrument = filteredInstruments.find((i) => i.isin === isin);
    if (!instrument) return;

    const units = getBuyEstimate(instrument, data.amount);

    const existingBuyOrder = getBuyOrder(id);

    const tags =
      asset && asset.tags?.nodes
        ? asset.tags.nodes.filter((tag) => tag?.display).map((tag) => tag?.name)
        : [];

    try {
      if (existingBuyOrder) {
        const updatedBuyOrder = {
          ...existingBuyOrder,
          isin,
          units,
          amount: parseFloat(data.amount),
        };
        await updateBuyOrder(updatedBuyOrder);
        flow === 'fullFlow' && openToast('Buy order has been updated');
      } else {
        trackGa({
          event: GaEventNames.addToCart,
          orderType: 'rebalancing',
          ecommerce: {
            items: [
              {
                item_id: asset!.id,
                item_name: asset!.name,
                affiliation: 'Tillit',
                currency: 'GBP',
                item_brand: asset!.assetManager?.name,
                item_category: tags[0],
                item_category2: tags[1],
                item_category3: tags[2],
                item_category4: tags[3],
                item_category5: tags[4],
                item_variant: selectedAccountType,
                price: data.amount,
                quantity: Math.round(units * 100) / 100,
              },
            ],
          },
        });

        await addBuyOrder({
          type: 'buyOrder',
          id: id,
          slug: asset.slug!,
          instrumentName: instrument.name,
          instrumentType: instrument.instrumentType,
          name,
          amount: parseFloat(data.amount),
          assetClass: asset.assetClass?.name || '',
          isDarkUniverse: instrument.isDarkUniverse || false,
          description: asset.description || '',
          fundName: name,
          isin,
          units: units,
          askPrice: instrument.askPrice,
          minimumTradeUnit: instrument.minimumTradeUnit,
          quoteUnit: instrument.quoteUnit,
          minAmount: minAmount!,
        });
        flow === 'fullFlow' &&
          openToast('Buy order has been added to your basket');
      }

      onProceed(OnProceedAction.addToBasket);
    } catch {
      setHasError(true);
    } finally {
      handleInvalidation();
    }
  };

  const unitTypeLabel =
    selectedInstrument?.instrumentType === 'Fund' ? 'units' : 'shares';

  const remainingAmountLabel = deriveRemainingAmountLabel(
    remainingAmount,
    basketSummary
  );

  const basketAmountLabel = deriveBasketAmountLabel(basketSummary);

  return (
    <CombinedQueryState
      queries={[assetQuery, buyOrderDetailsByAssetQuery, accountsQuery]}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <PillContainer>
            <Pill $filled $color="buy">
              BUY
            </Pill>
            {selectedAccountType &&
              selectedAccountType !== WrapperType.Unknown && (
                <Pill>{getShortNameForWrapperType(selectedAccountType)}</Pill>
              )}
          </PillContainer>
          <div>
            <H4>{selectedInstrument?.name}</H4>
            <FundDoLink
              to={{
                pathname: generateFundDetailsPath({
                  id: asset?.id!,
                  slug: asset?.slug!,
                }),
                state: {
                  shouldGoBack: true,
                },
              }}
              target="_blank"
              // onClick={() => trackFundOpen('link', asset, index)}
            >
              What does this fund do? <HiExternalLink />
            </FundDoLink>

            {hasPendingOrders && (
              <PendingOrdersAlert severity={Severity.error}>
                You currently have unsettled orders against this asset. Did you
                intend to create a new order?
              </PendingOrdersAlert>
            )}

            <Section>
              {selectedInstrument?.isTargetDateFund && (
                <input
                  type="hidden"
                  name="isin"
                  value={selectedIsin}
                  ref={register}
                />
              )}
              {!selectedInstrument?.isTargetDateFund &&
                filteredInstruments.length > 1 && (
                  <>
                    <Controller
                      control={control}
                      name="isin"
                      render={({ onChange, value }) => (
                        <>
                          <ShareClassWrapper>
                            <InstrumentSelectorSelect
                              account={currentAccount}
                              instrument={selectedInstrument!}
                              instruments={filteredInstruments}
                              onChange={(isin) => {
                                handleInstrumentChange?.(isin);
                                onChange(isin);
                              }}
                              value={value}
                              $color="secondary"
                            />
                            <ShareClassInfoPopover size="xs" />
                          </ShareClassWrapper>
                          {selectedInstrument?.isCustomUniverse && (
                            <ExclusivePillWrapper>
                              <ExclusivePill $canHover={false}>
                                Employer Exclusive
                              </ExclusivePill>
                            </ExclusivePillWrapper>
                          )}
                        </>
                      )}
                    />
                  </>
                )}
              <AmountInputContainer>
                <AmountInput
                  autoFocus
                  maxLength={8}
                  id="amount"
                  name="amount"
                  required
                  ref={register}
                />
                {errors.amount && (
                  <ErrorMessage>{errors.amount.message}</ErrorMessage>
                )}
              </AmountInputContainer>

              {!!availableCash && (
                <KeyValue
                  label={remainingAmountLabel}
                  value={
                    <>
                      {format.currencyFull(Math.abs(remainingAmount))}
                      <InfoPopoverV2
                        size="small"
                        $width="normal"
                        content={
                          <div>
                            {remainingAmount < 0 ? (
                              <TextSmall>
                                This is your order shortfall amount. It takes
                                into account both your account cash balance and
                                the net value of buy and sell orders in your
                                basket. You can continue to add orders to you
                                baskets.
                              </TextSmall>
                            ) : (
                              <TextSmall>
                                This is the amount you have available to invest
                                in funds. It takes into account both your
                                account cash balance and the net value of buy
                                and sell orders in your basket.
                              </TextSmall>
                            )}
                            <KeyValue
                              label="Cash balance"
                              value={format.currencyFull(availableCash)}
                              justifyContent="space-between"
                            />
                            <KeyValue
                              label={basketAmountLabel}
                              value={format.currencyFull(
                                Math.abs(basketSummary.amount)
                              )}
                              justifyContent="space-between"
                            />
                            <KeyValue
                              label={remainingAmountLabel}
                              value={format.currencyFull(remainingAmount)}
                              justifyContent="space-between"
                            />
                          </div>
                        }
                      />
                    </>
                  }
                />
              )}
              {units !== null && (
                <KeyValue
                  label={`Est. number of ${unitTypeLabel}`}
                  value={`${Math.round(units * 100000) / 100000}`}
                />
              )}
            </Section>
          </div>

          {selectedInstrument && selectedInstrument.askPrice && (
            <MinTradeUnitStatus
              amount={watchAmountNumber}
              askPrice={selectedInstrument.askPrice}
              minimumTradeUnit={selectedInstrument.minimumTradeUnit}
            />
          )}

          {selectedInstrument && (
            <QueryState {...buyOrderDetailsByAssetQuery}>
              {() => (
                <ImportantBuyInformation
                  selectedInstrument={selectedInstrument}
                  amountEntered={watchAmountNumber}
                  charges={charges}
                  existingPosition={existingInstrumentPosition}
                />
              )}
            </QueryState>
          )}
          <ButtonContainer>
            <StepButton
              className="magenta"
              type="submit"
              disabled={isSubmitting}
            >
              {existingBuyOrder ? 'Update basket' : 'Add to basket'}
            </StepButton>
            {hasError && (
              <ErrorAlert severity={Severity.error} padding="small">
                Something went wrong, please wait and then try again.
              </ErrorAlert>
            )}
            {flow === 'fullFlow' && selectedAccountId && (
              <>
                <StepButton
                  className="richBlue"
                  type="button"
                  onClick={handleSubmit(onQuickOrderSubmit)}
                  disabled={isSubmitting}
                >
                  Place Order
                </StepButton>
                {createQuickOrderQuery.error && (
                  <ErrorAlert severity={Severity.error} padding="small">
                    Something went wrong, please wait and then try again.
                  </ErrorAlert>
                )}
              </>
            )}
            {flow === 'fullFlow' && <GoBackButton onClick={onGoBack} />}
          </ButtonContainer>
        </div>
      </form>
    </CombinedQueryState>
  );
}

export function AddBuyOrderToBasketStep({
  onProceed,
  onGoBack,
  asset,
  selectedAccountType,
  selectedAccountId,
  selectedPosition,
  flow,
  selectedIsin,
  handleInstrumentChange,
}: AddBuyOrderToBasketDialogForm) {
  const urlParams = new URLSearchParams(window.location.search);
  const search = urlParams.get('search') || '';

  const [mode] = useMode();

  const { basketBuyOrders, portfolioRebalancingsQuery } = useFundsBasket({
    selectedAccountId,
  });
  const { signedIn } = useAuth();

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const accountsQuery = useAccountsQuery(undefined, {
    enabled: signedIn,
  });

  const id = asset?.id!;

  const assetQuery = useAssetQuery({ id });

  const defaultSelectedIsin = useGetDefaultSelectedInstrumentIsin(
    assetQuery.data?.asset,
    mode,
    userProfileQuery.data?.userProfile ?? undefined,
    accountsQuery.data?.accounts ?? [],
    search,
    basketBuyOrders,
    undefined,
    selectedIsin
  );

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {() => (
        <QueryState {...accountsQuery}>
          {() => (
            <QueryState {...assetQuery}>
              {() =>
                defaultSelectedIsin && (
                  <AddBuyOrderToBasketStepForm
                    onProceed={onProceed}
                    onGoBack={onGoBack}
                    asset={asset}
                    selectedAccountType={selectedAccountType}
                    selectedAccountId={selectedAccountId}
                    selectedPosition={selectedPosition}
                    flow={flow}
                    selectedIsin={defaultSelectedIsin}
                    handleInstrumentChange={handleInstrumentChange}
                  />
                )
              }
            </QueryState>
          )}
        </QueryState>
      )}
    </QueryState>
  );
}
