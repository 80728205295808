import { Popover } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontWeight,
  Text,
  TextSmall,
} from 'components/design-system/Text/Text';
import { assetClassBreakdownColors } from 'components/feature/FundDetails/Breakdown/_shared/colourHelper';
import type {
  Breakdown,
  BreakdownComponentProps,
} from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { colors } from 'constants/colors';
import { currencyFull, percent } from 'formatting';
import numeral from 'numeral';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import styled from 'styled-components';

interface ContainerProps {
  $disabled?: boolean;
}

const Container = styled.div<ContainerProps>`
  height: 2rem;
  width: 100%;
  ${({ $disabled }) => $disabled && 'opacity: 0.5;'}
`;

const Stack = styled.button`
  height: 100%;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const PercentageIncrease = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${colors.success};
  ${Text} {
    color: ${colors.success};
  }
`;

const PercentageDecrease = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  color: ${colors.danger};
  ${Text} {
    color: ${colors.danger};
  }
`;

const tolerance = 0.01;

function isGreaterThan(a: number | undefined, b: number | undefined) {
  if (a === undefined || b === undefined) {
    return false;
  }
  return numeral(a).subtract(b).value() > tolerance;
}

interface StackedBarChartProps extends BreakdownComponentProps {
  onClick: (breakdown: Breakdown) => void;
  disabled?: boolean;
  type: 'asset' | 'region';
  colorLookUp?: { [key: string]: string };
}

export const StackedBarChart = ({
  breakdown,
  onClick,
  disabled,
  type,
  colorLookUp,
}: StackedBarChartProps) => {
  return (
    <div>
      <Container $disabled={disabled}>
        {breakdown.lines?.map((breakdownLine) => {
          const getBackground = (): Record<string, string> => {
            if (breakdownLine.name === 'Cash') {
              return {
                background: `linear-gradient(135deg, ${colors.richBlack} 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(225deg, #d8d8d8 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(315deg, ${colors.richBlack} 25%, transparent 25%) 0px 0/ 8px 8px, linear-gradient(45deg, #d8d8d8 25%, #e8e8e8 25%) 0px 0/ 8px 8px`,
              };
            }

            const color =
              type === 'asset'
                ? assetClassBreakdownColors[breakdownLine.name]
                : colorLookUp?.[breakdownLine.name];

            const background =
              breakdownLine.proportion > 0
                ? color
                : `repeating-linear-gradient(-45deg, #ffffff 0px, #ffffff 2px, ${color} 3px, ${color} 10px, #ffffff 11px, #ffffff 15px)`;

            if (background) {
              return {
                background,
              };
            }

            return {};
          };

          const style: Record<string, string> = {
            ...getBackground(),
            width: `${Math.abs(breakdownLine.proportion) * 100}%`,
          };

          return (
            <Popover
              key={breakdown.name}
              popupId="asset-allocation-chart-popover"
              openOn="hover"
              content={
                <>
                  <TextSmall $fontWeight={FontWeight.medium} $noMargin>
                    {breakdownLine.name}
                  </TextSmall>
                  <TextSmall $noMargin>
                    {percent(breakdownLine.proportion)} |{' '}
                    {currencyFull(breakdownLine.amount)}
                  </TextSmall>
                  {isGreaterThan(
                    breakdownLine.amount,
                    breakdownLine.existingAmount
                  ) && (
                    <PercentageIncrease>
                      <AiOutlineArrowUp />{' '}
                      <TextSmall $noMargin>
                        {percent(
                          breakdownLine.proportion -
                            breakdownLine.existingProportion
                        )}
                      </TextSmall>
                    </PercentageIncrease>
                  )}
                  {isGreaterThan(
                    breakdownLine.existingAmount,
                    breakdownLine.amount
                  ) && (
                    <PercentageDecrease>
                      <AiOutlineArrowDown />{' '}
                      <TextSmall $noMargin>
                        {percent(
                          breakdownLine.proportion -
                            breakdownLine.existingProportion
                        )}
                      </TextSmall>
                    </PercentageDecrease>
                  )}
                </>
              }
            >
              {(props) => (
                <Stack
                  {...props}
                  onClick={() => onClick(breakdownLine)}
                  style={style}
                />
              )}
            </Popover>
          );
        })}
      </Container>
    </div>
  );
};
