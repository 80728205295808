import { Alert } from 'components/design-system/Alert/Alert';
import { Card } from 'components/design-system/Card/Card';
import { StepContainer } from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import {
  FaRegCircle,
  FaRegCircleCheck,
  FaRegCircleStop,
} from 'react-icons/fa6';
import styled from 'styled-components';

export const ActionsListContainer = styled(StepContainer)`
  padding: calc(56px + 2rem) 0;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: calc(72px + 2rem), 0;
  }
`;

export const UncheckedIcon = styled(FaRegCircle)`
  width: 2rem;
  height: 2rem;
  color: ${colors['magenta-200']};
  grid-area: icon;
`;

export const CheckedIcon = styled(FaRegCircleCheck)`
  width: 2rem;
  height: 2rem;
  color: ${colors['magenta-200']};
  grid-area: icon;
`;

export const DismissedIcon = styled(FaRegCircleStop)`
  width: 2rem;
  height: 2rem;
  color: ${colors['magenta-50']};
  grid-area: icon;
`;

export const ListLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;

export const CardLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: 'icon h2' 'icon text' 'action action';
  gap: 0.5rem 1rem;
  align-items: center;
  justify-content: stretch;

  > ${ActionsWrapper} {
    grid-area: action;
  }

  > h2 {
    grid-area: h2;
  }

  > p {
    grid-area: text;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-areas: 'icon h2 action' 'icon text action';
    gap: 0 1rem;
  }
`;

export const ErrorAlert = styled(Alert)`
  margin: 0.5rem 0;
  padding: 0.5rem;
`;

interface ActionCardProps {
  $active?: boolean;
}
export const ActionCard = styled(Card)<ActionCardProps>`
  position: relative;
  opacity: ${({ $active }) => ($active ? 1 : 0.7)};
`;
