import { FontSize, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${(p) => p.theme.breakpoints.down('md')} {
    white-space: nowrap;
  }
`;

interface TrProps {
  status?: 'buyOrder' | 'sellOrder' | 'existing' | 'mix';
}

export const Tr = styled.tr<TrProps>`
  background: ${(props) => {
    if (props.status === 'buyOrder') return '#0ACF9715';
    if (props.status === 'sellOrder') return '#E5493815';
    return 'inherit';
  }};
`;

export const Th = styled(Text).attrs({ as: 'th', $fontSize: FontSize.xs })`
  border-bottom: 1px solid ${colors.darkGrey};
  padding: 0.5rem 0;
  text-align: left;
  &:not(:nth-child(1)) {
    padding-left: 1rem;
  }
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const Td = styled(Text).attrs({ as: 'td', $fontSize: FontSize.xs })`
  border-bottom: 1px solid ${colors.midGrey};
  padding: 0.25rem 0;
  &:not(:nth-child(1)) {
    padding-left: 1rem;
  }
  &:not(:last-child) {
    padding-right: 1rem;
  }
`;

export const NameTdInner = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
