import vjs, { VideoJsPlayerOptions } from '@tillitinvest/video.js';
import { VideoPlayer } from 'components/VideoPlayer/VideoPlayer';
import { AssetQuery, Chapter } from 'generated/graphql';
import styled from 'styled-components';

interface VideoContainerProps {
  $isIntro: boolean;
}

const VideoContainer = styled.div<VideoContainerProps>`
  width: calc(100vw - 2rem);
  max-width: ${({ $isIntro }) => ($isIntro ? '1080px' : '980px')};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100%;
  }
`;

type Video = Exclude<AssetQuery['asset'], undefined | null>['meetManagerVideo'];

interface TutorialVideoProps {
  video: Video;
  description: string;
  autoplay?: boolean;
  chapters?: Chapter[];
  chapterVariant?: 'list' | 'buttons';
  $isIntro?: boolean;
  onVideoEnd?: () => void;
}

// TODO: Maybe do this via module augmentation instead of introducing a 'fake'
// new type?
interface CredentialledSourceObject extends vjs.Tech.SourceObject {
  withCredentials?: boolean;
}

export function TutorialVideo({
  video,
  description,
  autoplay = true,
  $isIntro = false,
  onVideoEnd,
}: TutorialVideoProps) {
  const videoOptions: VideoJsPlayerOptions = {
    autoplay,
    controls: true,
    responsive: true,
    fluid: true,
    html5: {
      preloadTextTracks: false,
      nativeTextTracks: false,
    },
    poster: video?.thumbnailUrl || undefined,
  };

  videoOptions.sources = [
    {
      src: video?.videoUrl!,
      withCredentials: true,
    } as CredentialledSourceObject,
  ];

  if (video?.subtitlesUrl) {
    videoOptions.tracks = [
      {
        src: video.subtitlesUrl,
        kind: 'subtitles',
        srclang: 'en',
        label: 'English',
      },
    ];
  }

  return (
    <VideoContainer $isIntro={$isIntro}>
      <VideoPlayer
        videoJsOptions={videoOptions}
        video={description}
        videoType="Personalise Pension"
        requiresAuthentication={true}
        onVideoEnd={onVideoEnd}
        playButtonTheme="light"
      />
    </VideoContainer>
  );
}
