import { H5, TextAlign } from 'components/design-system/Heading/Heading';
import { TextLarge } from 'components/design-system/Text/Text';
import { ResponsiveDialogProps } from 'components/Dialogs/ResponsiveDialog';
import { slugify } from 'formatting';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { HiChevronDown } from 'react-icons/hi';
import { beliefsAndValuesContentType } from '../BeliefsAndValues';
import {
  GlossaryAccordion,
  GlossaryAccordionDetails,
  GlossaryAccordions,
  GlossaryAccordionSummary,
  GlossaryAction,
  StyledGlossaryDialog,
} from './Glossary.styles';

interface GlossaryProps {
  glossaryEntries: beliefsAndValuesContentType[];
  selectedEntries: string[];
  isDialog?: boolean;
  onSelectEntry: (label: string) => void;
}

export function Glossary({
  glossaryEntries,
  selectedEntries,
  isDialog = true,
  onSelectEntry,
}: GlossaryProps) {
  return (
    <GlossaryAccordions $isDialog={isDialog}>
      {glossaryEntries.map((entry) => {
        const isSelected = selectedEntries.includes(entry.label);

        return (
          <GlossaryAccordion key={entry.label}>
            <GlossaryAccordionSummary
              expandIcon={<HiChevronDown />}
              aria-controls={`${slugify(entry.label)}-content`}
              id={`${slugify(entry.label)}-header`}
            >
              <TextLarge $noMargin>{entry.label}</TextLarge>
            </GlossaryAccordionSummary>

            <GlossaryAccordionDetails>
              {entry.description}

              <GlossaryAction
                $isActive={!isSelected}
                onClick={() => onSelectEntry(entry.label)}
              >
                {isSelected ? (
                  <>
                    <FaMinus />
                    Remove
                  </>
                ) : (
                  <>
                    <FaPlus />
                    Add
                  </>
                )}
              </GlossaryAction>
            </GlossaryAccordionDetails>
          </GlossaryAccordion>
        );
      })}
    </GlossaryAccordions>
  );
}

interface GlossaryDialogProps extends ResponsiveDialogProps {
  glossaryEntries: beliefsAndValuesContentType[];
  selectedEntries: string[];
  onSelectEntry: (label: string) => void;
}

export function GlossaryDialog({
  glossaryEntries,
  selectedEntries,
  onSelectEntry,
  onClose,
  ...props
}: GlossaryDialogProps) {
  return (
    <StyledGlossaryDialog
      aria-labelledby="Glossary"
      onClose={onClose}
      {...props}
    >
      <H5 $textAlign={TextAlign.center}>Glossary</H5>
      <Glossary
        glossaryEntries={glossaryEntries}
        selectedEntries={selectedEntries}
        onSelectEntry={onSelectEntry}
      />
    </StyledGlossaryDialog>
  );
}
