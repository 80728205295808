export enum OnboardingStepNames {
  referralCode = '0 - Referral code',
  selectAccountType = '1 -  pick your account',
  pensionIntroduction = 'Pension a - pension introduction',
  eligibility = '2 - eligibility',
  name = '3 - name',
  username = '4 - username',
  password = '5 - password',
  emailVerification = '6 - email verification',
  isaDeclaration = '7 - ISA declaration (optional ISA only)',
  dob = '8 - DoB',
  moreAboutYouPension = 'Pension b - More about you',
  yourEmployerPension = 'Pension b.1 - Your employer',
  aboutYourOtherPensions = 'Pension c - About your other pensions',
  fundingChoices = 'Pension d - Funding choices',
  autoEscalation = 'Pension d.1 - Auto Escalation',
  illustration = 'Pension e - Illustration',
  reviewYourDecision = 'Pension f - Review your decision',
  whatYouAreInvestedInStep = 'Pension f.1 - What you are invested in',
  declarationSipp = 'Pension g - Declaration',
  nationalInsuranceNumber = '9 - national insurance number',
  addressHistory = '10 - address history',
  nationality = '11 - nationality',
  nationalIdentifier = '11.a - national identifier',
  bankDetails = '12 - bank details',
  idSubmitted = '13 - ID submitted',
  beneficiaries = 'Pension h - Beneficiaries',
  verificationStarted = '14 - verification started',
  onboardingResult = '15 - onboarding result',
  onboardingRating = '16 - onboarding rating',
  mobileMfa = '17 - mobile mfa',
  addCash = '18 - add cash',
}

export enum GaEventNames {
  addToCart = 'add_to_cart',
  click = 'click',
  login = 'login',
  signOut = 'signOut',
  mfa = 'mfa',
  navigationClick = 'navigation_click',
  onboarding = 'onboarding',
  onboardingAbort = 'onboarding_abort',
  onboardingEnd = 'onboarding_end',
  onboardingStart = 'onboarding_start',
  onboardingResume = 'onboarding_resume',
  popover = 'popover',
  popoverInteractions = 'popover_interactions',
  purchase = 'purchase',
  updateCart = 'update_cart',
  removeFromCart = 'remove_from_cart',
  selectContent = 'select_content',
  selectItem = 'select_item',
  userIdEvent = 'userid_event',
  viewItem = 'view_item',
  submitSearch = 'submit_search',
  viewSearchResults = 'view_search_results',
  virtualPageview = 'virtual_pageview',
  checkout = 'checkout',
  checkoutStart = 'checkout_start',
  checkoutEnd = 'checkout_end',
  checkoutEdit = 'checkout_edit_order',
  feedback = 'feedback',
  feedbackOpportunity = 'feedback_opportunity',
  video = 'video',
  multiStepFormStart = 'multi_step_form_start',
  multiStepFormFinish = 'multi_step_form_finish',
  multiStepFormStep = 'multi_step_form_step',
  multiStepFormExit = 'multi_step_form_exit',
}

export enum GaProperties {
  videoTitle = 'video_title',
  videoType = 'Video Type',
  videoUrl = 'video_url',
  videoProgress = 'video_progress',
  videoProvider = 'video_provider',
  videoDuration = 'video_duration',
  videoCurrentTime = 'video_current_time',
  signedIn = 'Signed In',
  form = 'form',
  formStep = 'form_step',
}
