import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from './PersonalisePensionStep.styles';

export const Step2AssetClassesKeyTakeaways = () => {
  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>
        Asset Classes: Key takeaways
      </H1Large>

      <OverviewCopyContainer>
        <OverViewList>
          <AnimatePresence>
            <KeyPointLi delay={0.25}>
              <TextNormal $noMargin>
                Asset classes can be grouped into two types: growth-focused
                assets and protection-focused assets.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>
                Growth assets are those that aim to grow your money over time.
              </TextNormal>
              <ul>
                <KeyPointLi delay={0.45}>
                  <TextNormal $noMargin>
                    Examples include equities, and alternative assets like
                    private equity, property and infrastructure.
                  </TextNormal>
                </KeyPointLi>
                <KeyPointLi delay={0.55}>
                  <TextNormal $noMargin>
                    Growth assets are typically more volatile than protection
                    assets, but can come with higher returns.
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.65}>
              <TextNormal $noMargin>
                Protection assets are those that aim to protect the money in
                your pension pot.
              </TextNormal>
              <ul>
                <KeyPointLi delay={0.75}>
                  <TextNormal $noMargin>
                    Examples include bonds and some types of commodities.
                  </TextNormal>
                </KeyPointLi>
                <KeyPointLi delay={0.85}>
                  <TextNormal $noMargin>
                    Protection assets are typically less volatile than growth
                    assets, but typically offer lower, more stable returns.
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.95}>
              <TextNormal $noMargin>
                The longer you have left to retirement, the more you want to
                focus on growth assets. Those closer to retirement want to
                consider more of a balance between growth and protection assets.
              </TextNormal>
            </KeyPointLi>
          </AnimatePresence>
        </OverViewList>
      </OverviewCopyContainer>
    </Content>
  );
};
