import { Typography } from '@material-ui/core';
import { Card } from 'components/design-system/Card/Card';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { AiOutlineClose } from 'react-icons/ai';
import styled, { css } from 'styled-components';
import { CustomScrollBar, ElementSmallShadow } from 'theme/shared/base.styles';

interface VideoPlayerContainerProps {
  $hasChapters?: boolean;
}

export const VideoPlayerContainer = styled.div<VideoPlayerContainerProps>`
  width: 100%;
  ${({ $hasChapters, theme }) =>
    $hasChapters &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      ${theme.breakpoints.up('md')} {
        flex-direction: row;
        align-items: stretch;
        column-gap: 2.5rem;

        .video-js {
          padding-top: 34.8% !important;
        }
      }
    `}

  .video-js {
    position: relative;
    &.vjs-error .vjs-error-display .vjs-modal-dialog-content {
      display: flex;
      align-items: center;
      color: ${colors.white} !important;
      background-color: ${transparentize(0.4, colors.richBlue)} !important;
    }
  }
`;

interface StyledVideoProps {
  $hasRoundedBorders?: boolean;
  $playButtonTheme: 'light' | 'dark';
}

export const StyledVideo = styled.video<StyledVideoProps>`
  color: ${colors.white};
  border-radius: ${({ $hasRoundedBorders }) =>
    $hasRoundedBorders ? '16px' : '0px'};
  overflow: hidden;
  background-color: transparent;

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${ElementSmallShadow}
  }

  &:hover {
    button.vjs-big-play-button,
    button.vjs-big-play-button:focus {
      background-color: ${({ $playButtonTheme }) =>
        $playButtonTheme === 'dark'
          ? transparentize(0.8, colors.white)
          : transparentize(0.9, colors.richBlack)} !important;
      box-shadow: ${({ $playButtonTheme }) =>
        $playButtonTheme === 'dark'
          ? 'none'
          : `5px 5px 10px ${transparentize(0.9, colors.richBlack)}`};
    }
  }

  &.show-disclaimer {
    .vjs-poster {
      display: inline-block;
    }
    .vjs-control-bar {
      display: none;
    }
  }

  .vjs-poster {
    background-size: cover;
  }

  button.vjs-big-play-button {
    font-size: 42px;
    border: 3px solid ${colors.white};
    border-radius: 50%;
    background-color: ${({ $playButtonTheme }) =>
      $playButtonTheme === 'dark'
        ? 'transparent'
        : transparentize(0.95, colors.richBlack)};
    box-shadow: ${({ $playButtonTheme }) =>
      $playButtonTheme === 'dark'
        ? 'none'
        : `5px 5px 10px ${transparentize(0.95, colors.richBlack)}`};
    width: 70px;
    height: 70px;
    margin-top: -35px;
    margin-left: -35px;
  }
`;

export const DisclaimerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${transparentize(0.2, colors.white)};
  backdrop-filter: blur(3px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const EndCtaTitle = styled(Typography)`
  font-size: 1.25rem;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 2rem;
  }
  font-weight: 300;
  padding-bottom: 2.5rem;
`;

export const StyledClosedBtn = styled(AiOutlineClose)`
  position: absolute;
  top: 10%;
  right: 10%;
  color: #757575;
  font-size: 1.5rem;
  cursor: pointer;
`;

interface NearlyCompleteCtaProps {
  $show: boolean;
  $isFullscreen: boolean;
}

export const NearlyCompleteCta = styled.button<NearlyCompleteCtaProps>`
  font-family: ${(p) => p.theme.typography.bodyFontFamily}, sans-serif;
  font-size: 0.825rem !important;
  color: ${colors.richBlack} !important;
  background: ${colors.white} !important;
  border-radius: 1rem;
  position: absolute;
  right: ${({ $isFullscreen }) => ($isFullscreen ? '2.25rem' : '1.25rem')};
  bottom: ${({ $isFullscreen }) => ($isFullscreen ? '3.75rem' : '2.75rem')};
  display: flex;
  align-content: center;
  padding: 0.5rem 1rem;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: ${({ $show }) => ($show ? 'none' : 'translateX(20rem)')};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: ${colors.clouds};
  }
`;

export const StyledChaptersContainer = styled(Card)`
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1.5rem;
  border: 1px solid ${colors.clouds};
  border-radius: 20px;
  padding: 1.25rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 35%;
    flex-grow: 1;
    margin-top: 0;
    padding: 2rem;
  }
`;

export const ChaptersWrapper = styled.div`
  position: relative;
  width: calc(100% + 3rem);
  padding: 0 1.5rem;
  margin-left: -1.5rem;
  overflow-y: scroll;
  ${CustomScrollBar}

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: calc(100% + 4rem);
    padding: 0 2rem;
    margin-left: -2rem;
  }
`;

export const ChaptersInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  padding-right: 1rem;
  z-index: 1;
`;

export const ChapterItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
  cursor: pointer;

  h6,
  p {
    color: ${transparentize(0.5, colors.richBlack)};
  }

  &.active {
    h6,
    p {
      color: ${colors.richBlack};
    }
  }
`;

export const IconContainer = styled.div`
  color: ${transparentize(0.85, colors.richBlack)};
  min-width: 1rem;

  &.viewing {
    color: ${colors.magenta};
  }
  &.viewed {
    color: ${colors.richBlack};
  }
`;

export const ActiveIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: ${colors['magenta-50']};
  z-index: 0;
  transition: top 0.25s ease-in-out, height 0.25s ease-in-out;
`;
