import { Breakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import sortBy from 'lodash/sortBy';
import { getAssetMapping } from 'pages/FundDetails/Slices/AssetClassChartSlice';
import { groupBreakdown } from './groupBreakdown';

const assetClassSortOrder: string[] = [
  'Equities',
  'Bonds',
  'Property',
  'Alternative assets',
  'Multi-asset',
  'Money Market',
  'Cash',
];

export function orderAssetBreakdown(breakdown: Breakdown): Breakdown {
  const sortingSelector = ({ code, proportion }: Breakdown) => {
    if (code === 'Cash') {
      return Number.MAX_SAFE_INTEGER;
    } else if (assetClassSortOrder.includes(code || '')) {
      return assetClassSortOrder.indexOf(code || '');
    } else {
      return assetClassSortOrder.length + 1 - proportion; // To sort DESC;
    }
  };

  return { ...breakdown, lines: sortBy(breakdown.lines, sortingSelector) };
}

export const getBreakdownAssetMappingKey = (line: Breakdown) => {
  return getAssetMapping(line?.code || 'CASH');
};

export const groupAssetBreakdown = groupBreakdown(
  getBreakdownAssetMappingKey,
  orderAssetBreakdown
);
