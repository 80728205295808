import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ResponsiveDialog } from 'components/Dialogs/ResponsiveDialog';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledGlossaryDialog = styled(ResponsiveDialog)`
  padding: 5rem 3.5rem 3.5rem 3.5rem;
`;

interface GlossaryAccordionsProps {
  $isDialog: boolean;
}

export const GlossaryAccordions = styled.div<GlossaryAccordionsProps>`
  width: ${({ $isDialog }) =>
    $isDialog ? 'calc(100% + 7rem)' : 'calc(100% + 2rem)'};
  transform: translateX(
    ${({ $isDialog }) => ($isDialog ? '-3.5rem' : '-1rem')}
  );
  margin-top: 2rem;
`;

export const GlossaryAccordion = styled(Accordion)`
  border-radius: 0 !important;
  box-shadow: none;
  border: none;
  border-top-color: white;
  border-bottom: 1px solid ${colors.midGrey};
  box-sizing: border-box;

  &:before {
    display: none;
  }

  &.Mui-expanded {
    margin: 0;
    border-bottom: 1px solid ${colors.midGrey};
  }

  .MuiAccordionSummary-root.Mui-expanded {
    background-color: ${colors['grey-50']};
  }
`;

export const GlossaryAccordionSummary = styled(AccordionSummary)`
  color: ${colors.richBlue};
  padding: 0rem 1.5rem;
`;

export const GlossaryAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0rem;
  padding: 1.785rem 1.5rem;
`;

interface GlossaryActionProps {
  $isActive: boolean;
}

export const GlossaryAction = styled.button<GlossaryActionProps>`
  margin-top: 2rem;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  border: none;
  background: transparent;
  color: ${({ $isActive }) => ($isActive ? colors.magenta : colors.richBlue)};
  cursor: pointer;
  transition: color 0.25s ease-in-out, background 0.25s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
    background-color: ${colors['grey-100']};
  }
`;
