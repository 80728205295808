import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from '../PersonalisePensionStep.styles';
import {
  retirementRangeOptions,
  RetirementRangeType,
  useRetirementRange,
} from './useRetirementRange';

export const retirementCopy = {
  over20:
    'With more than 20 years left until retirement, you have the benefit of time and compounding on your side.',
  years15_20:
    'With 15-20 years left until retirement, you should focus on growth assets to make your money work and grow over the next few decades.',
  years10_15:
    'With 10-15 years left until retirement, you still have time on your side to grow your money.',
  years5_10:
    'With 5-10 years left until retirement, you may want to start protecting the money you have saved in your pension by considering a higher proportion of protection assets.',
  under5:
    'With less than 5 years left until retirement, this is the time to prioritise protecting the money you have built up; your nest egg.',
};

export function Step4PersonalCircumstancesKeyTakeaways() {
  const { retirementRange } = useRetirementRange();

  const contentLookup: Record<RetirementRangeType, () => JSX.Element> = {
    [retirementRangeOptions.over20]: Over20View,
    [retirementRangeOptions.years15_20]: Years1520View,
    [retirementRangeOptions.years10_15]: Years1015View,
    [retirementRangeOptions.years5_10]: Years510View,
    [retirementRangeOptions.under5]: Under5View,
  };

  const StepContent = contentLookup[retirementRange];

  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>
        Personal Circumstances: Key takeaways
      </H1Large>
      <OverviewCopyContainer>
        <OverViewList>
          <AnimatePresence>
            <StepContent />
          </AnimatePresence>
        </OverViewList>
      </OverviewCopyContainer>{' '}
    </Content>
  );
}

const Over20View = () => (
  <>
    <KeyPointLi delay={0.25}>
      <TextNormal $noMargin>{retirementCopy.over20}</TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.35}>
      <TextNormal $noMargin>
        You should focus on growth assets at this stage to make your money work
        and grow over the next few decades.
      </TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.45}>
      <TextNormal $noMargin>
        You have to take risk to be in with a chance of growing your money.
      </TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.55}>
      <TextNormal $noMargin>
        By investing in growth assets you can also, to an extent, compensate for
        periods when you may not be able to contribute as much to your pension.
      </TextNormal>
      <ul>
        <KeyPointLi delay={0.65}>
          <TextNormal $noMargin>
            For example, if you are saving for a home, taking parental leave,
            etc.
          </TextNormal>
        </KeyPointLi>
      </ul>
    </KeyPointLi>
  </>
);

const Years1520View = () => (
  <>
    <KeyPointLi delay={0.25}>
      <TextNormal $noMargin>{retirementCopy.years15_20}</TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.35}>
      <TextNormal $noMargin>
        As you move closer to having 15 years left to retirement, you may want
        to diversify amongst growth-focused assets, beyond just equities.
      </TextNormal>
      <ul>
        <KeyPointLi delay={0.45}>
          <TextNormal $noMargin>
            Consider property, infrastructure, etc in combination with equities
            and private equity.
          </TextNormal>
        </KeyPointLi>
      </ul>
    </KeyPointLi>
    <KeyPointLi delay={0.55}>
      <TextNormal $noMargin>
        You can start to consider protection assets, but remember that your
        personal circumstances may require you to focus on growth assets for
        longer, even as you get closer to retirement.
      </TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.65}>
      <TextNormal $noMargin>
        It's about finding the right balance between how long you have left to
        save and how close you are to reaching your target for what you need to
        achieve the lifestyle you want in retirement.
      </TextNormal>
    </KeyPointLi>
  </>
);

const Years1015View = () => (
  <>
    <KeyPointLi delay={0.25}>
      <TextNormal $noMargin>{retirementCopy.years10_15}</TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.35}>
      <TextNormal $noMargin>
        As you move closer to having 10 years left to retirement, you may want
        to diversify amongst growth-focused assets, beyond just equities.
      </TextNormal>
      <ul>
        <KeyPointLi delay={0.45}>
          <TextNormal $noMargin>
            Consider property, infrastructure, etc in combination with equities
            and private equity.
          </TextNormal>
        </KeyPointLi>
      </ul>
    </KeyPointLi>
    <KeyPointLi delay={0.55}>
      <TextNormal $noMargin>
        You can also consider adding some protection-focused assets. However, if
        you have taken breaks from saving into your pension in the past, you may
        need to make up for that shortfall by considering more growth-focused
        assets for longer.
      </TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.65}>
      <TextNormal $noMargin>
        It's about finding the right balance between how long you have left to
        save and how close you are to reaching your target for what you need to
        achieve the lifestyle you want in retirement.
      </TextNormal>
    </KeyPointLi>
  </>
);

const Years510View = () => (
  <>
    <KeyPointLi delay={0.25}>
      <TextNormal $noMargin>{retirementCopy.years5_10}</TextNormal>
    </KeyPointLi>
    <KeyPointLi delay={0.35}>
      <TextNormal $noMargin>
        However, don't forget about growth-focused assets entirely because they
        are still needed to help grow some of your money, protect you against
        inflation as you approach retirement and compensate for any saving
        shortfalls in the past.
      </TextNormal>
    </KeyPointLi>
  </>
);

const Under5View = () => (
  <>
    <KeyPointLi delay={0.25}>
      <TextNormal $noMargin>{retirementCopy.under5}</TextNormal>
      <ul>
        <KeyPointLi delay={0.35}>
          <TextNormal $noMargin>
            Consider a blend of protection-focused assets, including bonds and
            commodities.
          </TextNormal>
        </KeyPointLi>
      </ul>
    </KeyPointLi>
    <KeyPointLi delay={0.45}>
      <TextNormal $noMargin>
        However, don't forget about growth-focused assets entirely because they
        are still needed to help grow some of your money and protect you against
        inflation as you approach and enter retirement.
      </TextNormal>
    </KeyPointLi>
  </>
);
