import { HeaderShim } from 'components/design-system/Header';
import { StyledA } from 'components/design-system/Link';
import {
  GoBackButton,
  LinkStepButton,
  StepActions,
  StepButton,
  StepContainer,
} from 'components/design-system/StepComponents/StepComponents';
import { TextAlign } from 'components/design-system/Text/Text';
import { VideoNav } from 'components/VideoPlayer/VideoNav/VideoNav';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useGetPersonalisePensionSourcePath } from 'hooks/useGetSourcePath';
import { fundListPath, personalisePensionFeedbackPath } from 'paths';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { BackToSource } from './components/BackToSource';
import { Step1Intro } from './components/steps/Intro';
import { Outro } from './components/steps/Outro';
import { Step1KeyBuildingBlocksKeyTakeaways } from './components/steps/Step1KeyBuildingBlocksKeyTakeaways';
import { Step1KeyBuildingBlocks } from './components/steps/Step1KeyBuildingBlocksVideos';
import { Step2AssetClasses } from './components/steps/Step2AssetClasses';
import { Step2AssetClassesKeyTakeaways } from './components/steps/Step2AssetClassesKeyTakeaways';
import { Step3Diversification } from './components/steps/Step3Diversification';
import { Step3DiversificationKeyTakeaways } from './components/steps/Step3DiversificationKeyTakeaways';
import { Step4PersonalCircumstances } from './components/steps/Step4PersonalCircumstances/Step4PersonalCircumstances';
import { Step4PersonalCircumstancesKeyTakeaways } from './components/steps/Step4PersonalCircumstances/Step4PersonalCircumstancesKeyTakeaways';
import { Step5BeliefsAndValues } from './components/steps/Step5BeliefsAndValues';
import { Step5BeliefsAndValuesKeyTakeaways } from './components/steps/Step5BeliefsAndValuesKeyTakeaways';
import { FeedbackText, StepWrapper } from './PersonalisePension.styles';

enum Steps {
  Intro = 'Intro',
  KeyBuildingBlocks = 'Key-Building-Blocks',
  KeyBuildingBlocksKeyTakeaways = 'Key-Building-Blocks-Key-Takeaways',
  AssetClasses = 'Asset-Classes',
  AssetClassesKeyTakeaways = 'Asset-Classes-Key-Takeaways',
  Diversification = 'Diversification',
  DiversificationKeyTakeaways = 'Diversification-Key-Takeaways',
  PersonalCircumstances = 'Personal-Circumstances',
  PersonalCircumstancesKeyTakeaways = 'Personal-Circumstances-Key-Takeaways',
  BeliefsAndValues = 'Beliefs-and-Values',
  BeliefsAndValuesKeyTakeaways = 'Beliefs-and-Values-Key-Takeaways',
  Outro = 'Outro',
}

const steps = [
  Steps.Intro,
  Steps.KeyBuildingBlocks,
  Steps.KeyBuildingBlocksKeyTakeaways,
  Steps.AssetClasses,
  Steps.AssetClassesKeyTakeaways,
  Steps.Diversification,
  Steps.DiversificationKeyTakeaways,
  Steps.PersonalCircumstances,
  Steps.PersonalCircumstancesKeyTakeaways,
  Steps.BeliefsAndValues,
  Steps.BeliefsAndValuesKeyTakeaways,
  Steps.Outro,
];

export const PersonalisePension = () => {
  const { source } = useGetPersonalisePensionSourcePath();
  const [activeStep, setActiveStep] = useState<Steps>(Steps.Intro);
  const [viewedSteps, setViewedSteps] = useLocalStorage<Steps[]>(
    'pension-viewed-steps',
    []
  );

  const currentIndex = steps.indexOf(activeStep);

  const backStep = () => {
    if (currentIndex === 0) {
      return;
    }
    setActiveStep(steps[currentIndex - 1]);
  };

  const handleViewed = () => {
    if (!viewedSteps.includes(activeStep)) {
      setViewedSteps([...viewedSteps, activeStep]);
    }

    if (activeStep === Steps.KeyBuildingBlocks) {
      trackGa({
        event: GaEventNames.multiStepFormFinish,
        [GaProperties.form]: 'Personalise Pension',
      });
    }
  };

  const nextStep = () => {
    if (currentIndex === steps.length - 1) {
      return;
    }

    if (activeStep === Steps.Intro) {
      trackGa({
        event: GaEventNames.multiStepFormStart,
        [GaProperties.form]: 'Personalise Pension',
      });
    } else {
      trackGa({
        event: GaEventNames.multiStepFormStep,
        [GaProperties.form]: 'Personalise Pension',
        [GaProperties.formStep]: activeStep,
      });
    }

    handleViewed();
    setActiveStep(steps[currentIndex + 1]);
  };

  const handleExitFlow = () => {
    trackGa({
      event: GaEventNames.multiStepFormExit,
      [GaProperties.form]: 'Personalise Pension',
    });
  };

  const handleFinishFlow = () => {
    trackGa({
      event: GaEventNames.multiStepFormFinish,
      [GaProperties.form]: 'Personalise Pension',
    });
  };

  return (
    <StepContainer>
      <HeaderShim />
      <StepWrapper $isIntro={activeStep === Steps.Intro}>
        <BackToSource onClick={handleExitFlow} />

        {activeStep === Steps.Intro && <Step1Intro onProceed={nextStep} />}
        {activeStep === Steps.KeyBuildingBlocks && (
          <Step1KeyBuildingBlocks onProceed={nextStep} />
        )}
        {activeStep === Steps.KeyBuildingBlocksKeyTakeaways && (
          <Step1KeyBuildingBlocksKeyTakeaways />
        )}
        {activeStep === Steps.AssetClasses && (
          <Step2AssetClasses onProceed={nextStep} />
        )}
        {activeStep === Steps.AssetClassesKeyTakeaways && (
          <Step2AssetClassesKeyTakeaways />
        )}
        {activeStep === Steps.Diversification && (
          <Step3Diversification onProceed={nextStep} />
        )}
        {activeStep === Steps.DiversificationKeyTakeaways && (
          <Step3DiversificationKeyTakeaways />
        )}
        {activeStep === Steps.PersonalCircumstances && (
          <Step4PersonalCircumstances onProceed={nextStep} />
        )}
        {activeStep === Steps.PersonalCircumstancesKeyTakeaways && (
          <Step4PersonalCircumstancesKeyTakeaways />
        )}
        {activeStep === Steps.BeliefsAndValues && (
          <Step5BeliefsAndValues onProceed={nextStep} />
        )}
        {activeStep === Steps.BeliefsAndValuesKeyTakeaways && (
          <Step5BeliefsAndValuesKeyTakeaways />
        )}
        {activeStep === Steps.Outro && <Outro />}

        {activeStep !== Steps.Intro && activeStep !== Steps.Outro && (
          <VideoNav
            navButtons={[
              {
                label: 'Intro',
                name: Steps.KeyBuildingBlocks,
                alsoActiveFor: [Steps.KeyBuildingBlocksKeyTakeaways],
                viewed: viewedSteps.includes(Steps.KeyBuildingBlocks),
              },
              {
                label: 'Asset Classes',
                name: Steps.AssetClasses,
                alsoActiveFor: [Steps.AssetClassesKeyTakeaways],
                viewed: viewedSteps.includes(Steps.AssetClasses),
              },
              {
                label: 'Diversification',
                name: Steps.Diversification,
                alsoActiveFor: [Steps.DiversificationKeyTakeaways],
                viewed: viewedSteps.includes(Steps.Diversification),
              },
              {
                label: 'Personal Circumstances',
                name: Steps.PersonalCircumstances,
                alsoActiveFor: [Steps.PersonalCircumstancesKeyTakeaways],
                viewed: viewedSteps.includes(Steps.PersonalCircumstances),
              },
              {
                label: 'Beliefs and Values',
                name: Steps.BeliefsAndValues,
                alsoActiveFor: [Steps.BeliefsAndValuesKeyTakeaways],
                viewed: viewedSteps.includes(Steps.BeliefsAndValues),
              },
            ]}
            activeButton={activeStep}
            onClick={(step) => {
              if (viewedSteps.includes(step)) {
                setActiveStep(step);
              }
            }}
          />
        )}

        <StepActions>
          {activeStep === Steps.Outro ? (
            <LinkStepButton
              to={source ? source.toPath : fundListPath}
              onClick={handleFinishFlow}
              className="magenta"
            >
              Pick funds
            </LinkStepButton>
          ) : (
            <StepButton
              onClick={nextStep}
              className="magenta"
              // disabled={currentIndex === steps.length - 1}
            >
              {activeStep === Steps.Intro ? 'Start' : 'Next'}
            </StepButton>
          )}
          {currentIndex > 0 && (
            <GoBackButton onClick={backStep} disabled={currentIndex === 0} />
          )}
        </StepActions>

        {activeStep === Steps.Outro && (
          <FeedbackText $noMargin $textAlign={TextAlign.center}>
            We are always working on ways to improve how we can support you in
            making better investment decisions, so if you have any suggestions
            please let us know{' '}
            <StyledA href={personalisePensionFeedbackPath} target="_blank">
              here
            </StyledA>
            .
          </FeedbackText>
        )}
      </StepWrapper>
    </StepContainer>
  );
};
