import videojs, {
  VideoJsPlayer,
  VideoJsPlayerOptions,
} from '@tillitinvest/video.js';
import '@tillitinvest/video.js/dist/video-js.css';
import { tillitFetch } from 'api/tillitFetch';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { Chapter } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import throttle from 'lodash/throttle';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { setTimeout } from 'timers';
import { ChaptersContainer } from './Chapters';
import { EndCta } from './EndCta';
import {
  NearlyCompleteCta,
  StyledVideo,
  VideoPlayerContainer,
} from './VideoPlayer.styles';

interface VideoPlayerProps {
  videoJsOptions: VideoJsPlayerOptions;
  BeforeStartCTA?: React.ReactNode | JSX.Element | string;
  shouldShowEndCta?: boolean;
  video: string;
  videoType: string;
  requiresAuthentication?: boolean;
  nearlyCompleteCtaLink?: string;
  nearlyCompleteCtaText?: string;
  chapters?: Chapter[];
  roundedBorders?: boolean;
  playButtonTheme?: 'light' | 'dark';
  setPlayerRef?: (player: VideoJsPlayer) => void;
  onVideoEnd?: () => void;
}

interface TimeWatchedProgressPercentage {
  [10]: boolean;
  [25]: boolean;
  [50]: boolean;
  [75]: boolean;
  [95]: boolean;
}

export function VideoPlayer({
  videoJsOptions,
  BeforeStartCTA,
  shouldShowEndCta = false,
  video,
  videoType,
  requiresAuthentication = false,
  nearlyCompleteCtaLink,
  nearlyCompleteCtaText,
  chapters,
  roundedBorders = true,
  playButtonTheme = 'dark',
  setPlayerRef,
  onVideoEnd,
}: VideoPlayerProps) {
  const { signedIn } = useAuth();
  const videoRef = useRef(null);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [showStartCta, setShowStartCta] = useState(false);
  const [showEndCta, setShowEndCta] = useState(false);
  const [showNearlyCompleteCta, setShowNearlyCompleteCta] = useState(false);

  const history = useHistory();

  const [
    timeWatchedProgress,
    setTimeWatchedProgress,
  ] = useState<TimeWatchedProgressPercentage>({
    10: false,
    25: false,
    50: false,
    75: false,
    95: false,
  });

  const deriveAnalyticsProperties = useCallback(() => {
    const player = playerRef.current;
    return {
      [GaProperties.videoTitle]: video,
      [GaProperties.videoType]: videoType,
      [GaProperties.signedIn]: signedIn,
      [GaProperties.videoUrl]: window.location.href,
      [GaProperties.videoDuration]:
        player !== null ? player.duration() : undefined,
      [GaProperties.videoCurrentTime]:
        player !== null ? player.currentTime() : undefined,
    };
  }, [signedIn, video, videoType]);

  const trackBeforeStartCta = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'video',
      item_id: `unauth play attempt`,
    });
  };

  const updateTimeWatchedPercentage = useCallback(
    (
      percentageComplete: number,
      percentageCompleteToProcess: 10 | 25 | 50 | 75 | 95
    ) => {
      if (
        timeWatchedProgress[percentageCompleteToProcess] === false &&
        percentageComplete > percentageCompleteToProcess
      ) {
        setTimeWatchedProgress((prevTimeWatchedProgress) => ({
          ...prevTimeWatchedProgress,
          [percentageCompleteToProcess]: true,
        }));

        trackGa({
          event: GaEventNames.video,
          content_type: 'video - progress',
          video_progress: percentageCompleteToProcess,
          ...deriveAnalyticsProperties(),
        });
      }
    },
    [timeWatchedProgress, setTimeWatchedProgress, deriveAnalyticsProperties]
  );

  const getMimeType = (url: string, existingType: string | undefined) => {
    if (url.endsWith('m3u8')) {
      return 'application/x-mpegurl';
    } else if (url.endsWith('mp4')) {
      return 'video/mp4';
    } else {
      return existingType;
    }
  };

  // useMemo as without,  React build complains that it
  // "makes the dependencies of the useEffect hook change on every render"
  const optionsWithMimeTypes = useMemo(() => {
    const toReturn = { ...videoJsOptions };
    toReturn.sources = toReturn.sources?.map((source) => ({
      ...source,
      type: getMimeType(source.src, source.type),
    }));

    return toReturn;
  }, [videoJsOptions]);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement && !playerRef.current) {
      const player = (playerRef.current = videojs(
        videoElement,
        optionsWithMimeTypes
      ));
      if (setPlayerRef) {
        setPlayerRef(player);
      }

      if (requiresAuthentication) {
        // refresh CloudFront cookies and retry
        (player as any).reloadSourceOnError({
          errorInterval: 5,
          async getSource(next: any) {
            const tech = this.tech({ IWillNotUseThisInPlugins: true });
            const sourceObj = tech.currentSource_ || this.currentSource();

            const authStatusResponse = await tillitFetch('/auth/status');
            if (authStatusResponse.ok) {
              const { authenticated } = await authStatusResponse.json();

              if (authenticated) {
                return next(sourceObj);
              }
            }

            return null;
          },
        });
      }

      player.on('play', () => {
        const percentageComplete = Math.floor(
          (player.currentTime() / player.duration()) * 100
        );
        trackGa({
          event: GaEventNames.video,
          content_type: 'video - play',
          video_progress: percentageComplete,
          time: Math.round(player.currentTime()),
          ...deriveAnalyticsProperties(),
        });
      });

      player.on('pause', () => {
        const percentageComplete = Math.floor(
          (player.currentTime() / player.duration()) * 100
        );
        trackGa({
          event: GaEventNames.video,
          content_type: 'video - pause',
          video_progress: percentageComplete,
          time: Math.round(player.currentTime()),
          ...deriveAnalyticsProperties(),
        });
      });

      player.on('ended', () => {
        trackGa({
          event: GaEventNames.video,
          content_type: 'video - ended',
          video_progress: 100,
          time: Math.round(player.currentTime()),
          ...deriveAnalyticsProperties(),
        });

        // reset player on video ended
        if (player.isFullscreen()) player.exitFullscreen();

        // display the CTA overlay if option enabled
        if (shouldShowEndCta) {
          setShowEndCta(true);
        } else {
          // reset play button if not showing end cta
          player.hasStarted(false);
        }

        if (onVideoEnd) {
          onVideoEnd();
        }
      });
    }

    // This needs to re-run so that the timeWatchedProgress value is updated as it changes.
    if (playerRef.current) {
      const player = playerRef.current;
      player.off('timeupdate');
      player.on(
        'timeupdate',
        throttle(() => {
          const player = playerRef.current;

          if (player === null) {
            return;
          }
          // update the current time
          setCurrentTime(player?.currentTime());

          const percentageComplete = Math.floor(
            (player.currentTime() / player.duration()) * 100
          );

          setShowNearlyCompleteCta(percentageComplete > 75);

          updateTimeWatchedPercentage(percentageComplete, 10);
          updateTimeWatchedPercentage(percentageComplete, 25);
          updateTimeWatchedPercentage(percentageComplete, 50);
          updateTimeWatchedPercentage(percentageComplete, 75);
          updateTimeWatchedPercentage(percentageComplete, 95);
        }, 1000)
      );
    }
  }, [
    shouldShowEndCta,
    video,
    videoType,
    signedIn,
    setTimeWatchedProgress,
    timeWatchedProgress,
    deriveAnalyticsProperties,
    updateTimeWatchedPercentage,
    requiresAuthentication,
    optionsWithMimeTypes,
    setPlayerRef,
    onVideoEnd,
  ]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    const events = ['touchstart', 'click'];

    if (player === null) {
      return;
    }

    if (BeforeStartCTA) {
      const handleBeforeStart = () => {
        player.addClass('show-before-start-cta');
        setShowStartCta(true);
        trackBeforeStartCta();
      };

      events.forEach((event) => {
        player.on(event, handleBeforeStart);
      });
    }

    if (showEndCta) {
      player.addClass('show-disclaimer');
    }
  });

  useEffect(() => {
    if (videoRef.current) {
      const videoElm = videoRef.current as HTMLElement;
      setVideoHeight(videoElm.clientHeight || 0);

      window.onresize = () => {
        setVideoHeight(videoElm.clientHeight || 0);
      };
    }
  }, [videoRef]);

  const handleCloseEndCta = () => {
    setShowEndCta(false);
    const player = playerRef.current;
    if (player === null) {
      return;
    }
    player.removeClass('show-disclaimer');
    player.hasStarted(false);
  };

  const handleCtaOnClick = () => {
    setShowEndCta(false);
    const player = playerRef.current;
    if (player === null) {
      return;
    }
    player.removeClass('show-disclaimer');
    player.hasStarted(false);
    const target = document.getElementById('meet-the-manager');

    if (target === null) {
      return;
    }
    setTimeout(() => {
      window.scrollTo({
        top: window.scrollY + target.getBoundingClientRect().top - 80,
        behavior: 'smooth',
      });
    }, 500);
  };

  const handleNearlyCompletedCtaOnClick = () => {
    const player = playerRef.current;
    if (player === null) {
      return;
    }
    if (player && !player.isDisposed()) {
      player.pause();
    }
    if (player.isFullscreen()) player.exitFullscreen();

    if (nearlyCompleteCtaLink) {
      history.push(nearlyCompleteCtaLink);

      const hashLinkMatch =
        typeof nearlyCompleteCtaLink === 'string' &&
        nearlyCompleteCtaLink.match(/#([a-z-]+)/);

      if (hashLinkMatch && hashLinkMatch[0]) {
        setTimeout(() => {
          const hashLink = hashLinkMatch[0];

          const target = document.querySelector(hashLink);
          if (target === null) {
            return;
          }
          window.scrollTo({
            top: window.scrollY + target.getBoundingClientRect().top - 110,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'video',
      item_id: `nearly complete cta - ${nearlyCompleteCtaText}`,
    });
  };

  const handleChapterClick = (jumpTo: number, title: string) => {
    const player = playerRef.current;
    if (player === null) {
      return;
    }
    player.currentTime(jumpTo);
    player.play();

    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'video chapter',
      item_id: `${title}`,
    });

    if (BeforeStartCTA && !signedIn) {
      // show the before start cta
      setShowStartCta(true);
      trackBeforeStartCta();
    }
  };

  return (
    <>
      {showStartCta && !signedIn && <>{BeforeStartCTA}</>}
      <VideoPlayerContainer $hasChapters={chapters && chapters.length > 0}>
        <div data-vjs-player>
          {showEndCta && (
            <EndCta onClose={handleCloseEndCta} onClick={handleCtaOnClick} />
          )}
          {nearlyCompleteCtaLink && !showEndCta && shouldShowEndCta && (
            <NearlyCompleteCta
              $show={showNearlyCompleteCta}
              $isFullscreen={playerRef.current?.isFullscreen() ?? false}
              onClick={handleNearlyCompletedCtaOnClick}
            >
              {nearlyCompleteCtaText}
            </NearlyCompleteCta>
          )}
          <StyledVideo
            ref={videoRef}
            className="video-js vjs-big-play-centered"
            playsInline={true}
            $hasRoundedBorders={roundedBorders}
            $playButtonTheme={playButtonTheme}
            crossOrigin={
              requiresAuthentication ? 'use-credentials' : 'anonymous'
            }
          />
        </div>
        {chapters && chapters.length > 0 && (
          <ChaptersContainer
            currentTime={currentTime}
            chapters={chapters}
            videoHeight={videoHeight}
            onClick={handleChapterClick}
          />
        )}
      </VideoPlayerContainer>
    </>
  );
}
