import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import {
  getMapColourForProportion,
  mapColourForNegativeProportion,
} from '../_shared/colourHelper';

interface RegionMapKeysProps {
  $includeNegative: boolean;
}

const RegionMapKeys = styled.div<RegionMapKeysProps>`
  position: absolute;
  z-index: 1;
  width: 35%;
  bottom: -0.5rem;
  left: 0.25rem;
  gap: 0.25rem;
  display: grid;
  grid-template-columns: ${({ $includeNegative }) =>
    $includeNegative ? '1.5rem 1.5rem 1fr' : ' 1.5rem 1fr'};

  ${(p) => p.theme.breakpoints.up('sm')} {
    bottom: 0.5rem;
    width: 20%;
    left: 1.5rem;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 15%;
    left: 12%;
  }
`;

const RegionMapNegative = styled.div`
  width: 100%;
  height: 10px;
  background: ${mapColourForNegativeProportion};
`;

const RegionMapZero = styled.div`
  width: 100%;
  height: 10px;
  background: ${colors['seaBlue-50']};
`;

const RegionMapWrapper = styled.div`
  grid-column: auto;
`;

const RegionMapKey = styled.div`
  width: 100%;
  height: 10px;
  background: linear-gradient(
    90deg,
    ${getMapColourForProportion(0)} 30%,
    ${getMapColourForProportion(1)} 100%
  );
`;

interface RegionMapLabelsProps {
  $multipleValues?: boolean;
}

const RegionMapLabels = styled.div<RegionMapLabelsProps>`
  display: flex;
  justify-content: ${({ $multipleValues }) =>
    $multipleValues ? 'space-between' : 'center'};
  color: ${transparentize(0.6, colors.richBlack)};
  font-size: 0.75rem;
  padding: 0.125rem;
`;

interface RegionBreakdownKeyProps {
  includeNegative: boolean;
}

export const RegionBreakdownKey = ({
  includeNegative,
}: RegionBreakdownKeyProps) => {
  return (
    <RegionMapKeys $includeNegative={includeNegative}>
      {includeNegative && (
        <div>
          <RegionMapNegative />
          <RegionMapLabels>
            <span>&lt;0%</span>
          </RegionMapLabels>
        </div>
      )}
      <div>
        <RegionMapZero />
        <RegionMapLabels>
          <span>0%</span>
        </RegionMapLabels>
      </div>
      <RegionMapWrapper>
        <RegionMapKey />
        <RegionMapLabels $multipleValues>
          <span>1%</span>
          <span>100%</span>
        </RegionMapLabels>
      </RegionMapWrapper>
    </RegionMapKeys>
  );
};
