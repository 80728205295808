import { Text } from 'components/design-system/Text/Text';
import styled from 'styled-components';

interface StepWrapperProps {
  $isIntro: boolean;
}

export const StepWrapper = styled.div<StepWrapperProps>`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  min-height: ${({ theme, $isIntro }) =>
    `calc(100dvh - ${$isIntro ? `(${theme.header.height} + 20px)` : 'auto'})`};
  gap: 1rem;
  padding: 0.5rem 0 2rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: 1fr auto auto;
    min-height: ${({ theme }) => `calc(100vh - ${theme.header.height})`};
    padding: 2.5rem 0 2rem 0;
  }
`;

export const FeedbackText = styled(Text)`
  max-width: 30rem;
`;
