import { Text, TextAlign } from 'components/design-system/Text/Text';
import { VideoNavButton, VideoNavContainer } from './VideoNav.styles';

interface Item<NavItemEnum> {
  label: string;
  name: NavItemEnum;
  alsoActiveFor?: NavItemEnum[];
  viewed: boolean;
}

interface VideoNavProps<NavItemEnum> {
  navButtons: Item<NavItemEnum>[];
  activeButton: NavItemEnum;
  onClick: (name: NavItemEnum) => void;
}

export function VideoNav<NavItemEnum>({
  navButtons,
  activeButton,
  onClick,
}: VideoNavProps<NavItemEnum>) {
  const handleClick = (name: NavItemEnum) => {
    onClick(name);
  };

  return (
    <VideoNavContainer>
      {navButtons.map(
        ({ name, alsoActiveFor, label, viewed }: Item<NavItemEnum>) => {
          const active =
            activeButton === name || alsoActiveFor?.includes(activeButton);

          return (
            <VideoNavButton
              key={String(name)}
              onClick={() => handleClick(name)}
              disabled={!viewed && !active}
              $active={active}
              $viewed={viewed}
            >
              <Text $textAlign={TextAlign.left} $noMargin>
                {label}
              </Text>
            </VideoNavButton>
          );
        }
      )}
    </VideoNavContainer>
  );
}
