import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';
import { BottomShadow, SmallShadow } from 'theme/shared/base.styles';
import { FaCheck } from 'react-icons/fa6';

const bubbleSizeLookup = {
  small: '114px',
  medium: '140px',
  large: '165px',
};

interface StyledBubleProps {
  $isActive: boolean;
  $size: 'small' | 'medium' | 'large';
}

export const StyledBuble = styled.button<StyledBubleProps>`
  position: absolute;
  width: ${({ $size }) => bubbleSizeLookup[$size]};
  height: ${({ $size }) => bubbleSizeLookup[$size]};
  border: 1px solid ${colors['mauve-50']};
  border-radius: 50%;
  background: none;
  background-color: ${colors.white};
  color: ${colors['magenta-500']};
  font-size: 1.25rem;
  font-weight: 300;
  padding: 1.5rem 1.25rem;
  font-family: ${({ theme }) => theme.typography.headingFontFamily};
  ${SmallShadow}
  cursor: pointer;

  transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out,
    color 0.25s ease-in-out;

  &:hover {
    background-color: ${colors['magenta-25']};
    color: ${colors['magenta-500']};
    ${BottomShadow}
  }

  &:focus {
    outline: none;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${colors.white};
      background-color: ${colors.magenta};
      border: 1px solid ${colors['magenta-500']};
    `}
`;

export const StyledFaCheck = styled(FaCheck)`
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-0.625rem);
`;
