import { CombinedQueryState } from 'components/QueryState';
import { TextSmall } from 'components/design-system/Text/Text';
import {
  Toggle,
  ToggleColorOptions,
} from 'components/design-system/Toggle/Toggle';
import { assetClassBreakdownColors } from 'components/feature/FundDetails/Breakdown/_shared/colourHelper';
import { Breakdown } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { useAssetBreakdown } from 'components/feature/Xray/helpers/useAssetBreakdown';
import { useCombinedAssetBreakdown } from 'components/feature/Xray/helpers/useCombinedAssetBreakdown';
import { useCombinedRegionBreakdown } from 'components/feature/Xray/helpers/useCombinedRegionBreakdown';
import { useRegionBreakdown } from 'components/feature/Xray/helpers/useRegionBreakdown';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { keyBy, map, mapValues } from 'lodash';
import { useState } from 'react';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components';
import { DrillDownTable } from './_shared/DrillDownTable';
import { StackedBarChart } from './_shared/StackedBarChart';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const KeysContainer = styled.div`
  display: flex;
  gap: 0.75rem 2rem;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const KeyContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
`;

const KeySquareEl = styled.span`
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 0.25rem;
`;

interface KeySquareProps {
  name: string;
  type: 'asset' | 'region';
  colorLookUp?: { [key: string]: string };
}

const KeySquare = ({ name, type, colorLookUp }: KeySquareProps) => {
  let background = '';
  if (name === 'Cash') {
    background = `linear-gradient(135deg, ${colors.richBlack} 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(225deg, #d8d8d8 25%, transparent 25%) -4px 0/ 8px 8px, linear-gradient(315deg, ${colors.richBlack} 25%, transparent 25%) 0px 0/ 8px 8px, linear-gradient(45deg, #d8d8d8 25%, #e8e8e8 25%) 0px 0/ 8px 8px`;
  } else if (type === 'asset') {
    background = assetClassBreakdownColors[name];
  } else if (type === 'region' && colorLookUp) {
    background = colorLookUp[name];
  }

  return <KeySquareEl style={{ background }} />;
};

const colorArray = [
  colors['magenta-600'],
  colors['magenta-400'],
  colors['magenta-200'],
  colors['magenta-700'],
  colors['magenta-500'],
  colors['magenta-300'],
  colors['magenta-100'],
  colors['magenta-800'],
  colors['purple-50'],
  colors['purple-600'],
  colors['purple-400'],
  colors['purple-200'],
  colors['purple-700'],
  colors['purple-500'],
  colors['purple-300'],
  colors['purple-100'],
  colors['purple-800'],
  colors['purple-50'],
];

interface InteractiveDrillDownV2DisplayProps {
  portfolioRebalancingId?: string;
  queries: UseQueryResult[];
  combined: Breakdown;
  breakdown: Breakdown;
  type: 'asset' | 'region';
  colorLookUp?: { [key: string]: string };
}

export const InteractiveDrillDownV2Display = ({
  portfolioRebalancingId,
  queries,
  combined,
  breakdown,
  type,
  colorLookUp,
}: InteractiveDrillDownV2DisplayProps) => {
  const [activeCurrentBreakdown, setCurrentActiveBreakdown] = useState<
    Breakdown | undefined
  >();

  const [activeEstimatedBreakdown, setEstimatedActiveBreakdown] = useState<
    Breakdown | undefined
  >();

  return (
    <Container>
      <CombinedQueryState queries={queries}>
        <div>
          <TextSmall $noMargin>Current allocation</TextSmall>
          <StackedBarChart
            breakdown={breakdown}
            type={type}
            colorLookUp={colorLookUp}
            onClick={(breakdown: Breakdown) => {
              if (breakdown.code === activeCurrentBreakdown?.code) {
                setCurrentActiveBreakdown(undefined);
              } else {
                setCurrentActiveBreakdown(breakdown);
              }
            }}
          />
          {activeCurrentBreakdown && (
            <DrillDownTable
              type="existing"
              breakdown={activeCurrentBreakdown}
            />
          )}
        </div>
        <div>
          <TextSmall $noMargin>Estimated new allocation</TextSmall>
          <StackedBarChart
            breakdown={portfolioRebalancingId ? combined : breakdown}
            disabled={!portfolioRebalancingId}
            type={type}
            colorLookUp={colorLookUp}
            onClick={(breakdown: Breakdown) => {
              if (breakdown.code === activeEstimatedBreakdown?.code) {
                setEstimatedActiveBreakdown(undefined);
              } else {
                setEstimatedActiveBreakdown(breakdown);
              }
            }}
          />
          {activeEstimatedBreakdown && (
            <DrillDownTable
              type="combined"
              breakdown={activeEstimatedBreakdown}
            />
          )}
        </div>
      </CombinedQueryState>
      <KeysContainer>
        {breakdown.lines?.map((breakdownLine) => {
          return (
            <KeyContainer>
              <KeySquare
                type={type}
                name={breakdownLine.name}
                colorLookUp={colorLookUp}
              />
              <TextSmall $noMargin>{breakdownLine.name}</TextSmall>
            </KeyContainer>
          );
        })}
      </KeysContainer>
    </Container>
  );
};

const InteractiveDrillDownAsset = ({
  selectedAccountId,
  portfolioRebalancingId,
}: InteractiveDrillDownProps) => {
  const { query, breakdown } = useAssetBreakdown({
    accountId: selectedAccountId,
  });

  const { rebalancingQuery, breakdown: combined } = useCombinedAssetBreakdown({
    accountId: selectedAccountId,
    rebalancingId: portfolioRebalancingId!,
  });

  return (
    <InteractiveDrillDownV2Display
      portfolioRebalancingId={portfolioRebalancingId}
      combined={combined}
      breakdown={breakdown}
      queries={[query, rebalancingQuery]}
      type="asset"
    />
  );
};

interface InteractiveDrillDownProps {
  selectedAccountId: string;
  portfolioRebalancingId?: string;
}

const InteractiveDrillDownRegion = ({
  selectedAccountId,
  portfolioRebalancingId,
}: InteractiveDrillDownProps) => {
  const { query, breakdown } = useRegionBreakdown({
    accountId: selectedAccountId,
  });

  const { rebalancingQuery, breakdown: combined } = useCombinedRegionBreakdown({
    accountId: selectedAccountId,
    rebalancingId: portfolioRebalancingId!,
  });

  const colorLookUp = mapValues(
    keyBy(
      map(combined.lines, (breakdown, index) => ({
        name: breakdown.name,
        color: colorArray[index],
      })),
      'name'
    ),
    ({ color }) => color
  );

  return (
    <InteractiveDrillDownV2Display
      portfolioRebalancingId={portfolioRebalancingId}
      combined={combined}
      breakdown={breakdown}
      queries={[query, rebalancingQuery]}
      type="region"
      colorLookUp={colorLookUp}
    />
  );
};

export const InteractiveDrillDownV2 = ({
  selectedAccountId,
  portfolioRebalancingId,
}: InteractiveDrillDownProps) => {
  const [activeBreakdown, setActiveBreakdown] = useState('assetClass');

  return (
    <div>
      <ToggleContainer>
        <Toggle
          onClick={(value) => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: 'interactive breakdown - change breakdown',
              item_id: value,
            });
            setActiveBreakdown(value);
          }}
          $color={ToggleColorOptions.secondary}
          options={[
            { value: 'assetClass', label: 'Asset class' },
            { value: 'region', label: 'Region' },
          ]}
          value={activeBreakdown}
        />
      </ToggleContainer>
      {activeBreakdown === 'region' && (
        <InteractiveDrillDownRegion
          selectedAccountId={selectedAccountId}
          portfolioRebalancingId={portfolioRebalancingId}
        />
      )}
      {activeBreakdown === 'assetClass' && (
        <InteractiveDrillDownAsset
          selectedAccountId={selectedAccountId}
          portfolioRebalancingId={portfolioRebalancingId}
        />
      )}
    </div>
  );
};
