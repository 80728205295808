import { BreakdownNoProportions } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { groupRegionalBreakdown } from 'components/feature/Xray/helpers/groupRegionalBreakdown';
import { useAccountXrayQuery } from 'generated/graphql';
import flatMap from 'lodash/flatMap';

interface RegionXraySliceProps {
  accountId: string;
}

export function useRegionBreakdownRaw({ accountId }: RegionXraySliceProps) {
  const accountXrayQuery = useAccountXrayQuery({
    id: accountId,
  });

  const accountPositions = accountXrayQuery.data?.account?.positions || [];

  const lines: BreakdownNoProportions[] = flatMap(
    accountPositions,
    (position): BreakdownNoProportions[] => {
      if (position.instrument === null) {
        return [
          {
            name: 'Cash',
            subName: 'Cash',
            amount: position.currentValue,
            existingAmount: position.currentValue,
            code: 'ACCA',
            type: 'Cash',
            state: 'existing',
          },
        ];
      }
      if (position.instrument?.regionalBreakdown?.nodes.length === 0) {
        return [
          {
            name: 'Unmapped instrument',
            subName: position.instrument?.name,
            amount: position.currentValue,
            existingAmount: position.currentValue,
            type: 'Other',
            state: 'existing',
          },
        ];
      }
      return (
        position.instrument?.regionalBreakdown?.nodes.map((region) => ({
          name: region.name,
          subName: position.instrument?.name,
          amount:
            Math.round(position.currentValue * region.proportion * 100) / 100,
          existingAmount:
            Math.round(position.currentValue * region.proportion * 100) / 100,

          code: region.code === null ? undefined : region.code,
          isoCountryCode:
            region.isoCountryCode === null ? undefined : region.isoCountryCode,
          type: region.type,
          state: 'existing',
        })) || []
      );
    }
  )
    .map((region) => {
      const newBreakdown: BreakdownNoProportions = {
        subName: region.subName,
        name: region.name,
        amount: region.amount,
        existingAmount: region.existingAmount,
        isoCountryCode: region.isoCountryCode || '',
        code: region.code || '',
        type: region.type || '',
        state: 'existing',
      };
      return newBreakdown;
    })
    .filter((region) => region !== null && region?.amount !== 0);

  return {
    query: accountXrayQuery,
    breakdownLines: lines,
  };
}

export function useRegionBreakdown({ accountId }: RegionXraySliceProps) {
  const { query, breakdownLines } = useRegionBreakdownRaw({ accountId });

  return {
    query,
    breakdown: groupRegionalBreakdown(breakdownLines),
  };
}
