import {
  StepFormWrapper,
  StepWrapper,
} from 'components/design-system/StepComponents/StepComponents';
import { BeliefsAndValues } from 'components/feature/BeliefsAndValues/BeliefsAndValues';
import { PersonalisePension } from 'components/feature/personalisePension/PersonalisePension';
import { useToggle } from 'hooks/useFeatureToggle';
import { useUpdateRootCSSVars } from 'hooks/useUpdateRootCSSVars';
import { beliefsAndValuesPath, personalisePensionPath } from 'paths';
import { Redirect, Route } from 'react-router-dom';

export function PersonalisePensionPage() {
  const [personalisePensionFilteringEnabled] = useToggle(
    'global-personalise-pension-filtering'
  );

  const rootStyling = [
    {
      property: '--main-logo-color',
      value: 'black',
    },
    {
      property: '--main-header-color',
      value: 'black',
    },
  ];

  useUpdateRootCSSVars(rootStyling);

  return (
    <StepFormWrapper>
      <StepWrapper>
        <Route exact path={personalisePensionPath}>
          <PersonalisePension />
        </Route>

        <Route exact path={beliefsAndValuesPath}>
          {personalisePensionFilteringEnabled?.enabled ? (
            <BeliefsAndValues onProceed={() => {}} onBack={() => {}} />
          ) : (
            <Redirect to={personalisePensionPath} />
          )}
        </Route>
      </StepWrapper>
    </StepFormWrapper>
  );
}
