import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from './PersonalisePensionStep.styles';

export const Outro = () => {
  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>
        Personalise my pension: Recap
      </H1Large>

      <OverviewCopyContainer>
        <TextNormal $noMargin $textAlign={TextAlign.center}>
          The key building blocks to consider when personalising my pension are:
        </TextNormal>
        <OverViewList>
          <AnimatePresence>
            <KeyPointLi delay={0.25}>
              <TextNormal $noMargin>Asset Classes</TextNormal>
              <ul>
                <KeyPointLi delay={0.25}>
                  <TextNormal $noMargin>
                    Is your priority to grow or protect your money?
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>Diversification</TextNormal>
              <ul>
                <KeyPointLi delay={0.25}>
                  <TextNormal $noMargin>
                    Balance is key, and it can be achieved in different ways.
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.45}>
              <TextNormal $noMargin>Personal Circumstances</TextNormal>
              <ul>
                <KeyPointLi delay={0.25}>
                  <TextNormal $noMargin>
                    Think about where you are in your saving journey and what
                    you need to fit your circumstances.
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.55}>
              <TextNormal $noMargin>Beliefs and Values</TextNormal>
              <ul>
                <KeyPointLi delay={0.25}>
                  <TextNormal $noMargin>
                    This is your chance to align your pension with what matters
                    to you!
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
          </AnimatePresence>
        </OverViewList>

        <TextNormal
          $textAlign={TextAlign.center}
          $fontWeight={FontWeight.medium}
        >
          Next up - take a look at our funds and start personalising your
          pension!
        </TextNormal>
      </OverviewCopyContainer>
    </Content>
  );
};
