import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';
import { SectionTitle } from './AccountSection';

type StyledRickWarningProps = {
  $Color: string;
};

export const StyledRickWarning = styled(Typography)<StyledRickWarningProps>`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(5)}px;
  font-style: italic;
  font-weight: 300;
  color: ${({ $Color }) => $Color};
`;

export const WhiteSectionTitle = styled(SectionTitle)`
  color: ${colors.white};
`;

export const SectionInner = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const DateLabel = styled.p`
  margin: ${(p) => p.theme.spacing(0.25, 0, 0, 0)};
  font-size: 0.875rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;

export const PillWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${(p) => p.theme.spacing(5, 0)};
`;

const Container = css`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2.5)}px auto 0 auto;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin: ${(p) => p.theme.spacing(5)}px auto 0 auto;
  }

  > * {
    margin-top: 0;
    &:last-child {
    }
  }
`;

export const UnOpenContainer = styled.div`
  ${Container}
`;

export const OnboardingContainer = styled.div`
  ${Container}
`;

export const CannotOpenContainer = styled.div`
  ${Container}
`;

export const PendingContainer = styled.div`
  ${Container}
  max-width: 580px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    margin: ${(p) => p.theme.spacing(5)}px auto 0 auto;
  }

  > * {
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
    margin-top: 0;
    &:last-child {
      margin-top: ${(p) => p.theme.spacing(5)}px;
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 580px;
  margin: 0 auto ${(p) => p.theme.spacing(5)}px auto;
`;

export const ActionContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 80%;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 65%;
  }

  ${(p) => p.theme.breakpoints.up('lg')} {
    width: 55%;
  }
`;

export const PersonalisePensionLink = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  gap: 0.5rem;
  color: ${colors.magenta};
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;
