import { useMediaQuery } from '@material-ui/core';
import { HeaderShim } from 'components/design-system/Header';
import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
} from 'components/design-system/StepComponents/StepComponents';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import { TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { useDialog } from 'hooks/useDialog';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { BackToSource } from '../personalisePension/components/BackToSource';
import {
  BubblesContainer,
  BubblesWrapper,
  GlossaryButton,
  StepWrapper,
} from './BeliefsAndValues.styles';
import { BeliefValueBuble } from './components/BeliefValueBubble';
import { Glossary, GlossaryDialog } from './components/Glossary';

/* cSpell:disable */
const tempCopy = (
  <>
    <TextNormal>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </TextNormal>
    <TextNormal $noMargin>
      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat
    </TextNormal>
  </>
);
/* cSpell:enable */

export interface positionType {
  left: number;
  top: number;
}

export interface beliefsAndValuesContentType {
  label: string;
  description: string | JSX.Element | React.ReactNode;
  bubleSize: 'small' | 'medium' | 'large';
  position: positionType;
}

const beliefsAndValuesContent: beliefsAndValuesContentType[] = [
  {
    label: 'Positive change',
    description: tempCopy,
    bubleSize: 'large',
    position: {
      left: 294,
      top: 0,
    },
  },
  {
    label: 'Exclusions based',
    description: tempCopy,
    bubleSize: 'large',
    position: {
      left: 0,
      top: 157,
    },
  },
  {
    label: 'Adult entertainment',
    description: tempCopy,
    bubleSize: 'large',
    position: {
      left: 165,
      top: 264,
    },
  },
  {
    label: 'Nuclear power',
    description: tempCopy,
    bubleSize: 'medium',
    position: {
      left: 62,
      top: 19,
    },
  },
  {
    label: 'Measurable impact',
    description: tempCopy,
    bubleSize: 'medium',
    position: {
      left: 324,
      top: 180,
    },
  },
  {
    label: 'Tobacco',
    description: tempCopy,
    bubleSize: 'medium',
    position: {
      left: 476,
      top: 247,
    },
  },
  {
    label: 'Fossil fuels',
    description: tempCopy,
    bubleSize: 'small',
    position: {
      left: 192,
      top: 123,
    },
  },
  {
    label: 'Animal testing',
    description: tempCopy,
    bubleSize: 'small',
    position: {
      left: 464,
      top: 104,
    },
  },
  {
    label: 'Weapons',
    description: tempCopy,
    bubleSize: 'small',
    position: {
      left: 25,
      top: 331,
    },
  },
  {
    label: 'Alcohol',
    description: tempCopy,
    bubleSize: 'small',
    position: {
      left: 338,
      top: 340,
    },
  },
];

interface BeliefsAndValuesProps {
  onProceed: () => void;
  onBack: () => void;
}

export function BeliefsAndValues({ onProceed, onBack }: BeliefsAndValuesProps) {
  const theme = useTheme();
  const aboveMobile = useMediaQuery(theme.breakpoints.up('md'));
  const bubblesWrapperRef = useRef<HTMLDivElement>(null);
  const [activeBeliefsAndValues, setActiveBeliefsAndValues] = useState<
    string[]
  >([]);

  const {
    open: glossaryOpen,
    openDialog: openGlossary,
    closeDialog: closeGlossary,
  } = useDialog();

  const handleExitFlow = () => {
    trackGa({
      event: GaEventNames.multiStepFormExit,
      [GaProperties.form]: 'Personalise Pension - Beliefs and values',
    });
  };

  const handleSetActive = (label: string) => {
    setActiveBeliefsAndValues((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  useEffect(() => {
    if (bubblesWrapperRef.current && !aboveMobile) {
      const scrollToPosition =
        (bubblesWrapperRef.current.scrollWidth -
          bubblesWrapperRef.current.clientWidth) /
        2;
      bubblesWrapperRef.current.scrollLeft = scrollToPosition;
    }
  }, [aboveMobile]);

  return (
    <>
      <StepContainer>
        <HeaderShim />
        <StepWrapper>
          <BackToSource onClick={handleExitFlow} />

          <StepContent width={StepContentWidth.wide}>
            <H1Large $textAlign={TextAlign.center}>Beliefs & Values</H1Large>
            {/* cSpell:disable */}
            {/* TODO: Update temp copy */}
            <TextNormal $textAlign={TextAlign.center}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </TextNormal>
            {/* cSpell:enable */}
          </StepContent>

          <BubblesWrapper ref={bubblesWrapperRef}>
            <BubblesContainer>
              {beliefsAndValuesContent.map((bubble) => (
                <BeliefValueBuble
                  key={bubble.label}
                  size={bubble.bubleSize}
                  title={bubble.label}
                  isSelected={activeBeliefsAndValues.includes(bubble.label)}
                  position={bubble.position}
                  onSelect={() => handleSetActive(bubble.label)}
                />
              ))}
            </BubblesContainer>
          </BubblesWrapper>

          <GlossaryButton aria-label="Glossary" onClick={openGlossary}>
            Glossary
          </GlossaryButton>

          <StepActions>
            <StepButton onClick={onProceed} className="magenta">
              Continue
            </StepButton>
            <GoBackButton onClick={onBack} />
          </StepActions>
        </StepWrapper>
      </StepContainer>
      {!aboveMobile && glossaryOpen && (
        <CustomSwipeableDrawer
          onClose={() => {
            closeGlossary();
          }}
          title="Glossary"
        >
          <Glossary
            glossaryEntries={beliefsAndValuesContent}
            selectedEntries={activeBeliefsAndValues}
            isDialog={false}
            onSelectEntry={handleSetActive}
          />
        </CustomSwipeableDrawer>
      )}
      {aboveMobile && glossaryOpen && (
        <GlossaryDialog
          glossaryEntries={beliefsAndValuesContent}
          selectedEntries={activeBeliefsAndValues}
          open={glossaryOpen}
          onClose={closeGlossary}
          onSelectEntry={handleSetActive}
        />
      )}
    </>
  );
}
