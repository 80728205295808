import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from './PersonalisePensionStep.styles';

export const Step3DiversificationKeyTakeaways = () => {
  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>
        Diversification: Key takeaways
      </H1Large>

      <OverviewCopyContainer>
        <OverViewList>
          <AnimatePresence>
            <KeyPointLi delay={0.25}>
              <TextNormal $noMargin>
                Diversification essentially means not putting all your eggs in
                one basket.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>
                In practice, diversification is about achieving balance in your
                pension.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>
                Different types of investments will perform differently over
                time and by having a level of balance, you can smooth out some
                of those peaks and troughs.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.45}>
              <TextNormal $noMargin>
                There are many ways to achieve balance and there is no one right
                answer.
              </TextNormal>
              <ul>
                <KeyPointLi delay={0.55}>
                  <TextNormal $noMargin>
                    Examples include combining different asset classes, regions,
                    sectors, or investment styles, etc.
                  </TextNormal>
                </KeyPointLi>
              </ul>
            </KeyPointLi>
            <KeyPointLi delay={0.65}>
              <TextNormal $noMargin>
                Multi-asset funds can be a great ready-made solution if you want
                to combine different asset classes, but don’t want to make
                specific asset class decisions.
              </TextNormal>
            </KeyPointLi>
          </AnimatePresence>
        </OverViewList>
      </OverviewCopyContainer>
    </Content>
  );
};
