import { QueryState } from 'components/QueryState';
import { Card } from 'components/design-system/Card/Card';
import { StyledLink } from 'components/design-system/Link';
import { TabNav } from 'components/design-system/Tabs/TabNav/TabNav';
import {
  Text,
  TextAlign,
  TextNormal,
} from 'components/design-system/Text/Text';
import {
  Toggle,
  ToggleColorOptions,
} from 'components/design-system/Toggle/Toggle';
import { GaEventNames } from 'constants/gaConstants';
import { AccountStatus, WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  dynamicPortfolioConstructionAddCashPath,
  dynamicPortfolioConstructionBasketAddCashPath,
  dynamicPortfolioConstructionBasketPath,
  dynamicPortfolioConstructionFundsAddCashPath,
  dynamicPortfolioConstructionFundsPath,
  dynamicPortfolioConstructionPath,
  generateDynamicPortfolioConstructionBasketPath,
  generateDynamicPortfolioConstructionFundsPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { FundsListPortfolioBuilderLayout } from '../FundsList/FundsListPortfolioBuilderLayout';
import { useMode } from '../mode/useMode';
import { FundsBasket } from './FundsBasket/FundsBasket';
import { BasketOnlyAllocation } from './InteractiveBreakdown/BasketOnlyAllocation';
import { InteractiveBreakdown } from './InteractiveBreakdown/InteractiveBreakdown';
import { InteractiveDrillDownV2 } from './InteractiveDrillDownV2/InteractiveDrillDownV2';
import { MyFundsList } from './MyFundsList';
import {
  ClosedAccountMessageWrapper,
  DecisionMakingContainer,
  PortfolioBuilderContainer,
  PreambleWrapper,
  ToggleContainer,
} from './PortfolioBuilder.styles';
import { PortfolioBuilderRating } from './Rating/PortfolioBuilderRating';
import { ColumnHeading, ColumnHeadingTabs } from './_shared/ColumnHeading';
import { HeaderBar } from './_shared/HeaderBar';
import { useFundsBasket } from './hooks/useFundsBasket';

interface DynamicPortfolioConstructionDecisionMakingProps {
  selectedAccountType: WrapperType;
  selectedAccountId: string | null;
  selectedAccountStatus?: AccountStatus;
}

export const DynamicPortfolioConstructionDecisionMaking = ({
  selectedAccountType,
  selectedAccountId,
  selectedAccountStatus,
}: DynamicPortfolioConstructionDecisionMakingProps) => {
  const [showInteractiveDrillDownV2] = useToggle(
    'global-interactive-drill-down-v2'
  );

  const [activeBreakdown, setActiveBreakdown] = useState<
    'original' | 'updated'
  >('original');

  const {
    portfolioRebalancingsQuery,
    basketSummary,
    getRebalancingId,
  } = useFundsBasket({
    selectedAccountId,
  });

  const { showNewMessages } = useIntercom();
  const accountIsClosed = selectedAccountStatus === AccountStatus.Closed;

  const [mode, setMode] = useMode();

  useEffect(() => {
    if (
      mode !== null &&
      mode.mode !== 'resume' &&
      mode.wrapperType !== selectedAccountType
    ) {
      setMode({
        wrapperType: selectedAccountType,
        mode: 'buy',
      });
    }
  }, [selectedAccountType, mode, setMode]);

  const handleReopenAccountLink = () => {
    trackGa({
      event: GaEventNames.selectContent,
      item_id: 'talk to support',
      reason: `portfolio builder - reopen my ${selectedAccountType}`,
    });
    showNewMessages(`I'd like to reopen my ${selectedAccountType}`);
  };

  return (
    <QueryState {...portfolioRebalancingsQuery}>
      {() => {
        return (
          <div>
            <DecisionMakingContainer maxWidth="lg">
              <HeaderBar selectedAccountType={selectedAccountType} />
              {showInteractiveDrillDownV2 && (
                <ToggleContainer>
                  <Toggle
                    onClick={(value) => {
                      setActiveBreakdown(value);
                    }}
                    $color={ToggleColorOptions.secondary}
                    options={[
                      { value: 'original', label: 'Original' },
                      { value: 'updated', label: 'Updated' },
                    ]}
                    value={activeBreakdown}
                  />
                </ToggleContainer>
              )}

              {activeBreakdown === 'updated' &&
                selectedAccountId &&
                !accountIsClosed && (
                  <>
                    <PreambleWrapper>
                      <TextNormal>
                        This new tool allows you to build and rebalance your
                        portfolio with asset allocation in mind, broken down by
                        asset class or region.
                      </TextNormal>
                    </PreambleWrapper>
                    <InteractiveDrillDownV2
                      selectedAccountId={selectedAccountId}
                      portfolioRebalancingId={getRebalancingId()}
                    />
                  </>
                )}
              {activeBreakdown === 'original' && !accountIsClosed && (
                <>
                  <PreambleWrapper>
                    <TextNormal>
                      This new tool allows you to build and rebalance your
                      portfolio with asset allocation in mind, broken down by
                      asset class or region.
                    </TextNormal>
                    <TextNormal>
                      Please note, the asset allocation is based on the mandate
                      of the funds. The mandate is what (asset class) or where
                      (region) a fund is able to invest in. At this stage, the
                      tool does not take into account current holdings or live
                      exposure within a fund.
                    </TextNormal>
                  </PreambleWrapper>
                  <Card>
                    {selectedAccountId && selectedAccountType ? (
                      <InteractiveBreakdown
                        selectedAccountType={selectedAccountType}
                        selectedAccountId={selectedAccountId}
                      />
                    ) : (
                      <BasketOnlyAllocation
                        selectedAccountType={selectedAccountType}
                        selectedAccountId={selectedAccountId || ''}
                      />
                    )}
                  </Card>
                </>
              )}

              {accountIsClosed && (
                <Card $padding="lg">
                  <ClosedAccountMessageWrapper>
                    <Text $textAlign={TextAlign.center} $noMargin>
                      This account is closed. If you would like to reopen it,
                      please speak to our{' '}
                      <StyledLink onClick={handleReopenAccountLink}>
                        customer support team
                      </StyledLink>
                      .
                    </Text>
                  </ClosedAccountMessageWrapper>
                </Card>
              )}
            </DecisionMakingContainer>

            <ColumnHeading>
              <ColumnHeadingTabs>
                <TabNav
                  navItems={[
                    {
                      label: 'My funds',
                      href: generateDynamicPortfolioConstructionPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                    {
                      label: 'Basket',
                      href: generateDynamicPortfolioConstructionBasketPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                    {
                      label: 'Search funds',
                      href: generateDynamicPortfolioConstructionFundsPath({
                        wrapperType: getPathSegmentForWrapperType(
                          selectedAccountType
                        ),
                      }),
                    },
                  ]}
                />
              </ColumnHeadingTabs>
            </ColumnHeading>

            {selectedAccountId && (
              <Route
                path={[
                  dynamicPortfolioConstructionPath,
                  dynamicPortfolioConstructionAddCashPath,
                ]}
                exact
              >
                <PortfolioBuilderContainer maxWidth="lg">
                  <MyFundsList
                    selectedAccountType={selectedAccountType}
                    selectedAccountId={selectedAccountId}
                  />
                </PortfolioBuilderContainer>
              </Route>
            )}

            <Route
              path={[
                dynamicPortfolioConstructionFundsPath,
                dynamicPortfolioConstructionFundsAddCashPath,
              ]}
              exact
            >
              <FundsListPortfolioBuilderLayout
                selectedAccountType={selectedAccountType}
                selectedAccountId={selectedAccountId || ''}
              />
            </Route>
            <Route
              path={[
                dynamicPortfolioConstructionBasketPath,
                dynamicPortfolioConstructionBasketAddCashPath,
              ]}
              exact
            >
              <PortfolioBuilderContainer maxWidth="lg">
                <FundsBasket
                  selectedAccountId={selectedAccountId || undefined}
                  selectedAccountType={selectedAccountType}
                />
              </PortfolioBuilderContainer>
            </Route>

            <PortfolioBuilderRating basketCount={basketSummary.orderCount} />
          </div>
        );
      }}
    </QueryState>
  );
};
