import {
  FontWeight,
  Text,
  TextAlign,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { percent } from 'formatting';
import React from 'react';
import styled, { css } from 'styled-components';
import { Breakdown } from '../../breakdownTypes';

const BaseStatsWrapper = css`
  display: flex;
  gap: 0.875rem;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    flex-basis: 50%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    gap: 3.75rem;
    > div {
      flex-basis: auto;
    }
  }
`;

/** To be used as a wrapper when adding stats to the region breakdown */
export const RegionBreakdownMappedUnmappedWrapper = styled.div`
  margin: 0.5rem 0 2rem 0;
  ${BaseStatsWrapper}
`;

export const RegionBreakdownStatsWrapper = styled.div`
  margin-top: 0.5rem;
  ${BaseStatsWrapper}
`;

const StatLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 175px;
  cursor: pointer;
`;

interface StatValueProps {
  $isNegative?: boolean;
}

const StatValue = styled(Text)<StatValueProps>`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ $isNegative }) =>
    $isNegative ? colors['richBlue-500'] : colors.magenta};

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`;

interface RegionBreakDownStatProps {
  line: Pick<Breakdown, 'name' | 'proportion' | 'subName'>;
  onHover?: (
    _: any,
    leaf: Pick<Breakdown, 'name' | 'proportion' | 'subName'> | undefined,
    isStat?: boolean
  ) => void;
  onLeave?: () => void;
}

export const RegionBreakDownStat = ({
  line,
  onHover,
  onLeave,
}: RegionBreakDownStatProps) => {
  return (
    <StatLayout
      onMouseEnter={(event: React.MouseEvent) => onHover?.(event, line, true)}
      onMouseLeave={onLeave}
    >
      <StatValue $noMargin $isNegative={line.proportion < 0}>
        {percent(line.proportion)}
      </StatValue>
      <Text
        $noMargin
        $fontWeight={FontWeight.normal}
        $textAlign={TextAlign.center}
      >
        {line.name}
      </Text>
      {line.subName && (
        <TextSmall
          $noMargin
          $fontWeight={FontWeight.normal}
          $textAlign={TextAlign.center}
        >
          {line.subName}
        </TextSmall>
      )}
    </StatLayout>
  );
};
