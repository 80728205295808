import { BreakdownNoProportions } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { useAccountXrayQuery } from 'generated/graphql';
import flatMap from 'lodash/flatMap';
import type { AccountXrayQueryAccountPositions } from 'types/graphqlTypes';
import { groupAssetBreakdown } from './groupAssetBreakdown';

interface UseAssetBreakdownProps {
  accountId: string;
}

export function useAssetBreakdownRaw({ accountId }: UseAssetBreakdownProps) {
  const accountXrayQuery = useAccountXrayQuery({
    id: accountId,
  });

  const positions = accountXrayQuery.data?.account?.positions ?? [];

  const assetClassBreakdown: BreakdownNoProportions[] = flatMap(
    positions,
    (position: AccountXrayQueryAccountPositions): BreakdownNoProportions[] => {
      if (position.instrument === null) {
        return [
          {
            name: 'Cash',
            subName: 'Cash',
            amount: position.currentValue,
            existingAmount: position.currentValue,
            code: 'ACCA',
            state: 'existing',
          },
        ];
      }

      if (position.instrument?.assetClassBreakdown?.nodes.length === 0) {
        return [
          {
            name: position.instrument.name,
            subName: position.instrument.name,
            amount: position.currentValue,
            existingAmount: position.currentValue,
            code: 'UNKO',
            state: 'existing',
          },
        ];
      }

      const breakdown: BreakdownNoProportions[] =
        position.instrument?.assetClassBreakdown?.nodes.map((node) => ({
          name: node.name,
          subName: position.instrument?.name,
          amount:
            Math.round(position.currentValue * node.proportion * 100) / 100,
          existingAmount:
            Math.round(position.currentValue * node.proportion * 100) / 100,
          code: node.code === null ? undefined : node.code,
          state: 'existing',
        })) ?? [];

      return breakdown;
    }
  );

  return {
    query: accountXrayQuery,
    breakdownLines: assetClassBreakdown,
  };
}

export function useAssetBreakdown({ accountId }: UseAssetBreakdownProps) {
  const { query, breakdownLines } = useAssetBreakdownRaw({ accountId });

  return {
    query,
    breakdown: groupAssetBreakdown(breakdownLines),
  };
}
