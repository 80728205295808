import { QueryState } from 'components/QueryState';
import {
  ButtonWrapper,
  LinkCustomButton,
} from 'components/design-system/Button/CustomButtonV2';
import {
  H6,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { Pill } from 'components/design-system/Pill/Pill';
import {
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { FontSize, Text } from 'components/design-system/Text/Text';
import {
  ActionStatus,
  ActionType,
  useActionsQuery,
  useUserProfileQuery,
  WrapperType,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { useToggle } from 'hooks/useFeatureToggle';
import { actionsPath, dashboardPath } from 'paths';
import { useParams } from 'react-router-dom';
import {
  ActionsQueryAction,
  UserProfileQueryClientSummaryAccount,
  UserProfileQueryClientSummaryAccounts,
} from 'types/graphqlTypes';
import { actionSpecLookUp } from '../actionSpecLookUp';
import {
  ActionCard,
  ActionsListContainer,
  ActionsWrapper,
  CardLayout,
  CheckedIcon,
  DismissedIcon,
  ListLayout,
  UncheckedIcon,
} from './ActionsList.styles';

interface ActionsCardProps {
  action: ActionsQueryAction;
  account?: UserProfileQueryClientSummaryAccount;
  refetchActions: () => void;
  hasActionsForDifferentAccounts: boolean;
  source?: 'account';
}

function ActionsCard({
  action,
  account,
  hasActionsForDifferentAccounts,
  source,
}: ActionsCardProps) {
  const [personalisePensionToggle] = useToggle('global-personalise-pension');

  const actionSpec = actionSpecLookUp[action.type];
  if (!actionSpec || actionSpec === undefined) {
    return null;
  }

  const { title, description, path } = actionSpec;
  let queryString = '';
  if (action.type === ActionType.PickInvestments) {
    queryString = `?source=actions`;
  } else if (source === 'account') {
    queryString = `?source=account`;
  }

  return (
    <ActionCard $active={action.status === ActionStatus.Active}>
      <CardLayout>
        {action.status === ActionStatus.Active && <UncheckedIcon />}
        {action.status === ActionStatus.Completed && <CheckedIcon />}
        {action.status === ActionStatus.Dismissed && <DismissedIcon />}
        <H6 $noMargin>
          {typeof title === 'function'
            ? title({
                wrapperType: account?.wrapperType,
              })
            : title}
          {account && hasActionsForDifferentAccounts && (
            <Pill $fontSize={FontSize.small}>
              {getShortNameForWrapperType(account?.wrapperType)}
            </Pill>
          )}
        </H6>

        <Text $noMargin>
          {typeof description === 'function'
            ? description({
                wrapperType: account?.wrapperType,
              })
            : description}
        </Text>
        {action.status === ActionStatus.Active && path && (
          <>
            <ActionsWrapper>
              <LinkCustomButton
                to={
                  typeof path === 'function'
                    ? path({
                        wrapperType: account?.wrapperType,
                        actionId: action.id,
                        personalisePensionEnabled:
                          personalisePensionToggle?.enabled,
                      }) + queryString
                    : path + queryString
                }
                $size="small"
              >
                Go
              </LinkCustomButton>
            </ActionsWrapper>
          </>
        )}
      </CardLayout>
    </ActionCard>
  );
}

interface ActionsListProps {
  actions: ActionsQueryAction[];
  accounts: UserProfileQueryClientSummaryAccounts;
  refetchActions: () => void;
  source?: 'account';
}

function ActionsList({
  actions,
  accounts,
  refetchActions,
  source,
}: ActionsListProps) {
  const actionAccountIds = actions
    .map(({ accountId }) => accountId)
    .filter((accountId) => accountId !== null);

  const hasActionsForDifferentAccounts = actionAccountIds.some(
    (accountId) => accountId !== actionAccountIds[0]
  );
  return (
    <ListLayout>
      {actions
        .sort((a, b) => {
          if (a.status === b.status) {
            if (a.type === ActionType.PickInvestments) {
              return -1;
            }
            if (b.type === ActionType.PickInvestments) {
              return 1;
            }
          }
          return a.status > b.status ? 1 : -1;
        })
        .map((action) => {
          const account = accounts.find(({ id }) => id === action.accountId);
          if (action.accountId && !account) {
            return null;
          }
          return (
            <ActionsCard
              key={action.id}
              action={action}
              account={account}
              refetchActions={refetchActions}
              hasActionsForDifferentAccounts={hasActionsForDifferentAccounts}
              source={source}
            />
          );
        })}
    </ListLayout>
  );
}

interface IntroProps {
  wrapperType?: WrapperType;
  allActionsComplete: boolean;
}

const NoActionsInto = ({ wrapperType }: IntroProps) => {
  return (
    <>
      <StepTitle $textAlign={HeadingAlign.center}>
        {wrapperType
          ? `${getShortNameForWrapperType(wrapperType)} actions`
          : 'Your actions'}
      </StepTitle>
      <StepIntroduction mb={2}>
        <StepIntroductionTypography>
          {wrapperType
            ? `You have no actions for your ${getShortNameForWrapperType(
                wrapperType
              )}`
            : 'You have no actions'}
        </StepIntroductionTypography>
      </StepIntroduction>
    </>
  );
};

const CompletedActionsIntro = ({ wrapperType }: IntroProps) => {
  return (
    <>
      <StepTitle $textAlign={HeadingAlign.center}>
        {wrapperType
          ? `${getShortNameForWrapperType(wrapperType)} actions`
          : 'Your actions'}
      </StepTitle>
      <StepIntroduction mb={2}>
        <StepIntroductionTypography>
          All actions have been completed!
        </StepIntroductionTypography>
      </StepIntroduction>
    </>
  );
};

const JustOpenedIntro = ({ wrapperType }: IntroProps) => {
  return (
    <>
      <StepTitle $textAlign={HeadingAlign.center}>
        Your TILLIT{' '}
        {wrapperType ? getShortNameForWrapperType(wrapperType) : 'account'} is
        ready!
      </StepTitle>
      <StepIntroduction mb={2}>
        <StepIntroductionTypography>
          To make the most of your pension, complete the steps below.
        </StepIntroductionTypography>
      </StepIntroduction>
    </>
  );
};

const GenericIntro = ({ wrapperType }: IntroProps) => {
  return (
    <>
      <StepTitle $textAlign={HeadingAlign.center}>Your actions</StepTitle>
      <StepIntroduction mb={2}>
        <StepIntroductionTypography>
          The following steps will help you make the most of your TILLIT account
        </StepIntroductionTypography>
      </StepIntroduction>
    </>
  );
};

const getIntro = (
  actions?: ActionsQueryAction[] | null,
  accounts?: UserProfileQueryClientSummaryAccounts | null
) => {
  if (!actions || !accounts) {
    return () => null;
  }
  const actionAccountIds = actions
    .map(({ accountId }) => accountId)
    .filter((accountId) => accountId !== null);

  if (actions.length === 0) {
    return NoActionsInto;
  }

  if (actions.every(({ status }) => status === ActionStatus.Completed)) {
    return CompletedActionsIntro;
  }

  const hasActionsForDifferentAccounts = actionAccountIds?.some(
    (accountId) => accountId !== actionAccountIds[0]
  );
  if (hasActionsForDifferentAccounts) {
    return GenericIntro;
  }

  const account = accounts.find(({ id }) => id === actionAccountIds[0]);

  if (!account) {
    return GenericIntro;
  }

  const createdTimestamp = new Date(account.createdTimestampUtc);
  const threeDaysAgo = new Date(Date.now() - 3 * 24 * 60 * 60 * 1000);

  if (account && createdTimestamp > threeDaysAgo) {
    return JustOpenedIntro;
  }

  return GenericIntro;
};

export function ActionsListStep() {
  const params = useParams<{ accountId?: string; wrapperType?: string }>();
  const accountId = params.accountId;

  const actionsQuery = useActionsQuery({
    filter: {
      statuses: [ActionStatus.Active, ActionStatus.Completed],
      completedWithinDays: 2,
    },
  });
  const userProfile = useUserProfileQuery();

  const validAccountId = userProfile.data?.clientSummary?.accounts?.some(
    (account) => account.id === accountId
  );

  const actions =
    accountId && validAccountId
      ? actionsQuery.data?.actions?.filter(
          (action) => action.accountId === accountId
        )
      : actionsQuery.data?.actions;

  const Intro = getIntro(actions, userProfile.data?.clientSummary?.accounts);

  let account: UserProfileQueryClientSummaryAccount | null = null;
  if (accountId) {
    account =
      userProfile.data?.clientSummary?.accounts?.find(
        ({ id }) => id === accountId
      ) || null;
  } else if (userProfile.data?.clientSummary?.accounts?.length === 1) {
    account = userProfile.data?.clientSummary?.accounts[0];
  }

  const allActionsComplete = !!actions?.every(
    ({ status }) => status === ActionStatus.Completed
  );
  const anyActionActive = actionsQuery.data?.actions?.some(
    ({ status }) => status === ActionStatus.Active
  );

  return (
    <ActionsListContainer>
      <QueryState {...userProfile}>
        {() => (
          <QueryState {...actionsQuery}>
            {() => (
              <>
                <StepContent width={StepContentWidth.extraWide}>
                  <Intro
                    wrapperType={account ? account.wrapperType : undefined}
                    allActionsComplete={allActionsComplete}
                  />
                  {userProfile.data?.clientSummary?.accounts && actions && (
                    <ActionsList
                      actions={actions}
                      accounts={userProfile.data.clientSummary.accounts}
                      refetchActions={actionsQuery.refetch}
                      source={validAccountId ? 'account' : undefined}
                    />
                  )}
                </StepContent>
                {allActionsComplete && (
                  <ButtonWrapper>
                    {anyActionActive ? (
                      <LinkCustomButton to={actionsPath}>
                        See actions for other accounts
                      </LinkCustomButton>
                    ) : (
                      <LinkCustomButton to={dashboardPath}>
                        Dashboard
                      </LinkCustomButton>
                    )}
                  </ButtonWrapper>
                )}
              </>
            )}
          </QueryState>
        )}
      </QueryState>
    </ActionsListContainer>
  );
}
