import { colors } from 'constants/colors';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const BlockQuote = styled.div`
  margin: 0.25rem 1rem;
  padding: 0.25rem 1rem;
  position: relative;
`;

export const QuoteLeft = styled(FaQuoteLeft)`
  position: absolute;
  color: ${colors['magenta-50']};
  height: 2rem;
  width: 2rem;
  z-index: -1;
  top: -0.25rem;
  left: -0.75rem;
`;

export const QuoteRight = styled(FaQuoteRight)`
  position: absolute;
  color: ${colors['magenta-50']};
  height: 2rem;
  width: 2rem;
  z-index: -1;
  bottom: -0.25rem;
  right: -0.75rem;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 800px;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.5rem 0 0 0;
`;

export const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
`;

export const OverviewCopyContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const OverViewList = styled.ul`
  li {
    padding-bottom: 0.785rem;
    &:last-child {
      padding-bottom: 0;
    }
    ul li {
      padding-bottom: 0.325rem;
      &:first-child {
        padding-top: 0.325rem;
      }
    }
  }
`;
