import { Checkbox, FormControlLabel } from '@material-ui/core';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  H1Xl,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import { StyledA } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  FontWeight,
  TextAlign,
  TextNormal,
} from 'components/design-system/Text/Text';
import { FeeTierCopy } from 'components/feature/openSipp/steps/_shared/FeeTierCopy';
import { useAuth } from 'context/AuthContext';
import {
  AccountType,
  WrapperType,
  useApplyReferralCodeMutation,
  useReferralCodeQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { openPensionPath } from 'paths';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import {
  GoBackButton,
  SkipButton,
  StepActions,
  StepContent,
  StepContentWidth,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CheckboxContainer,
  CoBrandedLogo,
  FeeChangeCopy,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ConfirmReferralStep.styles';

interface ConfirmReferralStepFormValues {
  optIntoComplianceDataSharing?: boolean;
}

export type returnData = {
  employerName?: string;
  returnPath?: string;
};

interface ConfirmReferralIntroCopyProps {
  mode: 'normal' | 'dialog';
  employerName?: string;
  hasPensionAccount?: boolean;
  initialAccountType?: AccountType;
}

function ConfirmReferralIntroCopy({
  hasPensionAccount,
  mode,
  employerName,
  initialAccountType,
}: ConfirmReferralIntroCopyProps) {
  if (initialAccountType !== AccountType.Sipp) {
    return null;
  } else if (hasPensionAccount || mode === 'dialog') {
    return (
      <>
        <TextNormal $textAlign={TextAlign.center}>
          <strong>{employerName}</strong> has offered to make pension
          contributions into your TILLIT Pension (a Self-Invested Personal
          Pension, or SIPP).
        </TextNormal>
      </>
    );
  } else {
    return (
      <>
        <TextNormal $textAlign={TextAlign.center}>
          <strong>{employerName}</strong> has offered to make pension
          contributions into a TILLIT Pension (a Self-Invested Personal Pension,
          or SIPP).
        </TextNormal>
      </>
    );
  }
}

interface ConfirmReferralStepProps {
  mode?: 'normal' | 'dialog';
  referralCode: string;
  onProceed: ({ employerName, returnPath }: returnData) => void;
  onBack?: () => void;
}

export function ConfirmReferralStep({
  mode = 'normal',
  referralCode,
  onProceed,
  onBack,
}: ConfirmReferralStepProps) {
  const userProfileQuery = useUserProfileQuery();
  const { refreshSession } = useAuth();
  const { clearReferralCode } = useGetEmployerCode();

  const hasPensionAccount = userProfileQuery.data?.clientSummary?.accounts?.some(
    (account) => {
      return account.wrapperType === WrapperType.Sipp;
    }
  );

  const {
    mutateAsync: applyReferralCodeMutate,
    isLoading,
    isError,
  } = useApplyReferralCodeMutation();

  const referralCodeQuery = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        if (data.referralCode === null) {
          return;
        }

        const hasEmployer = !!data.referralCode?.employer;
        const contractNoteSharing =
          data?.referralCode?.employer?.complianceConfiguration
            ?.contractNoteSharing?.enabled ?? false;
        const initialAccountTypeSipp =
          data.referralCode?.initialAccountType === AccountType.Sipp;

        if (hasEmployer && (contractNoteSharing || initialAccountTypeSipp)) {
          return;
        }

        clearReferralCode();

        // We're only handling employer referrals for pensions & contract note sharing at the moment.
        // If the referral code is not for a pension or doesn't require contract note sharing, we'll just proceed.
        onProceed({});
      },
    }
  );

  const { data: referralCodeData } = referralCodeQuery;

  const isInvalidReferralCode = referralCodeData?.referralCode === null;

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;
  const initialAccountType = referralCodeData?.referralCode?.initialAccountType;

  const complianceConfiguration =
    referralCodeData?.referralCode?.employer?.complianceConfiguration;
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const methods = useForm();
  const { handleSubmit } = methods;

  const handleDoNotLink = () => {
    clearReferralCode();
    // If user proceeds without linking account do not set an account type
    onProceed({});
  };

  const onSubmit = async (data: ConfirmReferralStepFormValues) => {
    try {
      await applyReferralCodeMutate({
        input: {
          code: referralCode,
          optIntoComplianceDataSharing: data.optIntoComplianceDataSharing,
        },
      });

      // Since we're now potentially connected to an employer, refresh our
      // authentication session so claims for other services contain
      // employer information
      await refreshSession();

      if (initialAccountType === AccountType.Sipp && !hasPensionAccount) {
        window.location.href = openPensionPath;
      } else {
        onProceed({ employerName });
      }
    } catch {
      // Error handled by state
    }
  };

  const history = useHistory();
  const location = useLocation();

  return (
    <QueryState {...referralCodeQuery}>
      {() => (
        <>
          <ReferralWelcomeContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <StepContent width={StepContentWidth.extraWide}>
                {initialAccountType === AccountType.Sipp &&
                  hasPensionAccount && (
                    <H1Xl $textAlign={HeadingAlign.center}>
                      Allow {employerName} to contribute to your TILLIT Pension
                    </H1Xl>
                  )}
                {initialAccountType === AccountType.Sipp &&
                  !hasPensionAccount && (
                    <H1Xl $textAlign={HeadingAlign.center}>
                      Let's start your TILLIT Pension journey
                    </H1Xl>
                  )}
                {initialAccountType !== AccountType.Sipp && employerName && (
                  <H1Xl $textAlign={HeadingAlign.center}>
                    Link your account to {employerName}
                  </H1Xl>
                )}
                {initialAccountType !== AccountType.Sipp && !employerName && (
                  <H1Xl $textAlign={HeadingAlign.center}>
                    Link your account
                  </H1Xl>
                )}

                {isInvalidReferralCode ? (
                  <StepContent>
                    <TextNormal
                      $textAlign={HeadingAlign.center}
                      $fontWeight={FontWeight.medium}
                    >
                      You have an invalid referral code in the URL
                    </TextNormal>
                    <TextNormal $textAlign={HeadingAlign.center}>
                      Please correct this to continue
                    </TextNormal>
                    <TextNormal $textAlign={HeadingAlign.center}>
                      If you have any questions, just click on the blue icon in
                      the bottom right corner to speak to our team on live chat
                    </TextNormal>
                    <StepActions>
                      <SkipButton
                        variant="outlined"
                        className="richBlue"
                        onClick={() => {
                          clearReferralCode();
                          history.push(location.pathname);
                          onProceed({});
                        }}
                      >
                        Continue to dashboard without applying a code
                      </SkipButton>
                    </StepActions>
                  </StepContent>
                ) : (
                  <>
                    {referralCodeData?.referralCode?.employer?.logoUrl && (
                      <ReferralCoBrandingContainer>
                        <TillitLogo />
                        <AiOutlineClose />
                        <CoBrandedLogo
                          src={
                            referralCodeData?.referralCode?.employer?.logoUrl
                          }
                          alt={employerName || ''}
                        />
                      </ReferralCoBrandingContainer>
                    )}

                    <ConfirmReferralIntroCopy
                      hasPensionAccount={hasPensionAccount}
                      mode={mode}
                      employerName={employerName}
                      initialAccountType={initialAccountType}
                    />

                    <FeeChangeCopy>
                      <FeeTierCopy referralCode={referralCode} />
                    </FeeChangeCopy>

                    {contractNoteSharing && (
                      <CheckboxContainer>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="optIntoComplianceDataSharing"
                              inputRef={methods.register}
                            />
                          }
                          label={
                            complianceConfiguration?.policyLinkDescription ? (
                              <>
                                Forward contract notes to{' '}
                                <strong>{employerName}</strong>, as per their{' '}
                                {complianceConfiguration?.policyUrl ? (
                                  <StyledA
                                    href={complianceConfiguration?.policyUrl}
                                    target="_blank"
                                  >
                                    {
                                      complianceConfiguration?.policyLinkDescription
                                    }{' '}
                                    <HiExternalLink />
                                  </StyledA>
                                ) : (
                                  <>
                                    {
                                      complianceConfiguration?.policyLinkDescription
                                    }
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                Forward contract notes to{' '}
                                <strong>{employerName}</strong>, as per their
                                trading policy
                              </>
                            )
                          }
                        />
                      </CheckboxContainer>
                    )}

                    {initialAccountType === AccountType.Sipp &&
                      !hasPensionAccount && (
                        <TextNormal $textAlign={TextAlign.center}>
                          We'll spend the next few minutes helping you to decide
                          if the TILLIT Pension is right for you and setting up
                          your pension contributions.
                        </TextNormal>
                      )}

                    <ServerError isVisible={isError} />

                    <ReferralActionsContainer>
                      <CustomButtonV2 disabled={isLoading} type="submit">
                        {initialAccountType === AccountType.Sipp &&
                        !hasPensionAccount
                          ? 'Open pension account'
                          : 'Continue'}
                      </CustomButtonV2>

                      <CustomButtonV2 $color="white" onClick={handleDoNotLink}>
                        Continue without linking my account to {employerName}
                      </CustomButtonV2>
                      {onBack && <GoBackButton onClick={onBack} />}
                    </ReferralActionsContainer>
                  </>
                )}
              </StepContent>
            </form>
          </ReferralWelcomeContainer>
        </>
      )}
    </QueryState>
  );
}
