import { useAuth } from 'context/AuthContext';
import { useUserProfileQuery } from 'generated/graphql';

export const retirementRangeOptions = {
  over20: 'over20',
  years15_20: 'years15_20',
  years10_15: 'years10_15',
  years5_10: 'years5_10',
  under5: 'under5',
};

export type RetirementRangeType = typeof retirementRangeOptions[keyof typeof retirementRangeOptions];

export const useRetirementRange = () => {
  const { signedIn } = useAuth();
  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });

  const currentYear = new Date().getFullYear();
  const retirementAge =
    userProfileQuery.data?.userProfile?.pensionDetails?.retirementAge;
  const yearOfBirth = new Date(
    userProfileQuery.data?.userProfile?.dateOfBirth
  ).getFullYear();

  const years = retirementAge ? retirementAge - (currentYear - yearOfBirth) : 0;

  let retirementRange = retirementRangeOptions.under5;
  if (years > 20) retirementRange = retirementRangeOptions.over20;
  if (years > 15) retirementRange = retirementRangeOptions.years15_20;
  if (years > 10) retirementRange = retirementRangeOptions.years10_15;
  if (years > 5) retirementRange = retirementRangeOptions.years5_10;

  return {
    query: userProfileQuery,
    retirementRange,
  };
};
