import * as format from 'formatting';
import React from 'react';
import { FundBreakdown } from '../breakdownTypes';
import { TooltipBreakdownContainer, TooltipText } from './Breakdown.styles';

interface TooltipBreakdownProps {
  lines: FundBreakdown[];
}

export function TooltipBreakdown({ lines }: TooltipBreakdownProps) {
  return (
    <TooltipBreakdownContainer $isTall={lines.length > 8}>
      {lines.map((line, index) => (
        <React.Fragment key={`${format.slugify(line.name)}-${index}}`}>
          <TooltipText $hasDrillDown>
            <b>{line.name}</b>
            <span>{format.percent(line.proportion)}</span>
          </TooltipText>
        </React.Fragment>
      ))}
    </TooltipBreakdownContainer>
  );
}
