import { BreakdownNoProportions } from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import { usePortfolioRebalancingQuery } from 'generated/graphql';
import flatMap from 'lodash/flatMap';
import {
  RebalancingQueryBuyOrder,
  RebalancingQuerySellOrder,
} from 'types/graphqlTypes';
import { groupAssetBreakdown } from './groupAssetBreakdown';

interface UseRebalancingAssetBreakdownProps {
  rebalancingId: string;
}

export function useRebalancingAssetBreakdownRaw({
  rebalancingId,
}: UseRebalancingAssetBreakdownProps) {
  const portfolioRebalancingQuery = usePortfolioRebalancingQuery(
    {
      id: rebalancingId,
    },
    { enabled: !!rebalancingId }
  );

  const buyOrders =
    portfolioRebalancingQuery.data?.portfolioRebalancing?.buyOrders ?? [];
  const sellOrders =
    portfolioRebalancingQuery.data?.portfolioRebalancing?.sellOrders ?? [];

  const buyOrderLines: BreakdownNoProportions[] = flatMap(
    buyOrders,
    (buyOrder: RebalancingQueryBuyOrder): BreakdownNoProportions[] => {
      if (buyOrder.instrument?.assetClassBreakdown?.nodes.length === 0) {
        return [
          {
            name: 'Unknown',
            subName: 'Unknown',
            amount: buyOrder.amount,
            existingAmount: buyOrder.amount,
            code: 'UNKO',
            state: 'buyOrder',
          },
        ];
      }

      const breakdown: BreakdownNoProportions[] =
        buyOrder.instrument?.assetClassBreakdown?.nodes.map((node) => ({
          name: node.name,
          subName: buyOrder.instrument?.name,
          amount: Math.round(buyOrder.amount * node.proportion * 100) / 100,
          existingAmount:
            Math.round(buyOrder.amount * node.proportion * 100) / 100,
          code: node.code === null ? undefined : node.code,
          state: 'buyOrder',
        })) ?? [];

      return breakdown;
    }
  );

  const sellOrderLines: BreakdownNoProportions[] = flatMap(
    sellOrders,
    (sellOrder: RebalancingQuerySellOrder): BreakdownNoProportions[] => {
      if (sellOrder.instrument?.assetClassBreakdown?.nodes.length === 0) {
        return [
          {
            name: 'Unknown',
            subName: 'Unknown',
            amount: sellOrder.enteredAmount,
            existingAmount: sellOrder.enteredAmount,
            code: 'UNKO',
            state: 'sellOrder',
          },
        ];
      }

      const breakdown: BreakdownNoProportions[] =
        sellOrder.instrument?.assetClassBreakdown?.nodes.map((node) => ({
          name: node.name,
          subName: sellOrder.instrument?.name,
          amount:
            Math.round(-sellOrder.enteredAmount * node.proportion * 100) / 100,
          existingAmount:
            Math.round(-sellOrder.enteredAmount * node.proportion * 100) / 100,
          code: node.code === null ? undefined : node.code,
          state: 'sellOrder',
        })) ?? [];

      return breakdown;
    }
  );

  const assetClassBreakdown: BreakdownNoProportions[] = [
    ...buyOrderLines,
    ...sellOrderLines,
  ];

  return {
    query: portfolioRebalancingQuery,
    breakdownLines: assetClassBreakdown,
  };
}

export function useRebalancingAssetBreakdown({
  rebalancingId,
}: UseRebalancingAssetBreakdownProps) {
  const { query, breakdownLines } = useRebalancingAssetBreakdownRaw({
    rebalancingId,
  });

  return {
    query,
    breakdown: groupAssetBreakdown(breakdownLines),
  };
}
