import {
  Breakdown,
  BreakdownNoProportions,
} from 'components/feature/FundDetails/Breakdown/breakdownTypes';
import worldData from 'components/feature/FundDetails/Breakdown/RegionBreakdown/world';
import orderBy from 'lodash/orderBy';
import sum from 'lodash/sum';
import { groupBreakdown } from './groupBreakdown';

const getCountryNameFromCode = (code: string) => {
  const country = worldData.features.find(
    (feature) => feature.properties?.code === code
  );

  return country?.properties?.name;
};

const orderRegionalBreakdown = (breakdown: Breakdown): Breakdown => {
  const sortingSelector = ({ code, amount }: Breakdown) => {
    if (code === 'Cash') {
      return Number.MIN_SAFE_INTEGER;
    }
    if (code === 'Other') {
      return Number.MIN_SAFE_INTEGER + 1;
    }
    return amount;
  };

  return {
    ...breakdown,
    lines: orderBy(breakdown.lines, sortingSelector, 'desc'),
  };
};

const parentIsoCountryCodeLookUp: { [key: string]: string } = {
  IM: 'GB',
  JE: 'GB',
  GG: 'GB',
};

export const getBreakdownRegionalMappingKey = (line: Breakdown) => {
  if (line.type === 'Country') {
    if (line.isoCountryCode && getCountryNameFromCode(line.isoCountryCode)) {
      return getCountryNameFromCode(line.isoCountryCode);
    }

    return (
      parentIsoCountryCodeLookUp[line.isoCountryCode || ''] ??
      line.isoCountryCode
    );
  }
  if (line.type === 'Region') {
    return line.name;
  }
  return line.type ?? 'Other';
};

export const groupRegionalBreakdown = groupBreakdown(
  getBreakdownRegionalMappingKey,
  orderRegionalBreakdown
);

export const proportionise = (
  breakdownLines: BreakdownNoProportions[]
): Breakdown[] => {
  const totalAmount = sum(breakdownLines.map(({ amount }) => Math.abs(amount)));
  const existingTotalAmount = sum(
    breakdownLines.map(({ existingAmount }) => Math.abs(existingAmount))
  );

  return breakdownLines.map(
    (breakdown: BreakdownNoProportions): Breakdown => ({
      ...breakdown,
      proportion: breakdown.amount / totalAmount,
      existingProportion: breakdown.existingAmount / existingTotalAmount,
      lines: breakdown.lines ? proportionise(breakdown.lines) : [],
    })
  );
};
