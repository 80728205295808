import { motion } from 'framer-motion';

interface KeyPointProps {
  children: React.ReactNode | JSX.Element;
  delay: number;
}

const baseTransition = {
  type: 'spring',
  stiffness: 260,
  damping: 20,
};

export function KeyPointLi({ children, delay }: KeyPointProps) {
  return (
    <motion.li
      transition={{
        ...baseTransition,
        delay: delay,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      layout
    >
      {children}
    </motion.li>
  );
}
