import { QueryState } from 'components/QueryState';
import { useUserProfileQuery } from 'generated/graphql';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Wrapper } from '../openAccount/OpenAccountForm.style';
import { ConfirmReferralStep } from './components/ConfirmReferralStep/ConfirmReferralStep';

export function ConfirmReferral() {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { referralCode } = useGetEmployerCode();

  const userProfileQuery = useUserProfileQuery(undefined, {
    refetchOnMount: true,
    staleTime: 0,
    onSuccess: (data) => {
      const hasEmployer = !!data.userProfile?.employments?.[0];

      if (hasEmployer && !referralCode) {
        history.push('/dashboard');
      }
    },
  });

  const onProceed = () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    history.push('/dashboard');
  };

  return (
    <QueryState {...userProfileQuery}>
      {() => (
        <Wrapper>
          <ConfirmReferralStep
            referralCode={referralCode!}
            onProceed={onProceed}
          />
        </Wrapper>
      )}
    </QueryState>
  );
}
