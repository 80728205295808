import { Container } from '@material-ui/core';
import { StepContainer } from 'components/design-system/StepComponents/StepComponents';
import { colors } from 'constants/colors';
import styled from 'styled-components';

interface ListContainerProps {
  $smallGap?: boolean;
}

export const ListContainer = styled.div<ListContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 0;
    gap: ${({ $smallGap }) => ($smallGap ? '0.5rem' : '1.25rem')};
  }
`;

export const ListHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const PersonaliseHeadingLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: ${colors['richBlue-300']};
  font-size: 0.875rem;
  text-decoration: none;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: underline;
    color: ${colors['richBlue-400']};
  }

  &:active,
  &:visited {
    color: ${colors['richBlue-300']};
  }
`;

export const StepLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  align-items: center;
`;

export const Section = styled.div`
  width: 100%;
  padding: 0 1rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 1280px;
    padding: 0 1.5rem;
  }
`;

export const SelectFundsPercentagesStepContainer = styled(StepContainer)`
  display: grid;
  grid-template-rows: auto auto auto 1fr auto;
  padding: 7rem 0 0;
  min-height: 100vh;
  gap: 1rem;
  align-items: stretch;
  justify-items: center;
  justify-content: stretch;
  overflow: hidden;
  form {
    display: contents;
  }
`;

export const NoFundsSelectedWrapperPension = styled.div`
  position: relative;
  margin-top: 1rem;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-items: center;
  gap: 0rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-items: initial;
    padding: 3rem 0;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    gap: 2rem;
  }
`;

export const NoFundsSelectedDivider = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${colors['grey-600']};

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 1px;
    height: 180px;
  }
`;

export const NoFundsSelectedCol = styled.div`
  margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: flex-end;

    &:last-child {
      align-items: flex-start;
    }
  }
`;

export const NoFundsSelectedWrapper = styled.div`
  margin-top: 1rem;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
`;

export const HeaderWrapper = styled.div`
  padding: 0 1rem;
`;

export const TabsWrapper = styled.div`
  width: 100%;
`;

export const MyFundsContainer = styled(Container)`
  padding: 0 1.5rem;
`;
