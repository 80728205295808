import { colors } from 'constants/colors';
import styled from 'styled-components';

export const VideoNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
    justify-content: center;
  }
`;

interface VideoNavButtonProps {
  $active?: boolean;
  $viewed?: boolean;
}

export const VideoNavButton = styled.button<VideoNavButtonProps>`
  position: relative;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: ${colors.white};
  transition: background 0.25s ease-in-out;
  border: none;
  cursor: pointer;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 0.75rem 1.5rem;
  }

  h6,
  p {
    width: 100%;
    color: ${({ $active, $viewed }) =>
      $active
        ? colors.magenta
        : $viewed
        ? colors.richBlack
        : colors['grey-700']};
    transition: color 0.25s ease-in-out;
    text-align: center;
    font-weight: 500;
    cursor: ${({ $viewed, $active }) =>
      $viewed || $active ? 'pointer' : 'not-allowed'};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      text-align: initial;
    }
  }

  &:hover {
    background-color: ${colors.white};
    h6,
    p {
      color: ${({ $viewed, $active }) =>
        $viewed || $active ? colors.magenta : colors['grey-700']};
    }
  }

  &:focus {
    outline-color: ${colors['magenta-200']};
    h6,
    p {
      color: ${colors.magenta};
    }
  }
`;
