import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TutorialVideo } from '../TutorialVideo';
import { StepProps } from './_shared';
import { Content } from './PersonalisePensionStep.styles';

export const Step5BeliefsAndValues = ({ onProceed }: StepProps) => {
  const handleVideoEnd = () => {
    onProceed();
  };

  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>Beliefs and Values</H1Large>

      <TutorialVideo
        video={{
          thumbnailUrl: 'https://via.placeholder.com/150',
          videoUrl:
            '/mediacontent/protected/personalise-pension/Step-5-Beliefs-and-Values.mp4.m3u8',
          subtitlesUrl: '',
        }}
        description="Step-5-Beliefs-and-Values"
        onVideoEnd={handleVideoEnd}
      />
    </Content>
  );
};
