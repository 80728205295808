import { useMediaQuery, useTheme } from '@material-ui/core';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { Tabs } from 'components/design-system/Tabs/Tabs';
import {
  FontSize,
  FontWeight,
  Text,
  TextLarge,
} from 'components/design-system/Text/Text';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { generateCheckoutPath } from 'paths';
import { useFundsBasket } from '../hooks/useFundsBasket';
import { SellOrderContent } from './FundBasketSellOrder/SellOrderContent';
import {
  BasketContainer,
  BasketOrderWrapper,
  FundBasketSummaryContainer,
  NoMatchCopy,
  Spacer,
} from './FundsBasket.styles';
import { BuyOrderContent } from './fundBasketBuyOrder/BuyOrderContent';
import { FundBasketSummary } from './fundBasketSummary/FundBasketSummary';

interface FundsBasketProps {
  selectedAccountId?: string;
  selectedAccountType: WrapperType;
}

/**
 * Basket component
 *
 * A list of items of BasketFund from the useFundsBasket hook
 */
export function FundsBasket({
  selectedAccountId,
  selectedAccountType,
}: FundsBasketProps) {
  const { basketBuyOrders, basketSellOrders } = useFundsBasket({
    selectedAccountId,
  });

  const theme = useTheme();
  const isMbUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { getRebalancingId, canCheckout } = useFundsBasket({
    selectedAccountId: selectedAccountId ?? null,
  });
  const selectedRebalancingId = getRebalancingId();

  const tabsContent = [];
  !!basketBuyOrders.length &&
    tabsContent.push({
      title: 'BUY orders',
      content: (
        <BuyOrderContent
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
        />
      ),
    });
  !!basketSellOrders.length &&
    tabsContent.push({
      title: 'SELL orders',
      content: (
        <SellOrderContent
          selectedAccountType={selectedAccountType}
          selectedAccountId={selectedAccountId}
        />
      ),
    });

  return (
    <>
      {basketBuyOrders.length === 0 && basketSellOrders.length === 0 ? (
        <>
          <NoMatchCopy>
            <TextLarge $noMargin $fontWeight={FontWeight.normal}>
              Your basket is empty
            </TextLarge>
          </NoMatchCopy>
        </>
      ) : isMbUp ? (
        <BasketContainer>
          {basketBuyOrders.length > 0 && (
            <BasketOrderWrapper>
              <Text
                $noMargin
                $fontSize={FontSize.large}
                $fontWeight={FontWeight.medium}
              >
                BUY orders
              </Text>
              {
                <BuyOrderContent
                  selectedAccountType={selectedAccountType}
                  selectedAccountId={selectedAccountId}
                />
              }
            </BasketOrderWrapper>
          )}
          {basketSellOrders.length > 0 && (
            <BasketOrderWrapper>
              <Text
                $noMargin
                $fontSize={FontSize.large}
                $fontWeight={FontWeight.medium}
              >
                SELL orders
              </Text>
              <SellOrderContent
                selectedAccountType={selectedAccountType}
                selectedAccountId={selectedAccountId}
              />
            </BasketOrderWrapper>
          )}
          <FundBasketSummaryContainer>
            <FundBasketSummary selectedAccountId={selectedAccountId} />
          </FundBasketSummaryContainer>
        </BasketContainer>
      ) : (
        <>
          <Tabs
            title={<></>}
            stretchHeadings={true}
            isBorderless={true}
            tabs={tabsContent}
            headingSize={FontSize.large}
          />
          {selectedRebalancingId && (
            <>
              <LinkCustomButton
                type="button"
                $color="primary"
                variant="contained"
                disabled={!canCheckout()}
                to={generateCheckoutPath({
                  wrapperType: getPathSegmentForWrapperType(
                    selectedAccountType
                  ),
                  selectedRebalancingId: selectedRebalancingId,
                })}
              >
                Checkout
              </LinkCustomButton>
              <Spacer />
            </>
          )}
        </>
      )}
    </>
  );
}
