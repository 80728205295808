import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TutorialVideo } from '../TutorialVideo';
import { Content } from './PersonalisePensionStep.styles';
import { StepProps } from './_shared';

export const Step2AssetClasses = ({ onProceed }: StepProps) => {
  const handleVideoEnd = () => {
    onProceed();
  };

  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>Asset Classes</H1Large>

      <TutorialVideo
        autoplay
        chapterVariant="buttons"
        video={{
          thumbnailUrl: 'https://via.placeholder.com/150',
          videoUrl:
            '/mediacontent/protected/personalise-pension/Step-2-Asset-Classes.mp4.m3u8',
          subtitlesUrl: '',
        }}
        description="Step-2-Asset-Classes"
        onVideoEnd={handleVideoEnd}
      />
    </Content>
  );
};
