import {
  groupAssetBreakdown,
  orderAssetBreakdown,
} from './groupAssetBreakdown';
import { useAssetBreakdownRaw } from './useAssetBreakdown';
import { useRebalancingAssetBreakdownRaw } from './useRebalancingAssetBreakdown';

interface UseRebalancingAssetBreakdownProps {
  accountId: string;
  rebalancingId: string;
}

export const useCombinedAssetBreakdown = ({
  accountId,
  rebalancingId,
}: UseRebalancingAssetBreakdownProps) => {
  const {
    query: accountQuery,
    breakdownLines: accountBreakdownList,
  } = useAssetBreakdownRaw({ accountId });
  const {
    query: rebalancingQuery,
    breakdownLines: rebalancingBreakdownList,
  } = useRebalancingAssetBreakdownRaw({ rebalancingId });

  return {
    accountQuery,
    rebalancingQuery,
    breakdown: orderAssetBreakdown(
      groupAssetBreakdown([
        ...accountBreakdownList,
        ...rebalancingBreakdownList,
      ])
    ),
  };
};
