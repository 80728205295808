import { useMediaQuery, useTheme } from '@material-ui/core';
import * as d3 from 'd3';
import { percent } from 'formatting';
import max from 'lodash/max';
import min from 'lodash/min';
import { useState } from 'react';
import { TooltipLayout, TooltipText } from '../_shared/Breakdown.styles';
import { getTreeColourForProportion } from '../_shared/colourHelper';
import { FundBreakdown, FundBreakdownComponentProps } from '../breakdownTypes';
import {
  LeafContentLayout,
  LeafHeading,
  LeafValue,
  TreeBreakdownContainer,
  TreeBreakdownSvg,
} from './TreeBreakdown.styles';

export const TreeBreakdown = ({ breakdown }: FundBreakdownComponentProps) => {
  const [tooltipData, setTooltipData] = useState<null | FundBreakdown>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));

  if (breakdown.lines?.some(({ proportion }) => proportion < 0)) {
    return null;
  }

  const treemapWidth = isLargeUp ? 1280 : window.innerWidth - 50;
  const treemapHeight = isLargeUp ? 500 : window.innerHeight - 200;

  const handleMouseOver = (
    _: any,
    leaf: d3.HierarchyRectangularNode<FundBreakdown>
  ) => {
    setTooltipData(leaf.data);
    setShowTooltip(true);
  };

  const handleMouseOut = () => {
    setTooltipData(null);
    setShowTooltip(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const svg = event.currentTarget;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;

    const svgPoint = point.matrixTransform(svg.getScreenCTM()?.inverse());

    // Mouse position relative to SVG
    const mouseX = svgPoint.x + 30;
    const mouseY = svgPoint.y + 30;

    // Get SVG dimensions
    const svgRect = svg.getBoundingClientRect();
    const svgWidth = svgRect.width;
    const svgHeight = svgRect.height;

    // Calculate distances from right and bottom edges of SVG
    const distanceFromRight = svgWidth - mouseX;
    const distanceFromBottom = svgHeight - mouseY;

    // Adjust tooltip position based on distance from edges
    let tooltipX = mouseX;
    let tooltipY = mouseY;

    if (distanceFromRight < 150) {
      tooltipX -= 160;
    }

    if (distanceFromBottom < 100) {
      tooltipY -= 95;
    }
    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };

  const minProportion =
    min(breakdown.lines?.map(({ proportion }) => proportion)) || 0;
  const maxProportion =
    max(breakdown.lines?.map(({ proportion }) => proportion)) || 1;

  const convertAbsoluteProportionToRelativeProportion = d3
    .scaleLinear()
    .domain([minProportion, maxProportion])
    .range([0, 1]);

  const treemap = d3
    .treemap()
    .tile(d3.treemapSquarify.ratio(1))
    .size([treemapWidth, treemapHeight])
    .paddingInner(5)
    .round(true);

  const root = d3
    .hierarchy<FundBreakdown>(breakdown, ({ lines }) => lines)
    .sum((d) => (d.lines ? 0 : d.proportion))
    .sort((a, b) => b.data.proportion - a.data.proportion);

  const leaves = treemap(
    root
  ).leaves() as d3.HierarchyRectangularNode<FundBreakdown>[];

  return (
    <TreeBreakdownContainer>
      <TreeBreakdownSvg
        viewBox={`0 0 ${treemapWidth} ${treemapHeight}`}
        height={treemapHeight}
        width={treemapWidth}
        onMouseMove={(e) => handleMouseMove(e)}
      >
        <g>
          {leaves.map((leaf, index) => {
            const leafHeight = leaf.y1 - leaf.y0;
            const leafWidth = leaf.x1 - leaf.x0;
            const weight = convertAbsoluteProportionToRelativeProportion(
              leaf.data.proportion
            );

            return (
              <g key={leaf.data.name}>
                <rect
                  fill={getTreeColourForProportion(weight)}
                  rx="5"
                  ry="5"
                  x={leaf.x0}
                  y={leaf.y0}
                  width={leafWidth}
                  height={leafHeight}
                  onMouseOver={(event) => handleMouseOver(event, leaf)}
                  onMouseOut={() => handleMouseOut()}
                  pointerEvents="visible"
                />
                <foreignObject
                  height={leafHeight}
                  width={leafWidth}
                  x={leaf.x0}
                  y={leaf.y0}
                  alignmentBaseline="central"
                  pointerEvents="none"
                >
                  <LeafContentLayout>
                    <LeafHeading
                      $weight={leaf.data.proportion}
                      $leafWidth={leafWidth}
                      $leafHeight={leafHeight}
                    >
                      {leaf.data.name}
                    </LeafHeading>
                    <LeafValue
                      $leafWidth={leafWidth}
                      $weight={leaf.data.proportion}
                      $isMobile={!isLargeUp}
                    >
                      {percent(leaf.data.proportion)}{' '}
                    </LeafValue>
                  </LeafContentLayout>
                </foreignObject>
              </g>
            );
          })}
        </g>
      </TreeBreakdownSvg>
      {showTooltip && tooltipData && (
        <div
          style={{
            position: 'absolute',
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            pointerEvents: 'none',
          }}
        >
          <TooltipLayout>
            <TooltipText>
              <b>{tooltipData.name}</b>
            </TooltipText>
            <TooltipText>{percent(tooltipData.proportion)}</TooltipText>
          </TooltipLayout>
        </div>
      )}
    </TreeBreakdownContainer>
  );
};
