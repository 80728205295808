import IntroVideoCover from 'assets/Personalise-pension-IntroVideoCover.jpg';
import { TutorialVideo } from '../TutorialVideo';
import { Content } from './PersonalisePensionStep.styles';
import { StepProps } from './_shared';

export const Step1Intro = ({ onProceed }: StepProps) => {
  return (
    <Content>
      <TutorialVideo
        video={{
          thumbnailUrl: IntroVideoCover,
          videoUrl:
            '/mediacontent/protected/personalise-pension/Intro.mp4.m3u8',
          subtitlesUrl: '',
        }}
        description="Step 1: Introduction"
        onVideoEnd={onProceed}
        $isIntro
      />
    </Content>
  );
};
