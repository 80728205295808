import { colors } from 'constants/colors';
import * as d3 from 'd3';
import * as format from 'formatting';
import difference from 'lodash/difference';
import max from 'lodash/max';
import min from 'lodash/min';
import sum from 'lodash/sum';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { TooltipLayout, TooltipText } from '../_shared/Breakdown.styles';
import { TooltipBreakdown } from '../_shared/TooltipBreakdown';
import {
  getMapColourForProportion,
  mapColourForNegativeProportion,
} from '../_shared/colourHelper';
import { FundBreakdown, FundBreakdownComponentProps } from '../breakdownTypes';
import { RegionBreakdownKey } from './RegionBreakdownKey';
import {
  RegionBreakDownStat,
  RegionBreakdownMappedUnmappedWrapper,
  RegionBreakdownStatsWrapper,
} from './RegionBreakdownStats/RegionBreakdownStat';
import {
  TinyCountriesProjections,
  supportedTinyCountries,
} from './TinyCountriesProjections';
import worldData from './world';

const viewportWidth = 550;
const viewportHeight = 242;

const RegionTooltipLayout = styled(TooltipLayout)`
  ${({ $hasDrillDown }) =>
    $hasDrillDown &&
    css`
      width: 300px;
    `}
`;

const RegionContainer = styled.div`
  position: relative;
  pointer-events: visible;
  width: 100%;
  max-width: 1280px;
`;

const RegionSvg = styled.svg`
  width: calc(100% + 2.5rem);
  margin-left: -1.25rem;
  aspect-ratio: 100/44;
  margin-bottom: 2.5rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: auto;
    width: 100%;
  }
`;

export const breakdownSupported = (breakdown: FundBreakdown) => {
  const supportedCountries = worldData.features.map(
    (feature) => feature.properties?.code
  ) as string[];

  const countries = breakdown.lines
    ?.filter((line) => line.type !== 'Other' && line.type !== 'Cash')
    .map(({ isoCountryCode }) => isoCountryCode);

  const unsupportedCountries = difference(countries, supportedCountries);

  return unsupportedCountries.length === 0;
};

const deriveTextBreakdownLines = (breakdown: FundBreakdown) => {
  const supportedCountries = worldData.features.map(
    (feature) => feature.properties?.code
  ) as string[];

  const lines = breakdown.lines?.filter(
    (line) =>
      line.type === 'Other' ||
      line.type === 'Cash' ||
      !supportedCountries.includes(line.isoCountryCode ?? '')
  );

  return lines;
};

interface RegionBreakdownProps extends FundBreakdownComponentProps {
  showMappedUnmappedBreakdown?: boolean;
}

export const RegionBreakdown = ({
  breakdown,
  showMappedUnmappedBreakdown,
}: RegionBreakdownProps) => {
  const [tooltipData, setTooltipData] = useState<null | FundBreakdown>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const projection = d3
    .geoNaturalEarth1()
    .scale(100)
    .translate([viewportWidth / 2, viewportHeight / 1.7]);

  const deriveD = d3.geoPath().projection(projection);

  const handleMouseOver = (
    _: any,
    leaf: FundBreakdown | undefined,
    isStat?: boolean
  ) => {
    setTooltipData(leaf || null);
    setShowTooltip(isStat ? false : true);
  };

  const handleMouseOut = () => {
    setTooltipData(null);
    setShowTooltip(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const svg = event.currentTarget;
    const svgBoundingClientRect = svg.getBoundingClientRect();
    const mouseX = event.clientX - svgBoundingClientRect.left + 5;
    const mouseY = event.clientY - svgBoundingClientRect.top + 5;

    const svgWidth = svgBoundingClientRect.width;
    const svgHeight = svgBoundingClientRect.height;

    // Calculate distances from right and bottom edges of SVG
    const distanceFromRight = svgWidth - mouseX;
    const distanceFromBottom = svgHeight - mouseY;

    // Adjust tooltip position based on distance from edges
    let tooltipX = mouseX;
    let tooltipY = mouseY;

    if (distanceFromRight < 150) {
      tooltipX -= 160;
    }

    if (distanceFromBottom < 100) {
      tooltipY -= 95;
    }

    setTooltipPosition({ x: tooltipX, y: tooltipY });
  };

  const minProportion =
    min(breakdown.lines?.map(({ proportion }) => proportion)) || 0;
  const maxProportion =
    max(breakdown.lines?.map(({ proportion }) => proportion)) || 1;

  const convertAbsoluteProportionToRelativeProportion = d3
    .scaleLinear()
    .domain([minProportion, maxProportion])
    .range([0, 1]);

  const cashLines = deriveTextBreakdownLines(breakdown);

  const cashLineTotal = sum(cashLines?.map((line) => line.proportion));

  const fillColor = (breakdownLine: FundBreakdown | undefined) => {
    if (!breakdownLine) {
      return colors['seaBlue-50'];
    }

    const proportion = convertAbsoluteProportionToRelativeProportion(
      breakdownLine.proportion
    );

    return breakdownLine.proportion < 0
      ? mapColourForNegativeProportion
      : getMapColourForProportion(proportion);
  };

  const strokeColor = (
    tooltipData: FundBreakdown | null,
    breakdownLine: FundBreakdown | undefined
  ) => {
    if (!breakdownLine || !tooltipData) {
      return 'white';
    }

    const activeStroke =
      breakdownLine.proportion < 0 ? colors.richBlue : colors['magenta-600'];

    return tooltipData?.name === breakdownLine?.name ? activeStroke : 'white';
  };

  const hasNegative =
    breakdown.lines?.some(
      (line) => line.proportion < 0 && line.type === 'Country'
    ) ?? false;

  return (
    <RegionContainer>
      <div style={{ position: 'relative' }}>
        <RegionSvg
          viewBox={`0 0 ${viewportWidth} ${viewportHeight}`}
          onMouseMove={(e) => handleMouseMove(e)}
        >
          <g>
            {worldData.features.map((feature) => {
              const breakdownLineIndex = breakdown.lines?.findIndex(
                (breakdownLine) => {
                  return (
                    breakdownLine.isoCountryCode === feature.properties?.code
                  );
                }
              );
              const breakdownLine =
                breakdownLineIndex !== undefined &&
                breakdownLineIndex >= 0 &&
                breakdown.lines
                  ? breakdown.lines[breakdownLineIndex]
                  : undefined;

              const useZoomedProjection =
                feature.properties?.isTiny &&
                supportedTinyCountries.includes(
                  breakdownLine?.isoCountryCode || ''
                );

              const key = `region-${feature.id ?? feature.properties?.code3}`;

              return useZoomedProjection ? (
                <TinyCountriesProjections
                  key={key}
                  fill={fillColor(breakdownLine)}
                  stroke={strokeColor(tooltipData, breakdownLine)}
                  breakdownLine={breakdownLine}
                  onHover={handleMouseOver}
                  onLeave={handleMouseOut}
                />
              ) : (
                <path
                  key={key}
                  id={`region-${format.slugify(
                    breakdownLine?.name || 'undefined'
                  )}`}
                  fill={fillColor(breakdownLine)}
                  stroke={strokeColor(tooltipData, breakdownLine)}
                  strokeWidth={0.5}
                  d={deriveD(feature) ?? ''}
                  onMouseOver={(event: React.MouseEvent) =>
                    handleMouseOver(event, breakdownLine)
                  }
                  onMouseOut={handleMouseOut}
                  style={{
                    strokeWidth: '0.5px',
                    transition:
                      'stroke-width .25s ease-in-out, stroke .25s ease-in-out',
                  }}
                />
              );
            })}
            {/* This ensures that hovered region it set above other regions */}
            <use
              href={
                tooltipData && tooltipData?.name
                  ? `#region-${format.slugify(tooltipData.name)}`
                  : `#undefined`
              }
              style={{ pointerEvents: 'none' }}
            />
          </g>
        </RegionSvg>
        <RegionBreakdownKey includeNegative={hasNegative} />
      </div>

      {showMappedUnmappedBreakdown && cashLines && (
        <RegionBreakdownMappedUnmappedWrapper>
          <RegionBreakDownStat
            line={{
              name: 'Mapped',
              proportion: breakdown.proportion - cashLineTotal,
            }}
            onHover={handleMouseOver}
            onLeave={handleMouseOut}
          />
          <RegionBreakDownStat
            line={{
              name: 'Unmapped',
              proportion: cashLineTotal,
            }}
            onHover={handleMouseOver}
            onLeave={handleMouseOut}
          />
        </RegionBreakdownMappedUnmappedWrapper>
      )}

      {cashLines && (
        <RegionBreakdownStatsWrapper>
          {cashLines &&
            cashLines.map((line) => (
              <RegionBreakDownStat
                key={line.name}
                line={line}
                onHover={handleMouseOver}
                onLeave={handleMouseOut}
              />
            ))}
        </RegionBreakdownStatsWrapper>
      )}

      {showTooltip && tooltipData && (
        <div
          style={{
            position: 'absolute',
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            pointerEvents: 'none',
            zIndex: 8,
          }}
        >
          <RegionTooltipLayout $hasDrillDown={!!tooltipData.lines?.length}>
            <TooltipText>
              <b>{tooltipData.name}</b>
            </TooltipText>
            <TooltipText>{format.percent(tooltipData.proportion)}</TooltipText>
            {tooltipData.lines && tooltipData.lines.length > 0 && (
              <TooltipBreakdown lines={tooltipData.lines} />
            )}
          </RegionTooltipLayout>
        </div>
      )}
    </RegionContainer>
  );
};
