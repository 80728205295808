import { Card } from 'components/design-system/Card/Card';
import {
  H5,
  H6,
  TextAlign as HTextAlign,
} from 'components/design-system/Heading/Heading';
import { StyledA, StyledLink } from 'components/design-system/Link';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import {
  FontWeight,
  Text,
  TextAlign,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { ServerError } from 'components/feature/openAccount/steps/_shared/ServerError';
import { QueryState } from 'components/QueryState';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  useUpdateUserProfileMutation,
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { defaultPensionAssetPath } from 'paths';
import { useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserProfileOnboardingQueryUserProfile } from 'types/graphqlTypes';

const Hr = styled.hr`
  background: none;
  background-color: ${colors['magenta-100']};
  border: none;
  width: 40px;
  height: 1px;
`;

const EscalationCardContainer = styled(Card)`
  padding: 2rem 2.5rem;
  max-width: 18rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 0rem;
`;

const EscalationCardContent = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());
  };
}

function EscalationCard() {
  return (
    <EscalationCardContainer>
      <H5 $noMargin $textAlign={HTextAlign.center}>
        BlackRock LifePath
      </H5>
      <Hr />

      <EscalationCardContent>
        <H6
          style={{ fontStyle: 'italic', fontSize: '1rem' }}
          $textAlign={HTextAlign.center}
        >
          The autopilot option for saving for retirement
        </H6>

        <TextSmall $noMargin $textAlign={TextAlign.center}>
          Fund fee: 0.18% pa
        </TextSmall>
        <TextSmall $noMargin $textAlign={TextAlign.center}>
          <StyledLink as={Link} to={defaultPensionAssetPath} target="_blank">
            Find out more{' '}
            <FaExternalLinkAlt style={{ height: '0.5rem', width: '0.5rem' }} />
          </StyledLink>
        </TextSmall>
      </EscalationCardContent>
    </EscalationCardContainer>
  );
}
interface WhatYourInvestedInformationStepProps {
  onProceed: () => void;
  onGoBack: () => void;
  onOptOut: () => void;
  data: UserProfileOnboardingQueryUserProfile;
}

function WhatYourInvestedInformationStep({
  onProceed,
  onGoBack,
  onOptOut,
  data,
}: WhatYourInvestedInformationStepProps) {
  const hasOptedOutDefaultFund = data?.pensionDetails?.defaultFundOptOut;
  const invalidateQueries = useInvalidateMutatedQueries();
  const {
    mutateAsync: updateUserProfile,
    isError,
    isLoading,
  } = useUpdateUserProfileMutation();

  const optInMutateAsync = async () => {
    await updateUserProfile(
      {
        input: {
          pension: {
            defaultFundOptOut: false,
          },
        },
      },
      {
        onSuccess: () => {
          invalidateQueries();
        },
      }
    );

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.whatYouAreInvestedInStep,
    });

    onProceed();
  };

  const handleOnProceed = () => {
    if (hasOptedOutDefaultFund === null) {
      optInMutateAsync();
    } else {
      onProceed();
    }
  };

  return (
    <StepContainer>
      <StepTitle>Where is my money invested?</StepTitle>
      <StepIntroduction $width={StepIntroductionWidth.normal}>
        <TextNormal>
          {hasOptedOutDefaultFund
            ? `
                  Your regular pension contributions will be held as cash in your account until you pick your own investments. If you'd prefer to leave all of your pension on autopilot, click the link below.
                `
            : `
                When your TILLIT Pension account is opened, it will be on autopilot. This means that your regular pension contributions will be automatically invested in what's called a 'default fund'. 
                `}
        </TextNormal>

        <TextNormal>
          {!hasOptedOutDefaultFund &&
            'If you want to personalise your pension, click the link below.'}
        </TextNormal>
      </StepIntroduction>

      <StepContent width={StepContentWidth.xxWide}>
        {hasOptedOutDefaultFund ? (
          <Text $textAlign={TextAlign.center}>
            <StyledA as="button" onClick={optInMutateAsync}>
              Switch to autopilot
            </StyledA>
          </Text>
        ) : (
          <>
            <EscalationCard />
            <br />
            <Text $textAlign={TextAlign.center}>
              <StyledA as="button" onClick={onOptOut}>
                I want to personalise my pension
              </StyledA>
            </Text>
          </>
        )}
      </StepContent>

      <ServerError isVisible={isError} />

      <StepActions>
        <StepButton
          type="submit"
          className="magenta"
          onClick={handleOnProceed}
          disabled={isLoading}
        >
          Continue
        </StepButton>

        <GoBackButton onClick={onGoBack} />
      </StepActions>
    </StepContainer>
  );
}

interface OptOutConfirmationStepProps {
  onProceed: () => void;
  onGoBack: () => void;
}

function OptOutConfirmationStep({
  onProceed,
  onGoBack,
}: OptOutConfirmationStepProps) {
  const [personalisePensionEnabled] = useToggle('global-personalise-pension');

  const invalidateQueries = useInvalidateMutatedQueries();
  const {
    mutateAsync: updateUserProfile,
    isError,
    isLoading,
  } = useUpdateUserProfileMutation();

  const optOutMutateAsync = async () => {
    await updateUserProfile(
      {
        input: {
          pension: {
            defaultFundOptOut: true,
          },
        },
      },
      {
        onSuccess: () => {
          invalidateQueries();
        },
      }
    );

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.whatYouAreInvestedInStep,
    });

    onProceed();
  };

  return (
    <StepContainer>
      <StepTitle>Personalise your pension</StepTitle>
      <StepContent width={StepContentWidth.extraWide}>
        <StepText>
          <StepIntroductionTypography>
            One of the benefits of taking control of your pension is that you
            can personalise it to suit your personal circumstances, values and
            beliefs.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            You can decide if you want to leave part of your pension on
            autopilot and personalise the rest by adding one of more funds of
            your liking. Or you can take full control and build your pension
            from scratch!{' '}
            {personalisePensionEnabled?.enabled &&
              `And don't worry, we even have a handy tutorial to support you in making your own investment decisions.`}
          </StepIntroductionTypography>
          <TextNormal>
            Once your new pension account is open, you can get started on
            personalising it straight away.
          </TextNormal>
          <TextNormal $noMargin $fontWeight={FontWeight.medium}>
            Just remember, until you make your investment decisions, any
            contributions paid into your account will be held as cash so it’s
            important that you don't forget to pick your investments.
          </TextNormal>
        </StepText>
      </StepContent>
      <ServerError isVisible={isError} />
      <StepActions>
        <StepButton
          type="submit"
          className="magenta"
          onClick={optOutMutateAsync}
          disabled={isLoading}
        >
          Confirm
        </StepButton>
        <GoBackButton onClick={onGoBack} />
      </StepActions>
    </StepContainer>
  );
}

interface WhatYourInvestedInStepProps {
  onProceed: () => void;
  onGoBack: () => void;
}

export function WhatYourInvestedInStep({
  onProceed,
  onGoBack,
}: WhatYourInvestedInStepProps) {
  const [activeStep, setActiveStep] = useState<'information' | 'optOut'>();
  const WhatYourInvestedInformationStepQuery = useUserProfileOnboardingQuery();

  return (
    <QueryState {...WhatYourInvestedInformationStepQuery}>
      {(queryResult) => {
        if (activeStep === 'optOut') {
          return (
            <OptOutConfirmationStep
              onProceed={onProceed}
              onGoBack={() => {
                setActiveStep('information');
              }}
            />
          );
        } else {
          return (
            <WhatYourInvestedInformationStep
              onProceed={onProceed}
              onGoBack={onGoBack}
              onOptOut={() => {
                setActiveStep('optOut');
              }}
              data={queryResult.data?.userProfile!}
            />
          );
        }
      }}
    </QueryState>
  );
}
