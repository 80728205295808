import { useMediaQuery } from '@material-ui/core';
import { colors } from 'constants/colors';
import { useGetPersonalisePensionSourcePath } from 'hooks/useGetSourcePath';
import { AiOutlineClose } from 'react-icons/ai';
import { FaArrowLeft } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';

export const ExitFlow = styled.a`
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 1.25rem;
  color: ${colors.richBlack};
  padding: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    color: ${colors.magenta};
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    position: absolute;
    top: 0rem;
    right: auto;
    left: 0;
    font-size: 0.875rem;

    @media screen and (min-height: 880px) {
      top: 2.5rem;
    }
  }
`;

interface BackToSourceProps {
  onClick?: () => void;
}

export function BackToSource({ onClick }: BackToSourceProps) {
  const theme = useTheme();
  const aboveMobile = useMediaQuery(theme.breakpoints.up('md'));
  const { source } = useGetPersonalisePensionSourcePath();

  return source ? (
    <ExitFlow href={source.path} onClick={() => onClick?.()}>
      {aboveMobile ? (
        <>
          <FaArrowLeft />
          Back to {source.title}
        </>
      ) : (
        <AiOutlineClose />
      )}
    </ExitFlow>
  ) : null;
}
