import { colors } from 'constants/colors';
import styled from 'styled-components';

export const BasketContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  ${(p) => p.theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'buys sells' 'total total';
  }
`;

export const FundBasketSummaryContainer = styled.div`
  grid-area: total;
`;

export const NoMatchCopy = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  align-items: center;
  > p {
    max-width: 20rem;
    color: #8c8c8c;
  }
  text-align: center;
  padding: 5rem 0;
`;

export const BasketOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 0.25rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    gap: 1rem;
  }
`;

export const Spacer = styled.div`
  padding-bottom: 1.5rem;
`;

export const NoFundsSelectedWrapperPension = styled.div`
  position: relative;
  margin-top: 1rem;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 1fr;
  justify-items: center;
  gap: 0rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-items: initial;
    padding: 3rem 0;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 1fr;
    gap: 2rem;
  }
`;

export const NoFundsSelectedDivider = styled.div`
  width: 50%;
  height: 1px;
  background-color: ${colors['grey-600']};
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 1px;
    height: 180px;
  }
`;

export const NoFundsSelectedCol = styled.div`
  margin-top: 1rem;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: flex-end;
    &:last-child {
      align-items: flex-start;
    }
  }
`;
