import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { HiArrowRight, HiCheck, HiXMark } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ArrowRight = styled(HiArrowRight)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.white};
`;

export const ActionDivider = styled.span`
  height: 2.25rem;
  border-right: 1px solid ${colors['richBlue-400']};
`;

interface DismissWrapperProps {
  isConfirmOpen: boolean;
}

export const DismissWrapper = styled.div<DismissWrapperProps>`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  transition: background-color 0.3s;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  height: 1.75rem;
  max-width: 2rem;
  overflow: hidden;
  transition: background-color 0.3s, max-width 0.3s;

  ${({ isConfirmOpen }) =>
    isConfirmOpen &&
    `
      background-color: white;
      max-width: 10rem;
  `}
`;

interface DismissInnerWrapperProps {
  isConfirmOpen: boolean;
}

export const DismissInnerWrapper = styled.div<DismissInnerWrapperProps>`
  display: flex;
  flex-direction: row;
`;

export const DismissButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

export const AreYouSureWrapper = styled.div`
  width: 6.75rem;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
`;

export const ConfirmIcon = styled(HiCheck)`
  width: 1.5rem;
  height: 1.5rem;
`;

interface DismissIconProps {
  isConfirmOpen: boolean;
}

export const DismissIcon = styled(HiXMark)<DismissIconProps>`
  width: 1.5rem;
  height: 1.5rem;
  color: ${({ isConfirmOpen }) =>
    isConfirmOpen ? colors.black : colors.white};
`;

export const Wrapper = styled.div`
  background-color: ${colors.richBlue};
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 24rem;
    padding: 1.5rem;
  }
`;

export const ListLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
`;

export const ActionWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 0;

  & + & {
    border-top: 1px solid ${colors['richBlue-400']};
  }
`;

export const ActionLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 0;
  width: 100%;
  gap: 0 0.5rem;
  align-items: center;
  justify-content: space-between;

  text-align: inherit;
  border: none;
  background: none;
  text-decoration: none;

  &:first-child {
    border-top: none;
  }
`;

export const ActionItemHeading = styled(H6)`
  color: ${colors.white};
  margin: 0;
  font-size: 1.125rem;
  font-weight: 400;
`;

export const PopOverContainer = styled.div`
  position: fixed;
  width: 24rem;
  height: auto;
  right: 1.25rem;
  bottom: 5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.popup};
`;

export const TeaserContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 4rem;
  align-items: center;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: ${colors.white};
  }
`;
