import {
  actionsPath,
  dashboardPath,
  generateAutoSaveInvestPathPath,
  generateDynamicPortfolioConstructionPath,
} from 'paths';
import { useLocation } from 'react-router-dom';

interface SourceDetails {
  path: string;
  toPath: string;
  title: string;
}

interface SourceLookup {
  [key: string]: SourceDetails;
}

const sourceLookup: SourceLookup = {
  dashboard: {
    path: dashboardPath,
    toPath: dashboardPath,
    title: 'Dashboard',
  },
  actions: {
    path: actionsPath,
    toPath: generateAutoSaveInvestPathPath({ wrapperType: 'pension' }),
    title: 'Actions',
  },
  autoSaveInvest: {
    path: generateAutoSaveInvestPathPath({ wrapperType: 'pension' }),
    toPath: generateAutoSaveInvestPathPath({ wrapperType: 'pension' }),
    title: 'Regular deposit & invest plan',
  },

  portfolioRebalance: {
    path: generateDynamicPortfolioConstructionPath({
      wrapperType: 'pension',
    }),
    toPath: generateDynamicPortfolioConstructionPath({
      wrapperType: 'pension',
    }),
    title: 'Portfolio builder',
  },
};

/**
 * A React hook that extracts and validates the 'source' query
 * parameter from the current URL in the personalise pension route.
 *
 * This hook uses react-router's useLocation to access URL parameters and maps the source
 * parameter to a predefined value in sourceLookup if it exists.
 *
 * @returns {Object} An object containing the source value
 * @returns {string|null} returns.source - The mapped source value if valid, null otherwise
 *
 * @example
 * ```tsx
 * const { source } = useGetPersonalisePensionSourcePath();
 * if (source) {
 *   // Handle valid source path
 * }
 * ```
 */
export function useGetPersonalisePensionSourcePath() {
  const location = useLocation();
  const { search } = location;

  const queryParams = new URLSearchParams(search);
  const sourceParam = queryParams.has('source')
    ? (queryParams.get('source') as string)
    : undefined;

  return {
    source:
      sourceParam && sourceLookup[sourceParam]
        ? sourceLookup[sourceParam]
        : null,
  };
}
