import { colors } from 'constants/colors';
import React from 'react';
import { BiWorld } from 'react-icons/bi';
import {
  MdBubbleChart,
  MdDonutSmall,
  MdOutlineMenu,
  MdViewQuilt,
} from 'react-icons/md';
import { TbChartRadar } from 'react-icons/tb';
import styled from 'styled-components';

export enum ToggleColorOptions {
  default = 'default',
  primary = 'primary',
  purple = 'purple',
  blue = 'blue',
  mauve = 'mauve',
  magenta = 'magenta',
}

type ToggleColor = keyof typeof ToggleColorOptions;

const toggleBackgroundColorLookup = {
  [ToggleColorOptions.default]: colors.white,
  [ToggleColorOptions.primary]: colors.magenta,
  [ToggleColorOptions.purple]: colors.white,
  [ToggleColorOptions.blue]: colors.white,
  [ToggleColorOptions.mauve]: colors.white,
  [ToggleColorOptions.magenta]: colors.white,
};

const toggleTextColorLookup = {
  [ToggleColorOptions.default]: colors.richBlack,
  [ToggleColorOptions.primary]: colors.white,
  [ToggleColorOptions.purple]: colors.purple,
  [ToggleColorOptions.blue]: colors.richBlue,
  [ToggleColorOptions.mauve]: colors.mauve,
  [ToggleColorOptions.magenta]: colors.magenta,
};

type ColorType = {
  $colorType: ToggleColor;
};

const ToggleContainer = styled.div<ColorType>`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
`;

type ToggleButtonProps = {
  $isActive: boolean;
  $colorType: ToggleColor;
};
const ToggleButton = styled.button<ToggleButtonProps>`
  background-color: ${({ $isActive, $colorType }) =>
    $isActive ? toggleBackgroundColorLookup[$colorType] : 'transparent'};
  color: ${({ $isActive, $colorType }) =>
    $isActive ? toggleTextColorLookup[$colorType] : colors['grey-800']};
  height: 2rem;
  width: 2rem;
  border-radius: 0.25rem;
  padding: 0.125rem;
  font-size: 2rem;
  line-height: 1.5rem;
  cursor: pointer;
  border: solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.25rem;
  }
`;

type Option<Values> = {
  icon?: 'donut' | 'spider' | 'bubble' | 'map' | 'table' | 'tree';
  value: Values;
};

interface ToggleProps<Values> {
  options: Option<Values>[];
  value: string;
  $color?: ToggleColor;
  onClick: (
    value: Values,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}
export const ToggleIconButtons = <Values extends string>({
  value,
  options,
  onClick,
  $color = ToggleColorOptions.default,
}: ToggleProps<Values>) => {
  return (
    <ToggleContainer $colorType={$color}>
      {options.map((option) => {
        const { icon } = option;
        return (
          <ToggleButton
            key={option.value}
            $isActive={value === option.value}
            onClick={(event) => onClick(option.value, event)}
            $colorType={$color}
          >
            {icon === 'spider' && <TbChartRadar />}
            {icon === 'donut' && <MdDonutSmall />}
            {icon === 'bubble' && <MdBubbleChart />}
            {icon === 'map' && <BiWorld />}
            {icon === 'table' && <MdOutlineMenu />}
            {icon === 'tree' && <MdViewQuilt />}
          </ToggleButton>
        );
      })}
    </ToggleContainer>
  );
};
