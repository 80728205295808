import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StepWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  min-height: ${({ theme }) => `calc(100vh - (${theme.header.height}- 20px))`};
  gap: 1rem;
  padding: 0.5rem 0 2rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-rows: auto auto auto auto;
    min-height: ${({ theme }) => `calc(100vh - ${theme.header.height})`};
    padding: 2.5rem 0 2rem 0;
  }
`;

export const BubblesWrapper = styled.div`
  scroll-behavior: smooth;
  display: block;
  width: calc(100% + 2rem);
  height: auto;
  overflow-x: scroll;
  overflow-y: visible;
  margin-left: -1rem;
  padding: 1.5rem 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: 0;
    padding: 1.5rem 0;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.magenta} transparent;

  /* Webkit (Chrome/Safari) */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.magenta};
    border-radius: 4px;

    &:hover {
      background: ${colors['magenta-500']};
    }
  }
`;

export const BubblesContainer = styled.div`
  position: relative;
  width: 616px;
  height: 100%;
  min-height: 454px;
`;

export const GlossaryButton = styled.button`
  border: 0;
  background: none;
  text-decoration: underline;
  color: ${colors.richBlue};
  transition: color 0.25s ease-in-out;

  &:active,
  &:focus,
  &:hover {
    outline: none;
    color: ${colors.magenta};
  }
`;
