import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from './PersonalisePensionStep.styles';

export const Step1KeyBuildingBlocksKeyTakeaways = () => {
  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>Intro: Key takeaways</H1Large>

      <OverviewCopyContainer>
        <TextNormal $textAlign={TextAlign.center}>
          The key building blocks to consider when personalising my pension are:
        </TextNormal>

        <OverViewList>
          <AnimatePresence>
            <KeyPointLi delay={0.25}>
              <TextNormal $noMargin>Asset Classes</TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>Diversification</TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.45}>
              <TextNormal $noMargin>Personal Circumstances</TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.55}>
              <TextNormal $noMargin>Beliefs and Values</TextNormal>
            </KeyPointLi>
          </AnimatePresence>
        </OverViewList>
      </OverviewCopyContainer>
    </Content>
  );
};
