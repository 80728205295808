import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core';
import { UsPersonsDefinitionDialog } from 'components/Dialogs/UsPersonsDefinitionDialog';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  LegalDocumentType,
  useLatestLegalDocumentVersionQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useModal } from 'mui-modal-provider';
import { privacyPolicy } from 'paths';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepCheckboxesContainer,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const basicDeclarationsFormSchema = yup.object().shape({
  nonUsPersonAssertion: yup
    .boolean()
    .oneOf([true], 'Please check the non US person checkbox to continue')
    .required('Please check the non US person assertion checkbox to continue'),
  taxDomicileAssertion: yup
    .boolean()
    .oneOf([true], 'Please check the tax domicile checkbox to continue')
    .required('Please check the tax domicile checkbox to continue'),
  ageAssertion: yup
    .boolean()
    .oneOf([true], 'Please check the age checkbox to continue')
    .required('Please check the age checkbox to continue'),
  marketingOptIn: yup.boolean(),
  privacyPolicyTermsConditions: yup
    .boolean()
    .oneOf(
      [true],
      'Please check the privacy policy terms and conditions checkbox to continue'
    )
    .required(
      'Please check the privacy policy terms and conditions checkbox to continue'
    ),
});

interface BasicDeclarationsFormValues {
  nonUsPersonAssertion: boolean;
  taxDomicileAssertion: boolean;
  ageAssertion: boolean;
  marketingOptIn: boolean;
  privacyPolicyTermsConditions: boolean;
}

export type BasicDeclarationsValues = Pick<
  BasicDeclarationsFormValues,
  | 'nonUsPersonAssertion'
  | 'taxDomicileAssertion'
  | 'marketingOptIn'
  | 'ageAssertion'
  | 'privacyPolicyTermsConditions'
> & {
  termsAndConditionsVersion: string;
};

interface BasicDeclarationsProps {
  onProceed: (values: BasicDeclarationsValues) => void;
  onGoBack: () => void;
  values?: BasicDeclarationsValues;
}

export function BasicDeclarations({
  onProceed,
  onGoBack,
  values,
}: BasicDeclarationsProps) {
  const { showModal } = useModal();

  const latestLegalDocumentVersionQuery = useLatestLegalDocumentVersionQuery({
    documentType: LegalDocumentType.TermsAndConditions,
  });
  const latestTermsConditionsDocument =
    latestLegalDocumentVersionQuery.data?.latestLegalDocumentVersion;

  const { termsAndConditionsVersion, ...defaultValues } = values || {};
  const methods = useForm<BasicDeclarationsFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(basicDeclarationsFormSchema),
    defaultValues: defaultValues,
  });

  const { register, handleSubmit, errors } = methods;

  const latestTermsConditionsDocumentId = latestTermsConditionsDocument?.id!;

  const onSubmit = async (data: BasicDeclarationsFormValues) => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.eligibility,
    });
    onProceed({
      ...data,
      termsAndConditionsVersion: latestTermsConditionsDocumentId,
    });
  };

  return (
    <QueryState {...latestLegalDocumentVersionQuery}>
      {() => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <StepContainer>
            <StepContent autoWidth width={StepContentWidth.wide}>
              <StepTitle>Before we proceed</StepTitle>
              <StepIntroduction mb={2}>
                <StepIntroductionTypography>
                  In order to proceed with your account setup, we need you to
                  confirm the following:
                </StepIntroductionTypography>
              </StepIntroduction>
              <StepCheckboxesContainer>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nonUsPersonAssertion"
                      defaultChecked={values?.nonUsPersonAssertion}
                      inputRef={register}
                    />
                  }
                  label={
                    <>
                      I am not a '
                      <StyledLink
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          showModal(UsPersonsDefinitionDialog);
                          trackGa({
                            event: GaEventNames.selectContent,
                            content_type: 'link',
                            item_id: 'us person',
                          });
                        }}
                        href="#"
                      >
                        US Person
                      </StyledLink>
                      ', as defined by the United States FATCA regulation.
                    </>
                  }
                />
                <ErrorMessage
                  name="nonUsPersonAssertion"
                  errors={errors}
                  render={({ message }) => (
                    <FormHelperText error={true}>{message}</FormHelperText>
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="ageAssertion"
                      defaultChecked={values?.ageAssertion}
                      inputRef={register}
                    />
                  }
                  label="I am over the age of 18"
                />
                <ErrorMessage
                  name="ageAssertion"
                  errors={errors}
                  render={({ message }) => (
                    <FormHelperText error={true}>{message}</FormHelperText>
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="taxDomicileAssertion"
                      defaultChecked={values?.taxDomicileAssertion}
                      inputRef={register}
                    />
                  }
                  label="I am a UK taxpayer/UK tax resident"
                />
                <ErrorMessage
                  name="taxDomicileAssertion"
                  errors={errors}
                  render={({ message }) => (
                    <FormHelperText error={true}>{message}</FormHelperText>
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="marketingOptIn"
                      defaultChecked={values?.marketingOptIn}
                      inputRef={register}
                    />
                  }
                  label="Keep me up to date with TILLIT (Insights, news and product features)"
                />
                <ErrorMessage
                  name="marketingOptIn"
                  errors={errors}
                  render={({ message }) => (
                    <FormHelperText error={true}>{message}</FormHelperText>
                  )}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="privacyPolicyTermsConditions"
                      defaultChecked={values?.privacyPolicyTermsConditions}
                      inputRef={register}
                    />
                  }
                  label={
                    <>
                      I agree with the{' '}
                      <StyledLink href={privacyPolicy} target="_blank">
                        Privacy Policy
                      </StyledLink>{' '}
                      and{' '}
                      <StyledLink
                        href={latestTermsConditionsDocument?.url}
                        target="_blank"
                      >
                        Terms and Conditions
                      </StyledLink>
                    </>
                  }
                />
                <ErrorMessage
                  name="privacyPolicyTermsConditions"
                  errors={errors}
                  render={({ message }) => (
                    <FormHelperText error={true}>{message}</FormHelperText>
                  )}
                />
              </StepCheckboxesContainer>
            </StepContent>

            <StepActions>
              <StepButton type="submit" className="magenta">
                Continue
              </StepButton>
              <GoBackButton
                onClick={() => {
                  onGoBack();
                }}
              />
            </StepActions>
          </StepContainer>
        </form>
      )}
    </QueryState>
  );
}
