import { StyledLink } from 'components/design-system/Link';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { Loading } from 'components/Loading';
import { percent } from 'formatting';
import { useWrapperTypeFeeQuery, WrapperType } from 'generated/graphql';

interface FeeTierProps {
  $align?: TextAlign;
  platformFeeProportion: number;
}

export function PromotionalFeeTierCopy({
  $align = TextAlign.left,
  platformFeeProportion,
}: FeeTierProps) {
  return (
    <Text $textAlign={$align}>
      We normally charge a flat platform fee across all your investment accounts
      of 0.40% per annum, but for a limited time we are reducing this platform
      charge to {percent(platformFeeProportion)} for early adopters of the
      TILLIT Pension &mdash;{' '}
      <StyledLink
        href="https://d1ur1kq4xhe5pz.cloudfront.net/images/TILLIT-Pension-Early-Adopter-Terms-and-Conditions.pdf"
        target="_blank"
      >
        terms and conditions apply
      </StyledLink>
      .
    </Text>
  );
}

export function DefaultFeeTierCopy({
  $align = TextAlign.left,
  platformFeeProportion,
}: FeeTierProps) {
  return (
    <Text $textAlign={$align}>
      We only charge one fee, a flat platform fee across all your investment
      accounts of {percent(platformFeeProportion)} per annum that will drop by
      0.01% annually until it reaches 0.25% - regardless of the size of your
      assets!
    </Text>
  );
}

export function LowestFeeTierCopy({
  $align = TextAlign.left,
  platformFeeProportion,
}: FeeTierProps) {
  return (
    <Text $textAlign={$align}>
      We only charge one fee, a flat platform fee across all your investment
      accounts of {percent(platformFeeProportion)} - regardless of the size of
      your assets!
    </Text>
  );
}

interface FeeTierCopyProps {
  $align?: TextAlign;
  referralCode?: string;
}

export function FeeTierCopy({
  referralCode = '',
  $align = TextAlign.left,
}: FeeTierCopyProps) {
  const wrapperTypeFeesQuery = useWrapperTypeFeeQuery({
    type: WrapperType.Sipp,
    referral: referralCode,
  });

  const platformFeeProportion =
    wrapperTypeFeesQuery.data?.wrapperFee?.platformFeeProportion || 0.004;
  const currentPlatformFeeProportion =
    wrapperTypeFeesQuery.data?.wrapperFee?.currentPlatformFeeProportion;

  if (wrapperTypeFeesQuery.isLoading) {
    return <Loading />;
  }

  if (!currentPlatformFeeProportion) {
    if (platformFeeProportion === 0.0025) {
      return (
        <PromotionalFeeTierCopy
          $align={$align}
          platformFeeProportion={platformFeeProportion}
        />
      );
    }
    return (
      <DefaultFeeTierCopy
        $align={$align}
        platformFeeProportion={platformFeeProportion}
      />
    );
  }

  if (currentPlatformFeeProportion <= 0.0025) {
    return (
      <LowestFeeTierCopy
        $align={$align}
        platformFeeProportion={currentPlatformFeeProportion}
      />
    );
  }

  if (platformFeeProportion === 0.0025) {
    return (
      <PromotionalFeeTierCopy
        $align={$align}
        platformFeeProportion={platformFeeProportion}
      />
    );
  }

  return (
    <DefaultFeeTierCopy
      $align={$align}
      platformFeeProportion={platformFeeProportion}
    />
  );
}
