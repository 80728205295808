import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TutorialVideo } from '../TutorialVideo';
import { Content } from './PersonalisePensionStep.styles';
import { StepProps } from './_shared';

export const Step3Diversification = ({ onProceed }: StepProps) => {
  const handleVideoEnd = () => {
    onProceed();
  };

  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>Diversification</H1Large>

      <TutorialVideo
        video={{
          thumbnailUrl: 'https://via.placeholder.com/150',
          videoUrl:
            '/mediacontent/protected/personalise-pension/Step-3-Diversification.mp4.m3u8',
          subtitlesUrl: '',
        }}
        description="Step-3-Diversification"
        onVideoEnd={handleVideoEnd}
      />
    </Content>
  );
};
