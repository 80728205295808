import { ActionType, WrapperType } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import {
  actionsSetupMfa,
  autoSaveInvestBasePath,
  generateActionsTypePath,
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
  personalisePensionPath,
} from 'paths';

interface PathProps {
  wrapperType?: WrapperType;
  actionId: string;
  personalisePensionEnabled?: boolean;
}

interface TextProps {
  wrapperType?: WrapperType;
}

export const actionSpecLookUp: Partial<
  {
    [key in ActionType]: {
      title: string | ((props: TextProps) => string);
      description: string | ((props: TextProps) => string);
      path?: string | ((props: PathProps) => string);
    };
  }
> = {
  [ActionType.AddCash]: {
    title: 'Add cash',
    description: 'Add cash to your account in seconds',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'add-cash', actionId }),
  },
  [ActionType.FundAccount]: {
    title: 'Fund account',
    description:
      'Now that your account is ready, how would you like to funds it?',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'fund-account', actionId }),
  },
  [ActionType.PickInvestments]: {
    title: 'Personalise your pension',
    description: 'Start building your portfolio',
    path: ({ personalisePensionEnabled, wrapperType }) => {
      if (personalisePensionEnabled) {
        return personalisePensionPath;
      }

      return wrapperType
        ? generateAutoSaveInvestPathPath({
            wrapperType: getPathSegmentForWrapperType(wrapperType),
          })
        : autoSaveInvestBasePath;
    },
  },
  [ActionType.SetupMfa]: {
    title: 'Protect your account',
    description: 'Set up two-step verification to help secure your account.',
    path: actionsSetupMfa,
  },
  [ActionType.SetupRegularDeposit]: {
    title: 'Set up a regular deposit',
    description:
      'Make it easier to build your portfolio by setting up a regular deposit',
    path: ({ wrapperType }) =>
      wrapperType
        ? generateAutoSaveInvestSubPath({
            wrapperType: getPathSegmentForWrapperType(wrapperType),
            action: 'create-deposit',
          })
        : autoSaveInvestBasePath,
  },
  [ActionType.StartTransfer]: {
    title: ({ wrapperType }) =>
      wrapperType
        ? `Consolidate your ${getShortNameForWrapperType(
            wrapperType
          ).toLowerCase()}s`
        : 'Consolidate your accounts',
    description: ({ wrapperType }) =>
      wrapperType
        ? `Transfer other ${getShortNameForWrapperType(
            wrapperType
          ).toLowerCase()}s to TILLIT`
        : 'Transfer other accounts to TILLIT',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'transfer', actionId }),
  },
};
