import { H1Large, TextAlign } from 'components/design-system/Heading/Heading';
import { TextNormal } from 'components/design-system/Text/Text';
import { AnimatePresence } from 'framer-motion';
import { KeyPointLi } from '../KeyPointLi';
import {
  Content,
  OverviewCopyContainer,
  OverViewList,
} from './PersonalisePensionStep.styles';

export const Step5BeliefsAndValuesKeyTakeaways = () => {
  return (
    <Content>
      <H1Large $textAlign={TextAlign.center}>
        Beliefs and Values: Key takeaways
      </H1Large>

      <OverviewCopyContainer>
        <OverViewList>
          <AnimatePresence>
            <KeyPointLi delay={0.25}>
              <TextNormal $noMargin>
                A pension is much more than a savings account.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.35}>
              <TextNormal $noMargin>
                With your TILLIT Pension you can make conscious investment
                decisions based on your beliefs and values.
              </TextNormal>
            </KeyPointLi>
            <KeyPointLi delay={0.45}>
              <TextNormal $noMargin>
                Align it with what matters to you; be that balancing profit and
                purpose, supporting the transition to renewable energy,
                promoting diversity in decision making, or your personal
                religious beliefs.
              </TextNormal>
            </KeyPointLi>
          </AnimatePresence>
        </OverViewList>
      </OverviewCopyContainer>
    </Content>
  );
};
