import { Grid } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface StyledGridProps {
  $hasBackground: boolean;
}

export const StyledGrid = styled(Grid)<StyledGridProps>`
  min-height: 100vh;
  position: relative;
  margin-top: 0;

  ${({ $hasBackground }) =>
    $hasBackground &&
    css`
      /* background-color: #FFFFFF; */
      background-image: url('data:image/svg+xml,<svg width="393" height="483" viewBox="0 0 393 483" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity="0.5" d="M389 -8.20578e-06C391.761 -8.19325e-06 394 2.23857 394 4.99999L394 160.5L394 275L394 396.925C394 398.253 393.471 399.527 392.531 400.465L386.165 406.814C384.917 408.059 383.105 408.554 381.396 408.117L71.2614 328.84C69.5107 328.393 68.1377 327.036 67.6701 325.29L27.1012 173.885L6.43822 96.77L0.167193 73.1301C0.0562266 72.7118 3.9192e-05 72.2809 3.91969e-05 71.8481L3.97293e-05 24.5L3.8006e-05 4.99999C3.7762e-05 2.23857 2.23861 -9.96066e-06 5.00004 -9.94814e-06L389 -8.20578e-06Z" fill="%23F7E1FB"/><path d="M389 -8.20578e-06C391.761 -8.19325e-06 394 2.23857 394 4.99999L394 160.5L394 275L394 396.925C394 398.253 393.471 399.527 392.531 400.465L386.165 406.814C384.917 408.059 383.105 408.554 381.396 408.117L71.2614 328.84C69.5107 328.393 68.1377 327.036 67.6701 325.29L27.1012 173.885L6.43822 96.77L0.167193 73.1301C0.0562266 72.7118 3.9192e-05 72.2809 3.91969e-05 71.8481L3.97293e-05 24.5L3.8006e-05 4.99999C3.7762e-05 2.23857 2.23861 -9.96066e-06 5.00004 -9.94814e-06L389 -8.20578e-06Z" fill="url(%23paint0_linear_242_3001)"/><defs><linearGradient id="paint0_linear_242_3001" x1="384.152" y1="175.165" x2="384.152" y2="408.822" gradientUnits="userSpaceOnUse"><stop stop-color="white" stop-opacity="0"/><stop offset="1" stop-color="white"/></linearGradient></defs></svg>');
      background-position: top center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-attachment: fixed;
    `}

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${({ $hasBackground }) =>
      $hasBackground &&
      css`
        background-image: url('data:image/svg+xml,<svg width="1439" height="700" viewBox="0 0 1439 700" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_228_2997" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1439" height="700"><rect width="1439" height="700" fill="white"/></mask><g mask="url(%23mask0_228_2997)"><path opacity="0.5" d="M476.584 -870.681C477.832 -871.9 479.626 -872.381 481.317 -871.949L1256.58 -673.776C1258.33 -673.329 1259.7 -671.972 1260.17 -670.226L1463.06 86.9753C1463.53 88.7207 1463.02 90.5826 1461.72 91.8455L889.416 651.097C888.168 652.317 886.374 652.797 884.683 652.365L109.425 454.193C107.674 453.745 106.301 452.388 105.833 450.643L-97.0582 -306.559C-97.5259 -308.304 -97.0155 -310.166 -95.7231 -311.429L476.584 -870.681Z" fill="%23F7E1FB"/><path d="M476.584 -870.681C477.832 -871.9 479.626 -872.381 481.317 -871.949L1256.58 -673.776C1258.33 -673.329 1259.7 -671.972 1260.17 -670.226L1463.06 86.9753C1463.53 88.7207 1463.02 90.5826 1461.72 91.8455L889.416 651.097C888.168 652.317 886.374 652.797 884.683 652.365L109.425 454.193C107.674 453.745 106.301 452.388 105.833 450.643L-97.0582 -306.559C-97.5259 -308.304 -97.0155 -310.166 -95.7231 -311.429L476.584 -870.681Z" fill="url(%23paint0_linear_228_2997)"/></g><defs><linearGradient id="paint0_linear_228_2997" x1="887.406" y1="75.2076" x2="887.406" y2="653.061" gradientUnits="userSpaceOnUse"><stop stop-color="white" stop-opacity="0"/><stop offset="1" stop-color="white"/></linearGradient></defs></svg>');
      `}
  }
`;
